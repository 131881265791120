import { useUserProfile } from "~/shared/api/user/user";
import { Avatar, AvatarFallback, AvatarImage, AvatarProps, AvatarSystemFallback } from "~/shared/components/ds/Avatar";

export function CurrentUserAvatar({ size, color }: Pick<AvatarProps, "size" | "color">) {
  const { data: user } = useUserProfile();

  const fallback = [user?.firstName ?? "", user?.lastName ?? ""];
  const fallbackIsEmpty = fallback.every((str) => str === "");
  const headshot = user?.headshot?.location;
  const isSystemFallback = headshot == null && fallbackIsEmpty;

  return (
    <Avatar size={size} color={isSystemFallback ? "system" : color}>
      <>
        {(() => {
          switch (true) {
            case headshot != null: {
              return <AvatarImage src={headshot} alt={`${user?.firstName} ${user?.lastName}`} />;
            }
            case !fallbackIsEmpty: {
              return <AvatarFallback fallback={fallback} />;
            }
            default: {
              return <AvatarSystemFallback />;
            }
          }
        })()}
      </>
    </Avatar>
  );
}
