import * as React from "react";
import { useSearchParams } from "react-router-dom";
import { getSavedSearchParams } from "~/participant/utils/getSavedSearchParams.helpers";
import { inputStyles } from "~/shared/components/ds/Input";
import { Icon, IconUse } from "~/shared/components/ds/icons/Icon";
import { SAVED_ROLES_SEARCH_PARAM_KEY } from "~/shared/constants/savedRoles.constants";
import { useDebounceWithCallback } from "~/shared/hooks/useDebounce";
import { focusRingStyles } from "~/shared/styles/focus";

export function SavedSearchInput() {
  const [, startTransition] = React.useTransition();
  const searchInputRef = React.useRef<HTMLInputElement>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const { term } = getSavedSearchParams(searchParams);
  const [search, setSearch] = React.useState(term);
  const handleSearch = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  }, []);
  const onSearchChange = React.useCallback(
    (term: string) => {
      startTransition(() => {
        setSearchParams(
          (searchParams) => {
            searchParams.set(SAVED_ROLES_SEARCH_PARAM_KEY.TERM, term);

            return searchParams;
          },
          { replace: true, preventScrollReset: true },
        );
      });
    },
    [setSearchParams],
  );
  useDebounceWithCallback(search, 500, onSearchChange);

  return (
    <div className="w-full sm:w-[340px]">
      <div className={inputStyles({ theme: "foundation" })}>
        <span className="text-ds-text-tertiary flex h-10 items-center pl-3">
          <Icon className="h-4 w-4">
            <IconUse id="search-line" />
          </Icon>
        </span>
        <input
          spellCheck="false"
          value={search}
          onChange={handleSearch}
          type="text"
          ref={searchInputRef}
          placeholder="Search saved roles..."
          className="text-ds-text-primary placeholder:text-ds-text-placeholder block w-full flex-1 bg-transparent px-3 py-1 text-sm"
        />
        {search.length > 0 ? (
          <button
            onClick={() => {
              startTransition(() => {
                setSearch("");
              });
              searchInputRef.current?.focus();
            }}
            className={focusRingStyles({
              className: "text-ds-icon-tertiary mx-2 rounded-full focus-visible:ring-offset-1",
            })}
          >
            <Icon aria-hidden className="h-4 w-4">
              <IconUse id="close-circle-line" />
            </Icon>
            <span className="sr-only">Clear search</span>
          </button>
        ) : undefined}
      </div>
    </div>
  );
}
