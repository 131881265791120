import { QueryClient } from "@tanstack/react-query";
import { redirect } from "react-router-dom";
import { UTAH_PROGRAM_AVAILABLE_STEPS } from "~/participant/constants/utah.constants";
import { USER_OPTIONS } from "~/shared/api/user/user";

export async function maybeRedirectToLatestStep<T>(url: string, queryClient: QueryClient, cb: () => T) {
  const user = await queryClient.ensureQueryData(USER_OPTIONS.user());
  const pathName = new URL(url).pathname;
  const lastStepCompletedSlug = getLastStepCompletedSlug(user.onboardingStep);

  // No redirect needed
  if (!utahRedirectState.shouldTryRedirect() || pathName === lastStepCompletedSlug) {
    utahRedirectState.markRedirectAsAttempted();
    return cb();
  }
  utahRedirectState.markRedirectAsAttempted();

  return redirect(lastStepCompletedSlug);
}

export async function maybeAccessStep<T>(step: number, queryClient: QueryClient, cb: () => T) {
  const user = await queryClient.ensureQueryData(USER_OPTIONS.user());

  if (user.onboardingStep >= step) {
    return cb();
  }

  const lastStepCompletedSlug = getLastStepCompletedSlug(user.onboardingStep);

  return redirect(lastStepCompletedSlug);
}

/**
 * Utah redirect state is managed globally to ensure only one redirect attempt is made across multiple function calls.
 * We only want this behavior to occur once per app load.
 */
const utahRedirectState = (() => {
  let shouldTryRedirect = true;
  return {
    shouldTryRedirect: () => shouldTryRedirect,
    markRedirectAsAttempted: () => {
      shouldTryRedirect = false;
    },
  };
})();

function getLastStepCompletedSlug(onboardingStep: number) {
  return (
    Object.values(UTAH_PROGRAM_AVAILABLE_STEPS).find((value) => value.step === onboardingStep)?.slug ??
    UTAH_PROGRAM_AVAILABLE_STEPS.welcome.slug
  );
}
