import * as AlertDialog from "@radix-ui/react-alert-dialog";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import * as pagination from "@zag-js/pagination";
import { normalizeProps, useMachine } from "@zag-js/react";
import { compact, uniq } from "lodash";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import api from "~/shared/api/api";
import { USER_OPTIONS, useUserProfile } from "~/shared/api/user/user";
import { ReactComponent as Arrow } from "~/shared/components/svg/arrow.svg";
import { ReactComponent as Clock } from "~/shared/components/svg/clock.svg";
import { ReactComponent as CloseX } from "~/shared/components/svg/close-x.svg";
import { ReactComponent as Gift } from "~/shared/components/svg/gift.svg";
import Button from "../Button";
import UnstyledList from "../UnstyledList";
import styles from "./FeatureAnnouncements.module.css";

export function FeatureAnnouncements() {
  const { data: user } = useUserProfile();
  const { featureAnnouncements } = user ?? {};

  return featureAnnouncements && featureAnnouncements?.length > 0 ? (
    <FeatureAnnouncementsDialog announcements={featureAnnouncements} />
  ) : null;
}

interface FeatureAnnouncementsDialogProps {
  announcements: { id: number; featureId: number }[];
}
function FeatureAnnouncementsDialog({ announcements }: FeatureAnnouncementsDialogProps) {
  const [seenAnnoucements, setSeenAnnoucements] = React.useState([announcements?.[0]?.id]);
  const [previousPage, setPreviousPage] = React.useState(1);
  const featureIds = announcements.map((a) => a.featureId);
  const ids = announcements.map((a) => a.id);
  const mutation = useMarkFeatureAnnouncementsAsSeen();

  const [state, send] = useMachine(
    pagination.machine({
      id: "feature-annoucement-dialog-pagination",
      count: featureIds.length,
      pageSize: 1,
    }),
  );

  const api = pagination.connect(state, send, normalizeProps);

  if (previousPage !== api.page) {
    // whenever the page changes, update the seen annoucements array
    setPreviousPage(api.page);
    setSeenAnnoucements((prev) => {
      const newSeenAnnoucementArray = compact(uniq([...prev, ids[api.page - 1]]));

      return newSeenAnnoucementArray;
    });
  }

  return (
    <AlertDialog.Root
      defaultOpen
      onOpenChange={(open) => {
        if (!open) {
          // Mark all feature announcements as seen
          if (seenAnnoucements.length > 0) {
            mutation.mutate(compact(seenAnnoucements), {
              onError: () => {
                // TODO: add back once api is fixed
                // defaultToast.error(
                //   'Failed to mark feature announcements as seen'
                // );
              },
            });
          }
        }
      }}
    >
      <AlertDialog.Portal>
        <AlertDialog.Overlay
          className={twMerge("z-announcement fixed inset-0 bg-black/40", styles["animation-reveal-overlay"])}
        />
        <AlertDialog.Content
          className={twMerge(
            "z-announcement inset-center fixed min-h-[210px] w-full max-w-xs overflow-hidden rounded-lg bg-white shadow-md shadow-black/40 focus:outline-none sm:bottom-5 sm:left-auto sm:right-5 sm:top-auto sm:transform-none",
            styles["animation-reveal-content"],
          )}
        >
          <FeatureAnnouncementContent id={featureIds[api.page - 1]} />
          {featureIds.length > 1 && (
            <>
              <span
                aria-label={`Page ${api.page} of ${api.totalPages}`}
                className="font-primary absolute inset-3 inline-flex h-min w-min min-w-[10px] whitespace-nowrap rounded-full bg-white px-2 py-0.5 text-xs tabular-nums text-slate-500"
              >
                {api.page} / {api.totalPages}
              </span>
              <nav {...api.rootProps} className="absolute bottom-4 right-4 rounded-full bg-white">
                <UnstyledList className="stack-x-1">
                  <UnstyledList.Item>
                    <button
                      {...api.prevPageTriggerProps}
                      style={{ transform: "rotate(180deg)" }}
                      className={paginationTriggerStyles}
                    >
                      <span className="sr-only">Previous</span>
                      <Arrow aria-hidden className="h-2 w-2" />
                    </button>
                  </UnstyledList.Item>
                  <UnstyledList.Item>
                    <button {...api.nextPageTriggerProps} className={paginationTriggerStyles}>
                      <span className="sr-only">Next</span>
                      <Arrow aria-hidden className="h-2 w-2" />
                    </button>
                  </UnstyledList.Item>
                </UnstyledList>
              </nav>
            </>
          )}
          <AlertDialog.Cancel asChild>
            <button className="absolute right-2 top-2 m-0 grid h-5 w-5 place-items-center rounded-full border-none bg-slate-200/40 p-0 transition-colors hover:bg-slate-200">
              <span className="sr-only">Close</span>
              <CloseX aria-hidden className="h-4 w-4" />
            </button>
          </AlertDialog.Cancel>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  );
}

const paginationTriggerStyles =
  "color-slate-400 grid h-5 w-5 place-items-center rounded-full border border-solid border-slate-400 bg-transparent p-0 transition-colors hover:bg-slate-200 disabled:cursor-not-allowed disabled:opacity-50";

function FeatureAnnouncementContent({ id }: { id?: number }) {
  if (!id) return null;

  return FEATURE_CONTENT[id] ? <>{FEATURE_CONTENT[id]}</> : <FeatureAnnouncementTemplate id={id} />;
}

const FEATURE_CONTENT: Record<string | number, React.ReactNode> = {
  1000: <AddSkillsFeatureAnnouncement />,
  1004: <OffsiteNotice />,
};

function FeatureAnnouncementTemplate({ id }: { id: number }) {
  // FUTURE: Better handling of fallback/default content
  return null;
}

function AnnouncementTagLabel({ children, icon }: { children: React.ReactNode; icon?: React.ReactNode }) {
  return (
    <div
      className="inset absolute"
      style={{
        transform: `translateY(calc(-50% - 32px))`,
      }}
    >
      <span className="bg-primary-green inline-flex min-h-[24px] items-center gap-1 rounded-full px-2.5 py-0.5 text-xs font-medium text-white">
        {icon}
        <span>{children}</span>
      </span>
    </div>
  );
}

function AddSkillsFeatureAnnouncement() {
  const navigate = useNavigate();

  return (
    <>
      <div className="aspect-video bg-slate-100">
        <img alt="" loading="lazy" className="block w-full object-cover" src="/images/skills-feature-annoucement.jpg" />
      </div>
      <div className="stack-y-3 relative px-5 pb-4 pt-8">
        <AnnouncementTagLabel icon={<Gift width="14px" height="14px" />}>Feature Highlight</AnnouncementTagLabel>
        <AlertDialog.Title className="m-0 text-lg font-bold leading-none">Add/Modify Your Skills</AlertDialog.Title>
        <div className="stack-y-6">
          <AlertDialog.Description className="m-0 text-base">
            You can add or modify your skills in the platform! To access this feature, click your headshot in the top
            right corner, select "Account Settings", and then click the "Skills" tab.
          </AlertDialog.Description>
          <div className="stack-x-3 items-center">
            <AlertDialog.Action asChild>
              <Button
                onClick={() => {
                  navigate("/account/skills");
                }}
                $small
                $theme="secondary"
                aria-label="Navigate to skills page"
              >
                Try it out
              </Button>
            </AlertDialog.Action>
            <AlertDialog.Cancel className="font-primary border-none bg-transparent p-0 font-medium text-slate-500 transition-colors hover:text-slate-400">
              Dismiss
            </AlertDialog.Cancel>
          </div>
        </div>
      </div>
    </>
  );
}

function OffsiteNotice() {
  return (
    <>
      <div className="aspect-video bg-slate-100">
        <img
          alt="offsite notice 2024"
          loading="lazy"
          className="block w-full object-cover"
          src="/images/2024_offsite_notice.jpg"
        />
      </div>
      <div className="stack-y-3 relative px-5 pb-4 pt-8">
        <AnnouncementTagLabel icon={<Clock width="14px" height="14px" />}>Team Offsite</AnnouncementTagLabel>
        <div className="stack-y-6">
          <AlertDialog.Description className="m-0 text-base">
            During the week of <strong>1/15/24 - 1/19/24</strong> the BreakLine team will be at a company wide offsite.
            While we'll continue to be available throughout the week, our response time in the platform regarding the
            high priority roles in your Game Plan may be slower than usual.
          </AlertDialog.Description>
          <AlertDialog.Description className="m-0 text-base">
            Additionally, there will be NO Interview Preparation Workshops or Office Hours during this time. They will
            resume on <strong>1/22/24</strong>. Look forward to seeing you all there!
          </AlertDialog.Description>
          <div className="stack-x-3 items-center">
            <AlertDialog.Cancel className="font-primary border-none bg-transparent p-0 font-medium text-slate-500 transition-colors hover:text-slate-400">
              Dismiss
            </AlertDialog.Cancel>
          </div>
        </div>
      </div>
    </>
  );
}

function useMarkFeatureAnnouncementsAsSeen() {
  const queryClient = useQueryClient();

  async function markFeatureAnnouncementsAsSeen(ids: number[]) {
    if (ids.length <= 0) return;

    const payload = ids.map((id) => ({ id, interacted: true }));

    return await api.patch("/feature-announcements", payload);
  }

  return useMutation({
    mutationFn: markFeatureAnnouncementsAsSeen,
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: USER_OPTIONS.user().queryKey,
      });
    },
  });
}
