import { useSuspenseQuery } from "@tanstack/react-query";
import * as React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Helmet } from "react-helmet";
import { USER_OPTIONS } from "~/shared/api/user/user";
import { getRouteTreeRoot } from "~/shared/api/user/user.helpers";
import { ButtonLink } from "~/shared/components/ds/Button";
import { heading } from "~/shared/styles/heading";
import { copy } from "~/shared/styles/text";

export function NotFound() {
  return (
    <>
      <Helmet title="404 - Page Not Found" />
      <div className="flex items-center justify-center">
        <div className="stack-y-6 w-full max-w-prose items-center">
          <div className="stack-y-3">
            <h1
              className={heading({
                variant: { initial: "20", md: "30" },
                className: "text-uppercase",
                color: "secondary",
                align: "center",
              })}
            >
              404
            </h1>
            <h2
              className={heading({
                variant: { initial: "24", md: "36" },
                align: "center",
              })}
            >
              Sorry, we can't find that page.
            </h2>
          </div>
          <p
            className={copy({
              variant: { initial: "16", md: "18" },
              align: "center",
              color: "secondary",
              className: "max-w-[50ch] text-balance",
            })}
          >
            The page you are looking for was moved, deleted, renamed, or may have never existed!
          </p>
          <div className="flex justify-center">
            <GoBackHome />
          </div>
        </div>
      </div>
    </>
  );
}

export function NestedNotFound() {
  return (
    <>
      <Helmet title="404 - Page Not Found" />
      <div className="stack-y-6 w-full max-w-prose">
        <div className="stack-y-3">
          <h1
            className={heading({
              variant: { initial: "20", md: "30" },
              color: "secondary",
              className: "text-uppercase",
            })}
          >
            404
          </h1>
          <h2
            className={heading({
              variant: { initial: "24", md: "36" },
            })}
          >
            hmm, we can't find that page.
          </h2>
        </div>
        <p
          className={copy({
            variant: { initial: "16", md: "18" },
            color: "secondary",
            className: "max-w-[50ch] text-balance",
          })}
        >
          The page you are looking for was moved, deleted, renamed, or may have never existed!
        </p>
        <div className="flex">
          <GoBackHome />
        </div>
      </div>
    </>
  );
}

function GoBackHome() {
  return (
    <ErrorBoundary fallback={null}>
      <React.Suspense
        fallback={
          <ButtonLink to="/" size={{ initial: "sm", md: "lg" }}>
            Go Back Home
          </ButtonLink>
        }
      >
        <GoBackHomeButtonLink />
      </React.Suspense>
    </ErrorBoundary>
  );
}

function GoBackHomeButtonLink() {
  const { data: user } = useSuspenseQuery(USER_OPTIONS.user());
  const routeTreeRoot = getRouteTreeRoot(user.type);

  return (
    <ButtonLink to={routeTreeRoot} size={{ initial: "sm", md: "lg" }}>
      Go Back Home
    </ButtonLink>
  );
}
