import { queryOptions } from "@tanstack/react-query";
import api from "~/shared/api/api";
import { Params } from "~/shared/api/types/params";
import { USER_TYPES } from "~/shared/api/user/user.constants";

const DASHBOARD_KEYS = {
  all: "dashboard" as const,
  search: (params: Params) => [DASHBOARD_KEYS.all, "search", params] as const,
  unreadCount: () => [DASHBOARD_KEYS.all, "unreadCount"] as const,
};

export const DASHBOARD_QUERY_OPTIONS = {
  search: (params: Params) => {
    return queryOptions({
      queryKey: DASHBOARD_KEYS.search(params),
      queryFn: async ({ signal }) => {
        const response = await api.get<SearchResponse>(`/dashboard/search`, {
          signal,
          ...params,
        });

        return response.data;
      },
    });
  },
  unreadCount: () => {
    return queryOptions({
      queryKey: DASHBOARD_KEYS.unreadCount(),
      queryFn: async ({ signal }) => {
        const response = await api.get<UnreadCountResponse>(`/dashboard/unread-count`, {
          signal,
        });

        return response.data;
      },
    });
  },
};

interface UnreadCountResponse {
  tasks: number;
  notifications: number;
  total: number;
}

interface User {
  id: number;
  name: string;
  email: string;
  type: keyof typeof USER_TYPES;
}

interface Partner {
  id: number;
  name: string;
}

interface Job {
  id: number;
  name: string;
  partnerId: string;
  partnerName: string;
}

interface SearchResponse {
  users: User[];
  partners: Partner[];
  jobs: Job[];
}
