import { QueryClient } from "@tanstack/react-query";
import { PostHog } from "posthog-js";
import * as React from "react";
import { Outlet, redirect, RouteObject } from "react-router-dom";
import { COMPANIES_PATHS } from "~/participant/constants/paths.constants";
import { UTAH_PATHS, UTAH_PROGRAM_AVAILABLE_STEPS } from "~/participant/constants/utah.constants";
import { getIsUtahParticipant } from "~/participant/utils/getIsUtahParticipant.helpers";
import { maybeAccessStep, maybeRedirectToLatestStep } from "~/participant/utils/utah.helpers";
import { savedLoader } from "~/participant/views/SavedView";
import { utahOnboardingBasicInfoLoader } from "~/participant/views/utah-onboarding/UtahOnboardingBasicInfoView";
import { LoadingContainer } from "~/shared/components/ds/Spinner";
import { NestedRouteErrorHandler, TopLevelRouteErrorHandler } from "~/shared/components/Error";
import { NotFound } from "~/shared/components/NotFound";
import { PrivateAppLayout } from "~/shared/components/PrivateAppLayout/PrivateAppLayout";
import { onboardedGuardLoader } from "~/shared/guards/onboarded.guard";
import { FEATURE_FLAGS } from "~/shared/lib/posthog/posthog.constants";
import { lazyRetry } from "~/shared/util/lazyRetry";
import { notFound } from "~/shared/util/notFound";

export function getParticipantRoutes(queryClient: QueryClient, posthogClient?: PostHog): Array<RouteObject> {
  return [
    {
      path: "*",
      loader: () => {
        return onboardedGuardLoader(queryClient);
      },
      element: <Outlet />,
      children: [
        {
          path: "*",
          errorElement: <TopLevelRouteErrorHandler />,
          element: (
            <PrivateAppLayout>
              <React.Suspense fallback={<LoadingContainer level="page" />}>
                <Outlet />
              </React.Suspense>
            </PrivateAppLayout>
          ),
          children: [
            {
              index: true,
              errorElement: <TopLevelRouteErrorHandler />,
              lazy: async () => {
                const { HomeView } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "HomeView" */
                      "~/participant/views/HomeView/HomeView"
                    ),
                  "HomeView",
                );
                return {
                  Component: HomeView,
                };
              },
            },
            {
              path: "notifications",
              errorElement: <TopLevelRouteErrorHandler />,
              lazy: async () => {
                const { NotificationsView } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "NotificationsView" */
                      "~/participant/views/NotificationsView"
                    ),
                  "NotificationsView",
                );
                return {
                  Component: NotificationsView,
                };
              },
              children: [
                {
                  index: true,
                  errorElement: <NestedRouteErrorHandler />,
                  lazy: async () => {
                    const { NotificationsListView } = await lazyRetry(
                      () =>
                        import(
                          /* webpackChunkName: "NotificationsListView" */
                          "~/shared/views/NotificationsListView/NotificationsListView"
                        ),
                      "NotificationsListView",
                    );
                    return {
                      Component: NotificationsListView,
                    };
                  },
                },
              ],
            },
            {
              path: "jobs",
              errorElement: <TopLevelRouteErrorHandler />,
              lazy: async () => {
                const { JobsView } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "JobsView" */
                      "~/participant/views/JobsView"
                    ),
                  "JobsView",
                );
                return {
                  Component: JobsView,
                };
              },
            },
            // SAVED
            {
              path: "saved",
              errorElement: <TopLevelRouteErrorHandler />,
              lazy: async () => {
                const { SavedView } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "SavedView" */
                      "~/participant/views/SavedView"
                    ),
                  "SavedView",
                );

                return {
                  loader: async (args) => {
                    const isUtahParticipant = await getIsUtahParticipant(queryClient);
                    const canAccessUtahFeatures = posthogClient?.isFeatureEnabled(FEATURE_FLAGS.RELEASE_UTAH_PROGRAM);

                    // For now, this feature is only for participants in the Utah program track
                    if (!isUtahParticipant || !canAccessUtahFeatures) {
                      return notFound();
                    }
                    return savedLoader(args, queryClient, posthogClient);
                  },
                  Component: SavedView,
                };
              },
            },
            {
              path: "account",
              errorElement: <TopLevelRouteErrorHandler />,
              lazy: async () => {
                const { AccountView } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "AccountView" */
                      "~/participant/views/AccountView"
                    ),
                  "AccountView",
                );
                return {
                  element: <AccountView />,
                };
              },
              children: [
                {
                  index: true,
                  errorElement: <NestedRouteErrorHandler />,
                  lazy: async () => {
                    const { AccountPreferencesView } = await lazyRetry(
                      () =>
                        import(
                          /* webpackChunkName: "AccountPreferencesView" */
                          "~/shared/views/AccountPreferences/AccountPreferencesView"
                        ),
                      "AccountPreferencesView",
                    );
                    return {
                      element: <AccountPreferencesView />,
                    };
                  },
                },
                {
                  path: "notifications",
                  errorElement: <NestedRouteErrorHandler />,
                  lazy: async () => {
                    const { AccountNotificationsPreferencesView } = await lazyRetry(
                      () =>
                        import(
                          /* webpackChunkName: "AccountNotificationsPreferencesView" */
                          "~/shared/views/AccountNotificationsPreferencesView"
                        ),
                      "AccountNotificationsPreferencesView",
                    );
                    return {
                      element: <AccountNotificationsPreferencesView />,
                    };
                  },
                },
                {
                  path: "skills",
                  errorElement: <NestedRouteErrorHandler />,
                  lazy: async () => {
                    const { AccountSkillsView } = await lazyRetry(
                      () =>
                        import(
                          /* webpackChunkName: "AccountSkillsView" */
                          "~/participant/views/AccountSkillsView"
                        ),
                      "AccountSkillsView",
                    );
                    return {
                      Component: AccountSkillsView,
                    };
                  },
                },
              ],
            },
            // INTERVIEWS
            {
              path: "interviews",
              errorElement: <TopLevelRouteErrorHandler />,
              lazy: async () => {
                const { InterviewsView } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "InterviewsView" */
                      "~/participant/views/InterviewsView/InterviewsView"
                    ),
                  "InterviewsView",
                );
                return {
                  Component: InterviewsView,
                };
              },
            },
            {
              path: "interviews/:interviewId",
              errorElement: <TopLevelRouteErrorHandler />,
              lazy: async () => {
                const { InterviewView } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "InterviewView" */
                      "~/participant/views/InterviewView"
                    ),
                  "InterviewView",
                );
                return {
                  Component: InterviewView,
                };
              },
            },
            {
              path: "interviews/create",
              errorElement: <TopLevelRouteErrorHandler />,
              lazy: async () => {
                const { CreateInterviewView } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "CreateInterviewView" */
                      "~/participant/views/CreateInterviewView"
                    ),
                  "CreateInterviewView",
                );
                return {
                  Component: CreateInterviewView,
                };
              },
            },
            // EVENTS
            {
              path: "events",
              errorElement: <TopLevelRouteErrorHandler />,
              lazy: async () => {
                const { EventsView } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "EventsView" */
                      "~/participant/views/EventsView"
                    ),
                  "EventsView",
                );
                return {
                  Component: EventsView,
                };
              },
              children: [
                {
                  index: true,
                  errorElement: <NestedRouteErrorHandler />,
                  lazy: async () => {
                    const { EventTypeView } = await lazyRetry(
                      () =>
                        import(
                          /* webpackChunkName: "EventTypeView" */
                          "~/shared/views/EventTypeView"
                        ),
                      "EventTypeView",
                    );
                    return {
                      Component: EventTypeView,
                    };
                  },
                },
                {
                  path: "type/:eventTypeId",
                  errorElement: <NestedRouteErrorHandler />,
                  lazy: async () => {
                    const { EventTypeView } = await lazyRetry(
                      () =>
                        import(
                          /* webpackChunkName: "EventTypeView" */
                          "~/shared/views/EventTypeView"
                        ),
                      "EventTypeView",
                    );
                    return {
                      Component: EventTypeView,
                    };
                  },
                },
                {
                  path: "type/all",
                  loader: () => {
                    return redirect("/events");
                  },
                },
              ],
            },
            {
              path: "events/:eventId",
              errorElement: <TopLevelRouteErrorHandler />,
              shouldRevalidate: () => false,
              lazy: async () => {
                const { EventView, eventViewLoader } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "EventView" */
                      "~/participant/views/EventView"
                    ),
                  "EventView",
                );
                return {
                  loader: (args) => {
                    return eventViewLoader(args, queryClient);
                  },
                  Component: EventView,
                };
              },
            },
            // ASSIGNMENTS
            {
              path: "assignments",
              errorElement: <TopLevelRouteErrorHandler />,
              lazy: async () => {
                const { AssignmentsView } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "AssignmentsView" */
                      "~/participant/views/AssignmentsView"
                    ),
                  "AssignmentsView",
                );
                return {
                  Component: AssignmentsView,
                };
              },
              children: [
                {
                  index: true,
                  errorElement: <NestedRouteErrorHandler />,
                  shouldRevalidate: () => false,
                  lazy: async () => {
                    const { AssignmentsListView, assignmentsListViewLoader } = await lazyRetry(
                      () =>
                        import(
                          /* webpackChunkName: "AssignmentsListView" */
                          "~/participant/views/AssignmentsListView"
                        ),
                      "AssignmentsListView",
                    );
                    return {
                      loader: () => {
                        return assignmentsListViewLoader(queryClient);
                      },
                      Component: AssignmentsListView,
                    };
                  },
                },
                {
                  path: "pending",
                  errorElement: <NestedRouteErrorHandler />,
                  shouldRevalidate: () => false,
                  lazy: async () => {
                    const { AssignmentsPendingListView, assignmentsListViewLoader } = await lazyRetry(
                      () =>
                        import(
                          /* webpackChunkName: "AssignmentsPendingListView" */
                          "~/participant/views/AssignmentsListView"
                        ),
                      "AssignmentsPendingListView",
                    );
                    return {
                      loader: () => {
                        return assignmentsListViewLoader(queryClient, "pending");
                      },
                      element: <AssignmentsPendingListView type="pending" />,
                    };
                  },
                },
                {
                  path: "submitted",
                  errorElement: <NestedRouteErrorHandler />,
                  shouldRevalidate: () => false,
                  lazy: async () => {
                    const { AssignmentsSubmittedListView, assignmentsListViewLoader } = await lazyRetry(
                      () =>
                        import(
                          /* webpackChunkName: "AssignmentsSubmittedListView" */
                          "~/participant/views/AssignmentsListView"
                        ),
                      "AssignmentsSubmittedListView",
                    );
                    return {
                      loader: () => {
                        return assignmentsListViewLoader(queryClient, "submitted");
                      },
                      element: <AssignmentsSubmittedListView type="submitted" />,
                    };
                  },
                },
              ],
            },
            {
              path: "assignments/:assignmentId",
              errorElement: <TopLevelRouteErrorHandler />,
              shouldRevalidate: () => false,
              lazy: async () => {
                const { AssignmentView, assignmentViewLoader } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "AssignmentView" */
                      "~/participant/views/AssignmentView/AssignmentView"
                    ),
                  "AssignmentView",
                );
                return {
                  loader: async (args) => {
                    return assignmentViewLoader(args, queryClient);
                  },
                  Component: AssignmentView,
                };
              },
            },
            {
              path: "assignments/submissions/:submissionId",
              errorElement: <TopLevelRouteErrorHandler />,
              shouldRevalidate: () => false,
              lazy: async () => {
                const { AssignmentSubmissionView, assignmentSubmissionViewLoader } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "AssignmentSubmissionView" */
                      "~/participant/views/AssignmentSubmissionView"
                    ),
                  "AssignmentSubmissionView",
                );
                return {
                  loader: async (args) => {
                    return assignmentSubmissionViewLoader(args, queryClient);
                  },
                  Component: AssignmentSubmissionView,
                };
              },
            },
            {
              path: "surveys/:surveyId",
              errorElement: <TopLevelRouteErrorHandler />,
              shouldRevalidate: () => false,
              lazy: async () => {
                const { SurveyDetailView, surveyDetailViewLoader } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "SurveyDetailView" */
                      "~/participant/views/SurveyDetailView/SurveyDetailView"
                    ),
                  "SurveyDetailView",
                );
                return {
                  loader: (args) => {
                    return surveyDetailViewLoader(args, queryClient);
                  },
                  element: <SurveyDetailView />,
                };
              },
            },
            {
              path: "surveys/submissions/:submissionId",
              errorElement: <TopLevelRouteErrorHandler />,
              shouldRevalidate: () => false,
              lazy: async () => {
                const { SubmissionView, submissionViewLoader } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "SubmissionView" */
                      "~/participant/views/SurveySubmissionView"
                    ),
                  "SubmissionView",
                );
                return {
                  loader: (args) => {
                    return submissionViewLoader(args, queryClient);
                  },
                  Component: SubmissionView,
                };
              },
            },
            {
              path: "interview-reflections",
              errorElement: <TopLevelRouteErrorHandler />,
              shouldRevalidate: () => false,
              lazy: async () => {
                const { ParticipantSurveysView, participantSurveysViewLoader } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "ParticipantSurveysView" */
                      "~/participant/views/ParticipantSurveysView"
                    ),
                  "ParticipantSurveysView",
                );
                return {
                  loader: () => {
                    return participantSurveysViewLoader(queryClient);
                  },
                  Component: ParticipantSurveysView,
                };
              },
            },
            {
              path: "companies",
              errorElement: <TopLevelRouteErrorHandler />,
              lazy: async () => {
                const { CompaniesView } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "CompaniesView" */
                      "~/participant/views/CompaniesView/CompaniesView"
                    ),
                  "CompaniesView",
                );
                return {
                  Component: CompaniesView,
                };
              },
            },
            {
              path: "companies/:partnerId",
              errorElement: <TopLevelRouteErrorHandler />,
              lazy: async () => {
                const { CompanyView, companyLoader } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "CompanyView" */
                      "~/participant/views/CompanyView"
                    ),
                  "CompanyView",
                );
                return {
                  loader: async (args) => {
                    const { params } = args;
                    const { partnerId } = params;

                    if (!partnerId) {
                      return notFound();
                    }

                    return companyLoader(queryClient, partnerId);
                  },
                  Component: CompanyView,
                };
              },
            },
            {
              path: "partners",
              loader: async () => {
                return redirect(COMPANIES_PATHS.companies);
              },
              children: [
                {
                  path: ":partnerId",
                  loader: async ({ params }) => {
                    if (!params.partnerId) {
                      return notFound();
                    }

                    return redirect(COMPANIES_PATHS.detail(params.partnerId));
                  },
                  children: [
                    {
                      path: "preview",
                      loader: async ({ params }) => {
                        if (!params.partnerId) {
                          return notFound();
                        }

                        return redirect(COMPANIES_PATHS.detail(params.partnerId));
                      },
                    },
                  ],
                },
              ],
            },
            // GAME PLANS
            {
              path: "jobs/game-plan/:gamePlanId",
              errorElement: <TopLevelRouteErrorHandler />,
              lazy: async () => {
                const { JobGamePlansView } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "JobGamePlansView" */
                      "~/participant/views/JobGamePlansView"
                    ),
                  "JobGamePlansView",
                );
                return {
                  loader: async () => {
                    const isUtahParticipant = await getIsUtahParticipant(queryClient);

                    if (isUtahParticipant) {
                      notFound();
                    }

                    return null;
                  },
                  Component: JobGamePlansView,
                };
              },
            },
            {
              path: "game-plans",
              errorElement: <TopLevelRouteErrorHandler />,
              loader: async () => {
                const isUtahParticipant = await getIsUtahParticipant(queryClient);

                if (isUtahParticipant) {
                  notFound();
                }

                return null;
              },
              lazy: async () => {
                const { GamePlansView } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "GamePlansView" */
                      "~/participant/views/GamePlansView"
                    ),
                  "GamePlansView",
                );
                return {
                  Component: GamePlansView,
                };
              },
            },
            // CONTENT LIBRARY
            {
              path: "content-library",
              errorElement: <TopLevelRouteErrorHandler />,
              lazy: async () => {
                const { ContentLibraryView } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "ContentLibraryView" */
                      "~/participant/views/ContentLibraryView"
                    ),
                  "ContentLibraryView",
                );
                return {
                  Component: ContentLibraryView,
                };
              },
            },
            {
              path: "content-library/:contentId",
              errorElement: <TopLevelRouteErrorHandler />,
              lazy: async () => {
                const { ContentLibraryItemView } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "ContentLibraryItemView" */
                      "~/participant/views/ContentLibraryItemView"
                    ),
                  "ContentLibraryItemView",
                );
                return {
                  Component: ContentLibraryItemView,
                };
              },
            },
            {
              path: "*",
              element: (
                <div className="content-container flex min-h-[calc(100dvh-var(--siteheader-height))] items-center justify-center">
                  <NotFound />
                </div>
              ),
            },
          ],
        },
      ],
    },
    {
      path: "onboarding",
      errorElement: <TopLevelRouteErrorHandler />,
      lazy: async () => {
        const { OnboardingView, onboardingLoader } = await lazyRetry(
          () =>
            import(
              /* webpackChunkName: "OnboardingView" */
              "~/participant/views/OnboardingView/OnboardingView"
            ),
          "OnboardingView",
        );

        return {
          loader: () => onboardingLoader(queryClient),
          Component: OnboardingView,
        };
      },
    },
    {
      path: UTAH_PATHS.onboarding,
      errorElement: <TopLevelRouteErrorHandler />,
      lazy: async () => {
        const { UtahOnboardingView, utahOnboardingLoader } = await lazyRetry(
          () =>
            import(
              /* webpackChunkName: "UtahOnboardingView" */
              "~/participant/views/utah-onboarding/UtahOnboardingView"
            ),
          "UtahOnboardingView",
        );
        return {
          shouldRevalidate: () => false,
          loader: async () => {
            return utahOnboardingLoader(queryClient, posthogClient);
          },
          Component: UtahOnboardingView,
        };
      },
      children: [
        {
          index: true,
          errorElement: <NestedRouteErrorHandler />,
          lazy: async () => {
            const { UtahOnboardingWelcomeView, utahOnboardingWelcomeLoader } = await lazyRetry(
              () =>
                import(
                  /* webpackChunkName: "UtahOnboardingWelcomeView" */
                  "~/participant/views/utah-onboarding/UtahOnboardingWelcomeView"
                ),
              "UtahOnboardingWelcomeView",
            );
            return {
              loader: (args) => {
                return maybeRedirectToLatestStep(args.request.url, queryClient, () => {
                  return maybeAccessStep(UTAH_PROGRAM_AVAILABLE_STEPS.welcome.step, queryClient, () => {
                    return utahOnboardingWelcomeLoader();
                  });
                });
              },
              Component: UtahOnboardingWelcomeView,
            };
          },
        },
        {
          path: "basic-info",
          errorElement: <NestedRouteErrorHandler />,
          lazy: async () => {
            const { UtahOnboardingBasicInfoView } = await lazyRetry(
              () =>
                import(
                  /* webpackChunkName: "UtahOnboardingBasicInfoView" */
                  "~/participant/views/utah-onboarding/UtahOnboardingBasicInfoView"
                ),
              "UtahOnboardingBasicInfoView",
            );
            return {
              loader: (args) => {
                return maybeRedirectToLatestStep(args.request.url, queryClient, () => {
                  return maybeAccessStep(UTAH_PROGRAM_AVAILABLE_STEPS["basic-info"].step, queryClient, () => {
                    return utahOnboardingBasicInfoLoader();
                  });
                });
              },
              Component: UtahOnboardingBasicInfoView,
            };
          },
        },
        {
          path: "self-identification",
          lazy: async () => {
            const { UtahOnboardingSelfIdentificationView, utahOnboardingSelfIdentificationLoader } = await lazyRetry(
              () =>
                import(
                  /* webpackChunkName: "UtahOnboardingSelfIdentificationView" */
                  "~/participant/views/utah-onboarding/UtahOnboardingSelfIdentificationView"
                ),
              "UtahOnboardingSelfIdentificationView",
            );
            return {
              loader: (args) => {
                return maybeRedirectToLatestStep(args.request.url, queryClient, () => {
                  return maybeAccessStep(UTAH_PROGRAM_AVAILABLE_STEPS.demographics.step, queryClient, () => {
                    return utahOnboardingSelfIdentificationLoader(queryClient);
                  });
                });
              },
              element: <UtahOnboardingSelfIdentificationView />,
            };
          },
        },
        {
          path: "taxonomy",
          lazy: async () => {
            const { UtahOnboardingTaxonomyView, utahOnboardingTaxonomyLoader } = await lazyRetry(
              () =>
                import(
                  /* webpackChunkName: "UtahOnboardingTaxonomyView" */
                  "~/participant/views/utah-onboarding/UtahOnboardingTaxonomyView"
                ),
              "UtahOnboardingTaxonomyView",
            );
            return {
              loader: (args) => {
                return maybeRedirectToLatestStep(args.request.url, queryClient, () => {
                  return maybeAccessStep(UTAH_PROGRAM_AVAILABLE_STEPS.taxonomy.step, queryClient, () => {
                    return utahOnboardingTaxonomyLoader(queryClient);
                  });
                });
              },
              element: <UtahOnboardingTaxonomyView />,
            };
          },
        },
        {
          path: "bonus",
          lazy: async () => {
            const { UtahOnboardingBonusView, utahOnboardingBonusLoader } = await lazyRetry(
              () =>
                import(
                  /* webpackChunkName: "UtahOnboardingBonusView" */
                  "~/participant/views/utah-onboarding/UtahOnboardingBonusView"
                ),
              "UtahOnboardingBonusView",
            );
            return {
              loader: (args) => {
                return maybeRedirectToLatestStep(args.request.url, queryClient, () => {
                  return maybeAccessStep(UTAH_PROGRAM_AVAILABLE_STEPS.bonus.step, queryClient, () => {
                    return utahOnboardingBonusLoader();
                  });
                });
              },
              element: <UtahOnboardingBonusView />,
            };
          },
        },
        {
          path: "commitment",
          lazy: async () => {
            const { UtahOnboardingCommitmentView, utahOnboardingCommitmentLoader } = await lazyRetry(
              () =>
                import(
                  /* webpackChunkName: "UtahOnboardingCommitmentView" */
                  "~/participant/views/utah-onboarding/UtahOnboardingCommitmentView"
                ),
              "UtahOnboardingCommitmentView",
            );
            return {
              loader: (args) => {
                return maybeRedirectToLatestStep(args.request.url, queryClient, () => {
                  return maybeAccessStep(UTAH_PROGRAM_AVAILABLE_STEPS.commitment.step, queryClient, () => {
                    return utahOnboardingCommitmentLoader();
                  });
                });
              },
              element: <UtahOnboardingCommitmentView />,
            };
          },
        },
        {
          path: "*",
          element: (
            <div className="content-container flex min-h-[calc(100dvh-var(--siteheader-height))] items-center justify-center">
              <NotFound />
            </div>
          ),
        },
      ],
    },
  ];
}
