import { useSuspenseQuery } from "@tanstack/react-query";
import * as React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { USER_OPTIONS } from "~/shared/api/user/user";
import { getRouteTreeRoot } from "~/shared/api/user/user.helpers";
import { useSidebarNavState } from "~/shared/components/PrivateAppLayout/context/SidebarNavContext";
import { Logo } from "~/shared/components/ds/Logo";
import { Logomark } from "~/shared/components/ds/Logomark";
import { Icon, IconUse } from "~/shared/components/ds/icons/Icon";
import { navAction } from "~/shared/styles/layout.styles";

interface SidebarNavProps {
  children?: React.ReactNode;
}
export function SidebarNav({ children }: SidebarNavProps) {
  const { isOpen, setIsOpen } = useSidebarNavState() ?? {};
  const { container } = navAction({
    state: isOpen ? "expanded" : "collapsed",
  });

  return (
    <div className="border-ds-stroke-tertiary bg-ds-bg-foundation flex h-screen w-full flex-col border-r border-solid">
      <header
        className={twMerge(
          "flex flex-wrap items-center justify-between gap-5",
          isOpen ? "h-[var(--siteheader-height)] px-6" : "justify-center px-5 py-6",
        )}
      >
        <div className={twMerge("w-full", isOpen ? "max-w-[150px]" : "max-w-[30px]")}>
          <ErrorBoundary fallback={<LogoLinkContent isOpen={isOpen === true} />}>
            <React.Suspense fallback={<LogoLinkContent isOpen={isOpen === true} />}>
              <LogoLink>
                <LogoLinkContent isOpen={isOpen === true} />
              </LogoLink>
            </React.Suspense>
          </ErrorBoundary>
        </div>
        <button
          className={container({
            state: "collapsed",
            className: "hidden h-10 w-10 place-items-center [@media(min-width:1281px)]:grid",
          })}
          aria-label={`${isOpen ? "Collapse" : "Expand"} Sidebar Navigation`}
          onClick={() => setIsOpen?.((prev) => !prev)}
        >
          <Icon className="text-ds-icon-primary" aria-hidden>
            <IconUse id={isOpen ? "sidebar-fold-line" : "sidebar-unfold-line"} />
          </Icon>
        </button>
      </header>
      <div className="flex min-h-0 flex-1">{children}</div>
    </div>
  );
}

function LogoLink({ children }: { children?: React.ReactNode }) {
  const { data: user } = useSuspenseQuery(USER_OPTIONS.user());
  const routeTreeRoot = getRouteTreeRoot(user.type);

  return (
    <Link to={routeTreeRoot} aria-label="Go to homepage" className="block w-full">
      {children}
    </Link>
  );
}

function LogoLinkContent({ isOpen }: { isOpen: boolean }) {
  return isOpen ? <Logo aria-hidden theme="dark" /> : <Logomark aria-hidden />;
}
