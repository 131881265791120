export const ERROR_CODES = {
  BAD_REQUEST: "BAD_REQUEST",
  CONFLICT: "CONFLICT",
  NETWORK_ERROR: "NETWORK_ERROR",
  NOT_FOUND: "NOT_FOUND",
  RATE_LIMITED: "RATE_LIMITED",
  SERVER_ERROR: "SERVER_ERROR",
  TEAPOT: "TEAPOT",
  UNAUTHORIZED: "UNAUTHORIZED",
  UNKNOWN_ERROR: "UNKNOWN_ERROR",
  INVALID_ERROR: "INVALID_ERROR",
  REQUEST_CANCELLED: "REQUEST_CANCELLED",
} as const;

interface ApiErrorDetails {
  code: keyof typeof ERROR_CODES;
  status?: number;
  originalError: unknown;
  response?: {
    data?: unknown;
    headers?: Record<string, string>;
  };
  request?: {
    url?: string;
    method?: string;
    headers?: Record<string, string>;
    data?: unknown;
  };
}

export class ApiError extends Error {
  constructor(
    message: string,
    public details: ApiErrorDetails,
  ) {
    super(message);
    this.name = "ApiError";
    // Maintains proper stack trace
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ApiError);
    }
  }
}

export function createApiError(message: string, error: any, code: keyof typeof ERROR_CODES, status?: number) {
  const details: ApiErrorDetails = {
    code,
    status,
    originalError: error,
    response: error.response
      ? {
          data: error.response.data,
          headers: error.response.headers,
        }
      : undefined,
    request: error.config
      ? {
          url: error.config.url,
          method: error.config.method,
          headers: error.config.headers,
          data: error.config.data,
        }
      : undefined,
  };
  return new ApiError(message, details);
}

export function handleApiError(error: unknown) {
  // Ensure error is an object
  if (!error || typeof error !== "object") {
    return Promise.reject(createApiError("An invalid error was thrown", error, ERROR_CODES.INVALID_ERROR));
  }

  if ("__CANCEL__" in error) {
    return Promise.reject(createApiError("Request was cancelled", error, ERROR_CODES.REQUEST_CANCELLED));
  }

  if (!("response" in error)) {
    return Promise.reject(createApiError("Network error occurred", error, ERROR_CODES.NETWORK_ERROR));
  }

  if (typeof error === "object" && "status" in error && typeof error.status === "number") {
    switch (error.status) {
      case 400:
        return Promise.reject(createApiError("Bad request", error, ERROR_CODES.BAD_REQUEST, error.status));
      case 401:
      case 403:
        return Promise.reject(createApiError("Unauthorized", error, ERROR_CODES.UNAUTHORIZED, error.status));
      case 404:
        return Promise.reject(createApiError("Resource not found", error, ERROR_CODES.NOT_FOUND, error.status));
      case 409:
        return Promise.reject(createApiError("Conflict with existing data", error, ERROR_CODES.CONFLICT, error.status));
      case 418:
        return Promise.reject(createApiError("Server is a teapot", error, ERROR_CODES.TEAPOT, error.status));
      case 429:
        return Promise.reject(createApiError("Too many requests", error, ERROR_CODES.RATE_LIMITED, error.status));
      case 500:
        return Promise.reject(createApiError("Internal server error", error, ERROR_CODES.SERVER_ERROR, error.status));
      default:
        return Promise.reject(createApiError("Unknown error occurred", error, ERROR_CODES.UNKNOWN_ERROR, error.status));
    }
  }

  return Promise.reject(createApiError("Unknown error occurred", error, ERROR_CODES.UNKNOWN_ERROR));
}
