import { ReactComponent as Logo } from "~/shared/components/svg/logo.svg";

interface PublicAppLayoutProps {
  children: React.ReactNode;
}
export function PublicAppLayout({ children }: PublicAppLayoutProps) {
  return (
    <div className="min-h-screen w-full">
      <header className="pointer-events-none absolute inset-0 z-10 p-6 lg:p-10">
        <Logo aria-label="BreakLine Logo" style={{ width: 176 }} />
      </header>
      {children}
    </div>
  );
}
