import { QueryClient } from "@tanstack/react-query";
import { PostHog } from "posthog-js";
import { LoaderFunctionArgs, redirect } from "react-router-dom";
import { USER_OPTIONS } from "~/shared/api/user/user";
import { TEAM_EVENTS } from "~/team/constants/events.constants";
import { TEAM_EXPERIENCE_PREFIX } from "~/team/constants/paths.constants";

/**
 * Checks if the user type is 'breakline' and redirects to a prefixed URL if necessary.
 *
 * This helps transition our team application's adoption of the `/hq` prefix.
 *
 * @param args - The arguments provided by the loader function, including the request object.
 * @param queryClient - The query client used to fetch user data.
 * @returns A redirect response if the URL lacks the required prefix; otherwise, returns null.
 */
export async function maybeRedirectWithPrefix(
  args: LoaderFunctionArgs,
  queryClient: QueryClient,
  posthogClient?: PostHog,
) {
  const user = await queryClient.ensureQueryData(USER_OPTIONS.user());

  if (user.type === "breakline") {
    const { request } = args;
    const url = new URL(request.url);
    const hasPrefix = url.pathname.startsWith(`/${TEAM_EXPERIENCE_PREFIX}`);

    if (hasPrefix) return null;

    posthogClient?.capture(TEAM_EVENTS.TEAM_PREFIX_REDIRECT_CALLED, {
      requestPathname: url.pathname,
    });

    return redirect(`/${TEAM_EXPERIENCE_PREFIX}${url.pathname === "/" ? "" : url.pathname}`);
  }

  return null;
}
