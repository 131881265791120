import loadable from "@loadable/component";
import { format } from "date-fns";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";
import screen from "superior-mq";
import apiService from "~/shared/api/api-service";
import { useUserProfile } from "~/shared/api/user/user";
import { isBreakLineUser } from "~/shared/api/user/user.helpers";
import Grid from "~/shared/components/Grid";
import { H4 } from "~/shared/components/H";
import ResponsiveModal from "~/shared/components/ResponsiveModal";
import { Button } from "~/shared/components/ds/Button";
import { toast } from "~/shared/components/ds/Toast/Toast";
import Combo from "~/shared/components/forms/Combo";
import DateInput from "~/shared/components/forms/DateInput";
import Input from "~/shared/components/forms/Input";
import MultiSelect from "~/shared/components/forms/MultiSelect";
import Textarea from "~/shared/components/forms/Textarea";
import useNotificationStore from "~/shared/stores/notificationStore";
import { bp } from "~/shared/styles/helpers";
import { formatFormValues, urlPattern } from "~/shared/util/forms";

const StyledGrid = styled(Grid)`
  ${screen.below(
    bp.mobileMid,
    `
    grid-template-columns: 1fr;

    * {
      grid-column: span 1;
    }
  `,
  )}
`;

// Lazy load Components
const Wysiwyg = loadable(() => import("~/shared/components/forms/TextEditor"));

const participantConverter = (p) => {
  if (!p || !p.id) return {};
  return {
    headshot: p.headshot,
    firstName: p.firstName,
    lastName: p.lastName,
    value: p.id,
    label: `${p.firstName} ${p.lastName}`,
  };
};

const typeOptions = [
  { value: "note", label: "FYI" },
  { value: "task", label: "Task" },
];

const defaultValues = {
  type: typeOptions[0],
  title: "",
  body: "",
  dueDate: "",
  url: "",
  assignedTo: [],
  notification: null,
  partnerId: null,
  userId: null,
  gameplanStatusId: null,
  interviewRoundId: null,
  jobId: null,
  value: "",
};

const mapToFormValues = (modalData) => ({
  notification: modalData.notification || null,
  title: modalData.title || "",
  body: modalData.body || "",
});

const CreateTaskModal = ({ modalOpen, modalData, onRequestClose }) => {
  const language = modalData.noteType === "user" ? "Action Item" : "Note";
  const [mentions, setMentions] = useState([]);
  const { incrementTaskCount } = useNotificationStore();
  const { data: user } = useUserProfile();
  const {
    handleSubmit,
    control,
    reset,
    register,
    watch,
    formState: { isSubmitting, errors },
  } = useForm({
    mode: "onTouched",
    defaultValues,
    shouldUnregister: true,
  });

  const typeWatch = watch("type");

  const onSubmit = (values) => {
    let formData = formatFormValues(values, false);

    const isNote = modalData.allowNote && typeWatch?.value === "note";

    if (isNote) {
      formData.mentions = mentions?.map((m) => Number.parseInt(m.id, 10));
    } else {
      formData.dueDate = new Date(values.dueDate).toUTCString();
      formData.partnerId = modalData.partnerId || undefined;
      formData.userId = modalData.userId || undefined;
      formData.jobId = modalData.jobId || undefined;
      formData.interviewRoundId = modalData.interviewRoundId || undefined;
      formData.gameplanStatusId = modalData.gameplanStatusId || undefined;

      if (modalData.notification) {
        formData.notification = modalData.notification;
      }
    }

    delete formData.type;

    return apiService
      .save(isNote ? modalData.endpoint : "tasks", formData)
      .then(() => {
        if (!isNote) {
          incrementTaskCount();
        }

        onRequestClose(isNote);
        toast.success(isNote ? `${language} created.` : "Task created.");
      })
      .catch(() => {
        toast.error(`Failed to create ${isNote ? language : "task"}.`);
      });
  };

  // Empty the form on modal close
  useEffect(() => {
    reset({ ...defaultValues, ...mapToFormValues(modalData) });
    setMentions([]);
  }, [modalOpen, modalData, reset]);

  return (
    <ResponsiveModal isOpen={!!modalOpen} onRequestClose={onRequestClose} large>
      <H4>{typeWatch?.value === "note" ? `Add ${language}` : "Create Task"}</H4>

      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledGrid formGrid cols={2}>
          {isBreakLineUser(user?.type) && modalData.allowNote ? (
            <Grid.Item span={2}>
              <Controller
                render={({ field }) => (
                  <Combo {...field} options={typeOptions} label="Type" errors={errors} showToggleButton />
                )}
                name="type"
                control={control}
              />
            </Grid.Item>
          ) : (
            <input {...register("type", { value: typeOptions[0] })} disabled type="hidden" />
          )}

          {isBreakLineUser(user?.type) && (typeWatch?.value === "task" || !modalData.allowNote) ? (
            <>
              <Grid.Item>
                <Controller
                  render={({ field }) => (
                    <MultiSelect
                      {...field}
                      apiPath="staff"
                      label="Assigned To"
                      optionFormatter={participantConverter}
                      errors={errors}
                      required
                    />
                  )}
                  control={control}
                  name="assignedTo"
                  rules={{
                    required: "Please select someone to assign this task to.",
                  }}
                />
              </Grid.Item>
              <Grid.Item>
                <Controller
                  render={({ field }) => (
                    <DateInput
                      {...field}
                      label="Due Date"
                      useTime
                      min={format(Date.now(), "yyyy-MM-dd'T'HH:mm")}
                      errors={errors}
                      required
                    />
                  )}
                  control={control}
                  name="dueDate"
                />
              </Grid.Item>
              <Grid.Item span={2}>
                <Input
                  {...register("title", {
                    maxLength: 255,
                  })}
                  type="text"
                  label="Task"
                  maxLength={255}
                  errors={errors}
                  required
                />
              </Grid.Item>

              {!modalData.notification &&
              !modalData.partnerId &&
              !modalData.userId &&
              !modalData.interviewRoundId &&
              !modalData.gameplanStatusId &&
              !modalData.jobId ? (
                <Grid.Item span={2}>
                  <Input
                    {...register("url", {
                      pattern: {
                        value: urlPattern,
                        message: "Invalid URL.",
                      },
                    })}
                    type="text"
                    label="URL"
                    placeholder="https://app.breakline.org/..."
                    errors={errors}
                  />
                </Grid.Item>
              ) : null}

              <Grid.Item span={2}>
                <Controller
                  render={({ field }) => (
                    <Wysiwyg {...field} label="Description" placeholder="Lorem ipsum..." allowUpdates errors={errors} />
                  )}
                  control={control}
                  name="body"
                  defaultValue=""
                />
              </Grid.Item>
            </>
          ) : (
            <Grid.Item span={2}>
              <Controller
                render={({ field }) => (
                  <Textarea
                    {...field}
                    label={isBreakLineUser(user?.type) ? `${language}` : "Add a note for Team BreakLine!"}
                    rows="2"
                    allowMentions={isBreakLineUser(user?.type)}
                    onChange={
                      isBreakLineUser(user?.type)
                        ? (_a, newValue, _c, mentions) => {
                            field.onChange(newValue);
                            setMentions((prev) => [...prev, ...mentions]);
                          }
                        : field.onChange
                    }
                    errors={errors}
                    required
                  />
                )}
                rules={{
                  required: "Please enter a note",
                }}
                name="value"
                control={control}
              />
            </Grid.Item>
          )}

          <Grid.Item>
            <Button size="lg" type="submit" disabled={isSubmitting} isLoading={isSubmitting}>
              Create
            </Button>
          </Grid.Item>
        </StyledGrid>
      </form>
    </ResponsiveModal>
  );
};

CreateTaskModal.propTypes = {
  modalOpen: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  modalData: PropTypes.object,
  onRequestClose: PropTypes.func,
};

export default CreateTaskModal;
