import type { QueryClient } from "@tanstack/react-query";
import { PostHog } from "posthog-js";
import * as React from "react";
import { Outlet, RouteObject } from "react-router-dom";
import { getParticipantRoutes } from "~/participant/participant.routes";
import { TopLevelRouteErrorHandler } from "~/shared/components/Error";
import { SocketProvider } from "~/shared/components/SocketProvider";
import { LoadingContainer } from "~/shared/components/ds/Spinner";
import { authenticationGuardLoader } from "~/shared/guards/authentication.guard";
import { getTeamRoutes } from "~/team/team.routes";
import { maybeRedirectWithPrefix } from "~/team/utils/maybe-prefix-redirect.helpers";

export function PrivateRoutes(queryClient: QueryClient, posthogClient?: PostHog): RouteObject[] {
  return [
    {
      path: "*",
      loader: async (args) => {
        return authenticationGuardLoader(args, () => {
          return maybeRedirectWithPrefix(args, queryClient, posthogClient);
        });
      },
      errorElement: <TopLevelRouteErrorHandler />,
      element: (
        <React.Suspense fallback={<LoadingContainer level="full" />}>
          <SocketProvider>
            <Outlet />
          </SocketProvider>
        </React.Suspense>
      ),
      children: [...getTeamRoutes(queryClient, posthogClient), ...getParticipantRoutes(queryClient, posthogClient)],
    },
  ];
}
