import { EDIT_SAVED_ROLE_NOTES_FORM_ID, EditSavedRoleNotesForm } from "~/participant/components/EditSavedRoleNotesForm";
import { ResponsiveDialogLayout } from "~/shared/components/ResponsiveDialogLayout";
import { Button } from "~/shared/components/ds/Button";
import { DialogClose, DialogFooter } from "~/shared/components/ds/Dialog";
import { DrawerClose, DrawerFooter } from "~/shared/components/ds/Drawer";
import { toast } from "~/shared/components/ds/Toast/Toast";

export function EditSavedRoleNotesDialog({
  savedRoleId,
  notes,
  children,
}: {
  savedRoleId: number;
  notes: string;
  children: React.ReactNode;
}) {
  return (
    <ResponsiveDialogLayout
      title="Notes"
      description="Create and modify notes for this role."
      dialogContent={(setIsOpen) => {
        return (
          <EditSavedRoleNotesForm
            savedRoleId={savedRoleId}
            notes={notes}
            onSuccessfulSubmit={() => {
              setIsOpen(false);
            }}
            onFailedSubmit={() => {
              toast.error("Failed to update note");
            }}
          >
            {(isSubmitting) => {
              return (
                <DialogFooter>
                  <DialogClose asChild>
                    <Button variant="outline">Cancel</Button>
                  </DialogClose>
                  <Button
                    variant="secondary"
                    form={EDIT_SAVED_ROLE_NOTES_FORM_ID}
                    type="submit"
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                  >
                    Save
                  </Button>
                </DialogFooter>
              );
            }}
          </EditSavedRoleNotesForm>
        );
      }}
      drawerContent={(setIsOpen) => {
        return (
          <EditSavedRoleNotesForm
            savedRoleId={savedRoleId}
            notes={notes}
            onSuccessfulSubmit={() => {
              setIsOpen(false);
            }}
            onFailedSubmit={() => {
              toast.error("Failed to update note");
            }}
          >
            {(isSubmitting) => {
              return (
                <DrawerFooter className="px-0 pb-0 pt-6">
                  <DrawerClose asChild>
                    <Button variant="outline">Cancel</Button>
                  </DrawerClose>
                  <Button
                    variant="secondary"
                    form={EDIT_SAVED_ROLE_NOTES_FORM_ID}
                    type="submit"
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                  >
                    Save
                  </Button>
                </DrawerFooter>
              );
            }}
          </EditSavedRoleNotesForm>
        );
      }}
    >
      {children}
    </ResponsiveDialogLayout>
  );
}
