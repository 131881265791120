import { Arguments } from "swr";

export function createSWRKeyChecker(name: string) {
  return (arg: Arguments) => {
    if (!Boolean(arg)) return false;

    if (typeof arg === "string") {
      return arg.startsWith(name);
    }

    if (Array.isArray(arg) && typeof arg[0] === "string") {
      return arg[0].startsWith(name);
    }

    return false;
  };
}
