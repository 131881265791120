import { zodResolver } from "@hookform/resolvers/zod";
import { InfiniteData, useMutation, useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import * as React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import api from "~/shared/api/api";
import { JOBS_KEYS, JOBS_QUERY_OPTIONS, useInvalidateJobsCache } from "~/shared/api/jobs";
import { PARTNER_QUERY_OPTIONS } from "~/shared/api/partners";
import { CompanyAvatar } from "~/shared/components/ds/Avatar";
import { Badge } from "~/shared/components/ds/Badge";
import { Button, ButtonIcon } from "~/shared/components/ds/Button";
import {
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerNestedRoot,
  DrawerTitle,
} from "~/shared/components/ds/Drawer";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "~/shared/components/ds/Form";
import { Icon, IconUse } from "~/shared/components/ds/icons/Icon";
import { Popover, PopoverContent } from "~/shared/components/ds/Popover";
import { Separator } from "~/shared/components/ds/Separator";
import { Skeleton } from "~/shared/components/ds/Skeleton";
import { toast } from "~/shared/components/ds/Toast/Toast";
import { CommandListLoadingFallback } from "~/shared/components/FiltersV3/components/CommandList";
import useMediaQuery from "~/shared/hooks/useMediaQuery";
import { heading } from "~/shared/styles/heading";
import { copy } from "~/shared/styles/text";
import { createInfiniteDataTransform } from "~/shared/util/createInfiniteDataTransform";
import { CommandListNew } from "~/team/components/CommandListNew";
import { MergeSelectTrigger } from "~/team/components/MergeSelectTrigger";
import { JOB_PATHS } from "~/team/constants/paths.constants";
import { ROLE_MERGE_FORM_ID } from "~/team/views/JobView/constants/form.constants";

export function RoleMergeForm({
  jobId,
  formId,
  setIsOpen,
}: {
  jobId: string;
  formId: string;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const navigate = useNavigate();
  const roleMergeMutation = useRoleMergeMutation();
  const { data } = useSuspenseQuery({
    ...JOBS_QUERY_OPTIONS.detail(jobId),
    select: (data) => {
      return {
        ...data,
        name: data.title,
      };
    },
  });
  const form = useForm<RoleMergeFormInput>({
    mode: "onSubmit",
    resolver: zodResolver(RoleMergeFormSchema),
    defaultValues: {
      source: data,
      target: null,
    },
  });

  const { control, handleSubmit, getValues, setValue, clearErrors } = form;
  const [target, source] = useWatch({
    control,
    name: ["target", "source"],
  });

  return (
    <Form {...form}>
      <form
        id={formId}
        className="flex flex-1 flex-col"
        onSubmit={handleSubmit((data) => {
          if (!data.source?.id || !data.target?.id) {
            toast.error("Both source and target jobs must be selected before proceeding.");
            return;
          }

          const targetId = data.target.id;

          roleMergeMutation.mutate(
            {
              targetId: data.target.id,
              sourceId: data.source.id,
            },
            {
              onSuccess: () => {
                toast.success("Job merge complete", {
                  description: "Take a moment to verify the details",
                  closeButton: true,
                  duration: 5000,
                });
                setIsOpen(false);
                navigate(JOB_PATHS.detail(targetId.toString()));
              },
              onError: () => {
                toast.error("Failed to merge jobs");
              },
            },
          );
        })}
      >
        <div className="bg-ds-bg-foundation flex flex-col gap-y-2 px-6 pb-3">
          <div className="flex flex-col gap-y-3 py-3">
            <FormField
              control={control}
              name="source"
              render={({ field: { onChange, value, ref, onBlur } }) => {
                return (
                  <FormItem>
                    <FormLabel className="sr-only">Source Role</FormLabel>
                    <RoleCard
                      roleId={value?.id ?? null}
                      badge={
                        <Badge variant="subtle" colorScheme="yellow" size="sm" shape="pill">
                          Source
                        </Badge>
                      }
                    >
                      <FormControl>
                        <RoleSelectLayout
                          selection={value}
                          onSelect={(item) => {
                            onChange(item);
                          }}
                          ref={ref}
                          onBlur={onBlur}
                        >
                          Select Source
                        </RoleSelectLayout>
                      </FormControl>
                    </RoleCard>
                    <FormMessage className="text-center" />
                  </FormItem>
                );
              }}
            />
            <Separator orientation="horizontal">
              <Button
                type="button"
                variant="ghost"
                size="sm"
                prefix={
                  <ButtonIcon>
                    <IconUse id="arrow-up-down-line" />
                  </ButtonIcon>
                }
                onClick={() => {
                  const { source, target } = getValues();
                  setValue("source", target);
                  setValue("target", source);
                  clearErrors();
                }}
              >
                Swap
              </Button>
            </Separator>
            <FormField
              control={control}
              name="target"
              render={({ field: { onChange, value, ref, onBlur } }) => {
                return (
                  <FormItem>
                    <FormLabel className="sr-only">Target Role</FormLabel>

                    <RoleCard
                      roleId={value?.id ?? null}
                      badge={
                        <Badge variant="subtle" colorScheme="green" size="sm" shape="pill">
                          Target
                        </Badge>
                      }
                    >
                      <FormControl>
                        <RoleSelectLayout
                          selection={value}
                          onSelect={(item) => {
                            onChange(item);
                          }}
                          ref={ref}
                          onBlur={onBlur}
                        >
                          Select Target
                        </RoleSelectLayout>
                      </FormControl>
                    </RoleCard>
                    <FormMessage className="text-center" />
                  </FormItem>
                );
              }}
            />
          </div>
          <div className="flex w-full justify-center py-2" aria-hidden>
            {/* TODO: consider animating whenever for change happens like selection or swapping */}
            <Icon className="text-ds-icon-tertiary size-5">
              <IconUse id="arrow-down-line" />
            </Icon>
          </div>
        </div>
        <div className="bg-ds-bg-weaker border-ds-stroke-tertiary flex flex-1 items-center border-t">
          <div className="flex w-full flex-col gap-2 overflow-hidden p-6">
            {target?.id != null && source?.id != null ? (
              <RoleCard
                roleId={target.id}
                badge={
                  <Badge variant="solid" colorScheme="teal" size="sm" shape="pill">
                    Preview
                  </Badge>
                }
              />
            ) : (
              <RoleCard
                roleId={null}
                badge={
                  <Badge variant="solid" colorScheme="red" size="sm" shape="pill">
                    Missing Info
                  </Badge>
                }
              />
            )}
            <p className={copy({ variant: "14", color: "primary", className: "text-balance" })}>
              Not the expected results? <span className="text-ds-text-secondary">Try swapping the target.</span>
            </p>
          </div>
        </div>
      </form>
    </Form>
  );
}

const RoleSchema = z.object({
  id: z.coerce.number(),
  title: z.string(),
  name: z.string(),
  partner: z
    .object({
      id: z.coerce.number(),
      name: z.string(),
      logo: z.object({ location: z.string() }).optional(),
    })
    .optional(),
  aggregates: z
    .object({
      highPriority: z.coerce.number(),
    })
    .optional(),
});
type Role = z.infer<typeof RoleSchema>;

const RoleMergeFormSchema = z
  .object({
    target: RoleSchema.nullable(),
    source: RoleSchema.nullable(),
  })
  .superRefine((arg, ctx) => {
    if (!arg.source) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "No source provided",
        path: ["source"],
      });
      return;
    }

    if (!arg.target) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "No target provided",
        path: ["target"],
      });
    }
  });

type RoleMergeFormInput = z.infer<typeof RoleMergeFormSchema>;

function RoleCard({
  roleId,
  badge,
  children,
}: React.PropsWithChildren<{
  roleId: number | null;
  badge: React.ReactNode;
}>) {
  return (
    <div className="border-ds-stroke-tertiary bg-ds-bg-foundation rounded-lg border">
      {roleId != null ? (
        <ErrorBoundary fallback={<RoleCardContentPlaceholder badge={badge} />}>
          <React.Suspense fallback={<RoleCardContentPlaceholder loading badge={badge} />}>
            <RoleCardContent jobId={roleId} badge={badge} />
          </React.Suspense>
        </ErrorBoundary>
      ) : (
        <RoleCardContentPlaceholder badge={badge} />
      )}
      {children}
    </div>
  );
}

function RoleCardContent({
  jobId,
  badge,
}: React.PropsWithChildren<{
  jobId: number;
  badge: React.ReactNode;
}>) {
  const { data: job } = useSuspenseQuery(JOBS_QUERY_OPTIONS.detail(jobId.toString()));

  if (!job) return <RoleCardContentPlaceholder badge={badge} />;

  const { id, title, partner, aggregates } = job;

  return (
    <div className="flex gap-x-2 p-2">
      <div className="shrink-0">
        <CompanyAvatar alt={title} size="lg" fallback={title.split(" ")} logo={partner.logo?.location ?? null} />
      </div>
      <div className="flex min-w-0 flex-1 flex-col gap-y-0.5">
        <span className="flex items-center justify-between gap-x-1">
          <span className={heading({ variant: "16", className: "truncate" })}>{title}</span>
          {badge}
        </span>
        <span className="flex flex-col gap-y-1">
          <span className="flex items-center gap-1">
            {aggregates?.highPriority === 1 ? (
              <img alt="" src="/images/flame.svg" className="h-3.5 w-3.5 shrink-0" />
            ) : (
              <Icon className="size-3.5 grid place-items-center" aria-hidden>
                <IconUse id="briefcase-line" />
              </Icon>
            )}

            <span className="text-ds-text-secondary truncate text-sm leading-none">{id}</span>
          </span>
          <span className="flex items-center gap-1">
            <Icon className="size-3.5 grid place-items-center" aria-hidden>
              <IconUse id="building-line" />
            </Icon>
            <span className="text-ds-text-secondary truncate text-sm leading-none">{partner.name}</span>
          </span>
        </span>
      </div>
    </div>
  );
}

function RoleCardContentPlaceholder({
  loading = false,
  badge,
}: React.PropsWithChildren<{
  loading?: boolean;
  badge: React.ReactNode;
}>) {
  const TextComponent = loading ? Skeleton : "span";

  return (
    <div className="flex gap-x-2 p-2">
      <div className="bg-ds-neutral-300 size-10 rounded-full" />
      <div className="flex min-w-0 flex-1 flex-col gap-y-0.5">
        <span className="flex items-center justify-between gap-x-1">
          <TextComponent className={heading({ variant: "16", color: "disabled", className: "truncate" })}>
            Title
          </TextComponent>
          {badge}
        </span>
        <span className="text-ds-text-disabled flex flex-col gap-y-1">
          <span className="flex items-center gap-1">
            <Icon className="size-3.5 grid place-items-center" aria-hidden>
              <IconUse id="briefcase-line" />
            </Icon>
            <TextComponent className="truncate text-sm leading-none">123456</TextComponent>
          </span>
          <span className="flex items-center gap-1">
            <Icon className="size-3.5 grid place-items-center" aria-hidden>
              <IconUse id="building-line" />
            </Icon>
            <TextComponent className="truncate text-xs leading-none">Name</TextComponent>
          </span>
        </span>
      </div>
    </div>
  );
}

function useRoleMergeMutation() {
  const queryClient = useQueryClient();
  const invalidateJobsCache = useInvalidateJobsCache();

  return useMutation({
    mutationKey: [ROLE_MERGE_FORM_ID],
    mutationFn: async ({ targetId, sourceId }: { targetId: number; sourceId: number }) => {
      // No need to return since it's a 204
      await api.post("/jobs/actions/merge", {
        targetId,
        sourceId,
      });
    },
    onSuccess: () => {
      invalidateJobsCache();
      queryClient.invalidateQueries({ queryKey: [JOBS_KEYS.all] });
    },
  });
}

const RoleSelectLayout = React.forwardRef<
  HTMLButtonElement,
  Omit<React.ComponentPropsWithoutRef<"button">, "onSelect"> & {
    onSelect: (item: Role | null) => void;
    selection: Role | null;
  }
>(({ onSelect, selection, children, ...rest }, forwardedRef) => {
  const [open, setOpen] = React.useState(false);
  const isAboveTablet = useIsAboveTablet();

  return isAboveTablet ? (
    <Popover open={open} onOpenChange={setOpen}>
      <MergeSelectTrigger isAboveTablet={isAboveTablet} ref={forwardedRef} {...rest}>
        {children}
      </MergeSelectTrigger>
      <PopoverContent
        align="start"
        side="bottom"
        sideOffset={4}
        className="w-[var(--radix-popover-trigger-width)] overflow-clip"
      >
        <RoleSelectionFlow
          role={selection}
          onRoleSelection={(role) => {
            onSelect(role);
            setOpen(false);
          }}
        />
      </PopoverContent>
    </Popover>
  ) : (
    <DrawerNestedRoot open={open} onOpenChange={setOpen}>
      <MergeSelectTrigger isAboveTablet={isAboveTablet} ref={forwardedRef} {...rest}>
        {children}
      </MergeSelectTrigger>
      <DrawerContent className="h-auto max-h-[80dvh] overflow-clip">
        <DrawerHeader className="sr-only">
          <DrawerTitle className="sr-only">Select a role</DrawerTitle>
          <DrawerDescription className="sr-only">Select a role to merge profiles with</DrawerDescription>
        </DrawerHeader>
        <div className="max-h-full overflow-y-auto">
          <RoleSelectionFlow
            role={selection}
            onRoleSelection={(role) => {
              onSelect(role);
              setOpen(false);
            }}
          />
        </div>
      </DrawerContent>
    </DrawerNestedRoot>
  );
});

function RoleSelectionFlow({
  role,
  onRoleSelection,
}: {
  role: Role | null;
  onRoleSelection: (role: Role | null) => void;
}) {
  const [partnerSelection, setPartnerSelection] = React.useState<Role["partner"] | null>(role?.partner ?? null);

  return partnerSelection == null ? (
    <PartnerSelection partner={partnerSelection} onPartnerSelection={setPartnerSelection} />
  ) : (
    <RoleSelection
      partnerId={partnerSelection.id}
      role={role}
      onResetPartner={() => {
        setPartnerSelection(null);
      }}
      onRoleSelection={onRoleSelection}
    />
  );
}

function PartnerSelection({
  partner,
  onPartnerSelection,
}: {
  partner: Role["partner"] | null;
  onPartnerSelection: (partner: Role["partner"] | null) => void;
}) {
  const isAboveTablet = useIsAboveTablet();

  return (
    <ErrorBoundary
      fallback={
        <div className="grid h-[300px] place-items-center">
          <span className="text-ds-text-tertiary text-center text-sm">There was an issue loading partners.</span>
        </div>
      }
    >
      <CommandListLoadingFallback className="max-h-[200px]">
        <CommandListNew
          className={!isAboveTablet ? "max-h-full" : "max-h-[200px]"}
          label="Partners"
          onItemSelect={(item) => {
            onPartnerSelection(item);
          }}
          selection={partner ?? null}
          listItemRender={(item) => {
            return (
              <span className="relative flex flex-1 items-center">
                <span className="text-ds-text-secondary truncate text-sm leading-tight">{item.name}</span>
                <Icon
                  aria-hidden
                  className="size-4 absolute right-0 translate-x-full opacity-0 group-aria-selected/item:opacity-100"
                >
                  <IconUse id="arrow-right-s-line" />
                </Icon>
              </span>
            );
          }}
          infiniteQueryOptions={PARTNER_QUERY_OPTIONS.infinitePartnersList}
        />
      </CommandListLoadingFallback>
    </ErrorBoundary>
  );
}

function RoleSelection({
  role,
  partnerId,
  onResetPartner,
  onRoleSelection,
}: {
  role: Role | null;
  partnerId: number;
  onResetPartner: () => void;
  onRoleSelection: (role: Role | null) => void;
}) {
  const isAboveTablet = useIsAboveTablet();
  const jobsListQueryOptions = React.useMemo(() => {
    return getJobsListQueryOptions(partnerId);
  }, [partnerId]);

  return (
    <ErrorBoundary
      fallback={
        <div className="grid h-[300px] place-items-center">
          <span className="text-ds-text-tertiary text-center text-sm">There was an issue loading roles.</span>
        </div>
      }
    >
      <CommandListLoadingFallback className="max-h-[200px]">
        <div className="border-ds-stroke-tertiary flex items-baseline justify-between border-b pb-0.5 pl-4 pt-1.5">
          <span className={copy({ variant: "14", weight: "medium", className: "text-ds-text-primary" })}>
            Select a role
          </span>
          <Button
            variant="link"
            size="sm"
            onClick={() => {
              onResetPartner();
            }}
          >
            Change partner
          </Button>
        </div>

        <CommandListNew
          className={!isAboveTablet ? "max-h-full" : "max-h-[200px]"}
          label="Roles"
          onItemSelect={(item) => {
            onRoleSelection(item);
          }}
          selection={role}
          listItemRender={(item) => {
            const isHighPriority = item.aggregates?.highPriority === 1;

            return (
              <span className="flex w-full items-center gap-x-2 truncate">
                {isHighPriority && <img alt="" src="/images/flame.svg" className="h-3.5 w-3.5 shrink-0" />}
                <span className="text-ds-text-secondary min-w-0 flex-1 truncate text-sm leading-tight">
                  {item.name}
                </span>
                <span className="text-ds-text-tertiary shrink-0 text-sm leading-tight">{item.id}</span>
              </span>
            );
          }}
          infiniteQueryOptions={jobsListQueryOptions}
        />
      </CommandListLoadingFallback>
    </ErrorBoundary>
  );
}

function getJobsListQueryOptions(partnerId: number) {
  return (params: { [key: string]: unknown }) => {
    return {
      ...JOBS_QUERY_OPTIONS.infiniteJobsList({
        ...DEFAULT_ROLES_PARAMS,
        ...params,
        partner: partnerId,
      }),
      select: <TData extends { id: number; title: string }>(data: InfiniteData<{ items: Array<TData> }>) => {
        return createInfiniteDataTransform(data, (item) => {
          return {
            ...item,
            name: item.title,
          };
        });
      },
    };
  };
}

const DEFAULT_ROLES_PARAMS = {
  limit: 20,
  term: "",
  orderBy: "highPotential",
  orderDir: "DESC",
} as const;

function useIsAboveTablet() {
  return useMediaQuery("(min-width: 768px)");
}
