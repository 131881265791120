import * as React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useParams } from "react-router-dom";
import { Button, ButtonIcon } from "~/shared/components/ds/Button";
import { IconUse } from "~/shared/components/ds/icons/Icon";
import { FormErrorState, FormLoadingState } from "~/team/components/FormStates";
import { RoleMergeForm } from "~/team/views/JobView/components/RoleMergeAction/RoleMergeForm";
import { RoleMergeSheetLayout } from "~/team/views/JobView/components/RoleMergeAction/RoleMergeSheetLayout";
import { ROLE_MERGE_FORM_ID } from "~/team/views/JobView/constants/form.constants";

export function RoleMergeAction() {
  const { jobId } = useParams();

  if (!jobId) return null;

  return (
    <RoleMergeSheetLayout
      title="Merge Role"
      description="Select the role you want to merge with this one."
      trigger={
        <Button
          variant="destructive"
          prefix={
            <ButtonIcon>
              <IconUse id="git-merge-line" />
            </ButtonIcon>
          }
        >
          Merge Tool
        </Button>
      }
      formId={ROLE_MERGE_FORM_ID}
    >
      {(setIsOpen) => {
        return (
          <ErrorBoundary fallback={<FormErrorState />}>
            <React.Suspense fallback={<FormLoadingState />}>
              <RoleMergeForm jobId={jobId} formId={ROLE_MERGE_FORM_ID} setIsOpen={setIsOpen} />
            </React.Suspense>
          </ErrorBoundary>
        );
      }}
    </RoleMergeSheetLayout>
  );
}
