import axios from "axios";
import { getImpersonationState } from "~/shared/components/PrivateAppLayout/components/Impersonation";
import { handleApiError } from "~/shared/lib/api-error";
import { authenticationTokenRefresh } from "~/shared/lib/cognito/helpers/authentication-token-refresh";
import config from "../../config";

export const baseURL = config.api;

const api = axios.create({
  baseURL,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
  },
});

// This interceptor intercepts API responses and refreshes the user's access token if necessary
api.interceptors.request.use(async (request) => {
  try {
    const token = await authenticationTokenRefresh();
    request.headers.Authorization = `Bearer ${token}`;

    const impersonationState = getImpersonationState();

    if (impersonationState != null) {
      request.headers["X-BL-Impersonate"] = impersonationState.username;
    }

    return request;
  } catch (err) {
    return request;
  }
});

api.interceptors.response.use((response) => response, handleApiError);

// Refresh token if expired
// This function checks if the user's ID token has expired
export function isAccessTokenExpired(expiration?: number): boolean {
  if (expiration === undefined) return true;

  const currentTimeSeconds = Math.round(new Date().getTime() / 1000);
  return expiration < currentTimeSeconds;
}

export default api;
