import * as React from "react";
import { twMerge } from "tailwind-merge";
import { Spinner } from "~/shared/components/ds/Spinner";

interface ListLoadMoreProps extends React.ComponentPropsWithoutRef<"div"> {
  loading?: boolean;
}
export const ListLoadMore = React.forwardRef<HTMLDivElement, ListLoadMoreProps>(
  ({ className, loading, ...rest }, forwardedRef) => {
    return (
      <div
        className={twMerge(
          "mt-2 grid h-10 place-items-center border-x-0 border-b-0 border-t border-solid border-gray-200",
          className,
        )}
        ref={forwardedRef}
        {...rest}
      >
        {loading ? <Spinner size="xs" /> : null}
      </div>
    );
  },
);
