import { infiniteQueryOptions } from "@tanstack/react-query";
import api from "~/shared/api/api";
import { Params } from "~/shared/api/types/params";
import { PaginatedResponse } from "~/shared/types/pagination.types";
import { addFullNameToDataItems } from "~/shared/util/addFullNameToDataItems";
import { createInfiniteQuery } from "~/shared/util/createInfiniteQuery";

const STAFF_KEYS = {
  all: ["staff"] as const,
  filter: (params: Params) => [...STAFF_KEYS.all, "filter", params] as const,
  list: (params: Params) => [...STAFF_KEYS.all, "list", params] as const,
};

export function useInfiniteStaffFilter(params: { [key: string]: unknown }) {
  return createInfiniteQuery({
    fetchFn: async (params, { signal }) => {
      const response = await api.get(`/staff`, {
        ...params,
        signal,
      });

      return response.data;
    },
    select: (data) => {
      return addFullNameToDataItems(data);
    },
    queryKeyFn: (params) => STAFF_KEYS.filter(params),
  })({
    params,
  });
}

export const STAFF_QUERY_OPTIONS = {
  infiniteStaffList: (params: { [key: string]: unknown }) => {
    const { limit = 20 } = params ?? {};

    return infiniteQueryOptions({
      queryFn: async ({ pageParam = 1, signal }) => {
        const response = await api.get<
          PaginatedResponse<{
            id: number;
            firstName: string;
            lastName: string;
            email: string;
            headshot?: {
              location: string;
            };
          }>
        >(`/staff`, {
          params: {
            ...params,
            limit,
            skip: (pageParam - 1) * (limit as number),
          },
          signal,
        });

        return response.data;
      },
      queryKey: STAFF_KEYS.list({
        params: {
          ...params,
          limit,
        },
      }),
      initialPageParam: 1,
      getNextPageParam: (lastPage, _, lastPageParam) => {
        if (lastPage.items.length < (limit as number)) {
          return undefined;
        }

        return lastPageParam + 1;
      },
      select: addFullNameToDataItems,
    });
  },
};
