import * as React from "react";
import { twMerge } from "tailwind-merge";
import { focusRingStyles } from "~/shared/styles/focus";

export const Textarea = React.forwardRef<HTMLTextAreaElement, React.ComponentPropsWithoutRef<"textarea">>(
  ({ className, ...rest }, ref) => {
    return (
      <textarea
        className={focusRingStyles({
          variant: "input",
          className: twMerge(
            "border-ds-stroke-tertiary bg-ds-field-1 text-ds-text-primary placeholder:text-ds-text-placeholder read-only:border-ds-stroke-tertiary read-only:bg-ds-neutral-0 read-only:text-ds-text-tertiary disabled:bg-ds-field-1 aria-[invalid=true]:border-ds-state-error aria-[invalid=true]:ring-ds-red-light flex min-h-[60px] w-full rounded-md border px-3 py-2 text-sm transition-all read-only:shadow-none disabled:cursor-not-allowed disabled:opacity-50 aria-[invalid=true]:ring-2",
            className,
          ),
        })}
        ref={ref}
        {...rest}
      />
    );
  },
);
Textarea.displayName = "Textarea";
