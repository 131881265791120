import { useSuspenseQuery } from "@tanstack/react-query";
import { UTAH_PROGRAM_ID } from "~/participant/constants/utah.constants";
import { USER_OPTIONS } from "~/shared/api/user/user";

export function useIsUtahParticipant() {
  const { data: user } = useSuspenseQuery(USER_OPTIONS.user());
  const isParticipant = user?.type === "participant";
  const isUtahParticipant = user?.profile?.programs?.some((program) => program.id === UTAH_PROGRAM_ID) ?? false;

  return isParticipant && isUtahParticipant;
}
