import styled from "styled-components";
import screen from "superior-mq";
import { bp } from "~/shared/styles/helpers";

const LoginForm = {};

LoginForm.Main = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;

  ${screen.below(
    bp.mobileMid,
    `
    min-height: fill-available;
    min-height: -webkit-fill-available;
  `,
  )}
`;

LoginForm.Content = styled.div`
  position: relative;
  padding: 60px 20px;
  max-width: 520px;
  margin: auto;

  h1 {
    color: var(--dark-text);
  }

  ${screen.below(
    bp.mobileSm,
    `
      padding: 40px 20px;
    `,
  )}
`;

LoginForm.Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: 490px;
  margin: 0 auto;

  ${screen.below(
    bp.portrait,
    `
    max-width: 600px;
  `,
  )}
`;

export default LoginForm;
