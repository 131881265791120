import { Slot, Slottable } from "@radix-ui/react-slot";
import * as React from "react";
import { tv, VariantProps } from "tailwind-variants";
import { focusRingStyles } from "~/shared/styles/focus";

interface BadgeProps extends React.ComponentPropsWithoutRef<"span">, BadgeVariants {
  icon?: React.ReactElement;
  asChild?: boolean;
}
export const Badge = React.forwardRef<HTMLElement, BadgeProps>(
  ({ interactive, colorScheme, variant, shape, size, icon, children, className, asChild, ...rest }, ref) => {
    const Component = asChild ? Slot : "span";
    const iconElement = icon
      ? React.cloneElement(icon, {
          className: badgeIconVariants({ size }),
        })
      : null;
    const elementVars = COLOR_SCHEME_VARS[colorScheme ?? "neutral"][variant ?? "subtle"];

    return (
      <Component
        ref={ref}
        {...rest}
        className={badgeVariants({
          interactive,
          colorScheme,
          variant,
          shape,
          size,
          className,
        })}
        style={
          {
            "--bg-color": elementVars.bg,
            "--text-color": elementVars.text,
          } as React.CSSProperties
        }
      >
        {iconElement}
        <Slottable>{children}</Slottable>
      </Component>
    );
  },
);
Badge.displayName = "Badge";

const badgeVariants = tv({
  base: "no-underline flex shrink-0 items-center tabular-nums font-medium transition-colors bg-[rgb(var(--bg-color))] text-[rgb(var(--text-color))] [--hover-opacity:0.8] [--hover-bg-color:rgba(var(--bg-color)_/_var(--hover-opacity))]",
  variants: {
    interactive: {
      true: focusRingStyles({
        className:
          "hover:bg-[var(--hover-bg-color)] focus-visible:bg-[var(--hover-bg-color)] hover:text-[rgb(var(--text-color))]",
      }),
    },
    colorScheme: {
      neutral: "",
      dark: "",
      teal: "",
      orange: "",
      blue: "",
      green: "",
      yellow: "",
      red: "",
      purple: "",
      pink: "",
      primary: "",
      secondary: "",
      positive: "",
      negative: "",
      warning: "",
      info: "",
    },
    variant: {
      subtle: "",
      solid: "",
    },
    shape: {
      rectangle: "rounded-md",
      pill: "rounded-full",
    },
    size: {
      sm: "gap-0.5 text-[0.6875rem] px-2 h-5",
      normal: "gap-1 text-xs px-2.5 h-6",
      lg: "gap-1.5 text-sm px-3 h-8",
    },
  },
  defaultVariants: {
    colorScheme: "neutral",
    variant: "subtle",
    shape: "rectangle",
    size: "normal",
  },
});
export type BadgeVariants = VariantProps<typeof badgeVariants>;

export const badgeIconVariants = tv({
  base: "color-current grid place-items-center",
  variants: {
    size: {
      sm: "w-[0.6875rem] h-[0.6875rem]",
      normal: "w-[0.875rem] h-[0.875rem]",
      lg: "w-4 h-4",
    },
  },
  defaultVariants: {
    size: "normal",
  },
});

export const COLOR_SCHEME_VARS = {
  neutral: {
    subtle: {
      bg: "var(--neutral-200)",
      text: "var(--neutral-900)",
    },
    solid: {
      bg: "var(--neutral-400)",
      text: "var(--neutral-100)",
    },
  },
  dark: {
    subtle: {
      bg: "var(--neutral-300)",
      text: "var(--neutral-900)",
    },
    solid: {
      bg: "var(--neutral-800)",
      text: "var(--neutral-100)",
    },
  },
  teal: {
    subtle: {
      bg: "var(--teal-lighter)",
      text: "var(--teal-darker)",
    },
    solid: {
      bg: "var(--teal-base)",
      text: "var(--neutral-100)",
    },
  },
  orange: {
    subtle: {
      bg: "var(--orange-lighter)",
      text: "var(--orange-darker)",
    },
    solid: {
      bg: "var(--orange-base)",
      text: "var(--neutral-100)",
    },
  },
  blue: {
    subtle: {
      bg: "var(--blue-lighter)",
      text: "var(--blue-darker)",
    },
    solid: {
      bg: "var(--blue-base)",
      text: "var(--neutral-100)",
    },
  },
  green: {
    subtle: {
      bg: "var(--green-lighter)",
      text: "var(--green-darker)",
    },
    solid: {
      bg: "var(--green-base)",
      text: "var(--neutral-100)",
    },
  },
  yellow: {
    subtle: {
      bg: "var(--yellow-lighter)",
      text: "var(--yellow-darker)",
    },
    solid: {
      bg: "var(--yellow-base)",
      text: "var(--neutral-900)",
    },
  },
  red: {
    subtle: {
      bg: "var(--red-lighter)",
      text: "var(--red-darker)",
    },
    solid: {
      bg: "var(--red-base)",
      text: "var(--neutral-100)",
    },
  },
  purple: {
    subtle: {
      bg: "var(--purple-lighter)",
      text: "var(--purple-darker)",
    },
    solid: {
      bg: "var(--purple-base)",
      text: "var(--neutral-100)",
    },
  },
  pink: {
    subtle: {
      bg: "var(--pink-lighter)",
      text: "var(--pink-darker)",
    },
    solid: {
      bg: "var(--pink-base)",
      text: "var(--neutral-100)",
    },
  },
  // Semantic colors
  primary: {
    subtle: {
      bg: "var(--primary-lighter)",
      text: "var(--primary-darker)",
    },
    solid: {
      bg: "var(--primary-base)",
      text: "var(--neutral-100)",
    },
  },
  secondary: {
    subtle: {
      bg: "var(--secondary-lighter)",
      text: "var(--secondary-darker)",
    },
    solid: {
      bg: "var(--secondary-base)",
      text: "var(--neutral-100)",
    },
  },
  positive: {
    subtle: {
      bg: "var(--green-lighter)",
      text: "var(--green-darker)",
    },
    solid: {
      bg: "var(--green-base)",
      text: "var(--neutral-100)",
    },
  },
  negative: {
    subtle: {
      bg: "var(--red-lighter)",
      text: "var(--red-darker)",
    },
    solid: {
      bg: "var(--red-base)",
      text: "var(--neutral-100)",
    },
  },
  warning: {
    subtle: {
      bg: "var(--yellow-lightest)",
      text: "var(--yellow-darker)",
    },
    solid: {
      bg: "var(--yellow-base)",
      text: "var(--neutral-900)",
    },
  },
  info: {
    subtle: {
      bg: "var(--blue-lighter)",
      text: "var(--blue-darker)",
    },
    solid: {
      bg: "var(--blue-base)",
      text: "var(--neutral-100)",
    },
  },
} as const;
