import { USER_ACCESS, USER_TYPES } from "~/shared/api/user/user.constants";
import { TEAM_EXPERIENCE_PREFIX } from "~/team/constants/paths.constants";

export function isBreakLineUser(type: string) {
  return type === USER_TYPES.breakline;
}

export function isParticipantUser(type: string) {
  return type === USER_TYPES.participant;
}

export function isAdminUser(roles?: string[]) {
  return roles?.includes(USER_ACCESS.admin) ?? false;
}

export function getRouteTreeRoot(type?: keyof typeof USER_TYPES) {
  switch (type) {
    case "breakline": {
      return `/${TEAM_EXPERIENCE_PREFIX}` as const;
    }
    case "participant":
    default: {
      return "/" as const;
    }
  }
}
