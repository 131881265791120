import * as React from "react";
import { SetURLSearchParams } from "react-router-dom";
import { z } from "zod";
import apiService from "~/shared/api/api-service";
import { parseSearchParams, stringify } from "~/shared/util/searchParams.helpers";

export function useTableSearchParamsCallbacks({
  key,
  setSearchParams,
}: {
  key: string;
  setSearchParams: SetURLSearchParams;
}) {
  const handlePageChange = React.useCallback(
    (nextPage: number) => {
      setSearchParams(
        (searchParams) => {
          const previousParams = parseSearchParams(searchParams, key);
          searchParams.set(key, stringify({ ...previousParams, page: Number(nextPage) }));
          return searchParams;
        },
        { preventScrollReset: true },
      );
    },
    [key, setSearchParams],
  );

  const handlePerRowsChange = React.useCallback(
    (newPerPage: number) => {
      // TODO: This is event is causing an infinite loop but only when you're in a simulated touch mode in dev tools. Need to investigate in the future but my guess is that the issue will resolve itself when we refactor this component to use a different pagination library.
      setSearchParams(
        (searchParams) => {
          const previousParams = parseSearchParams(searchParams, key);
          searchParams.set(
            key,
            stringify({
              ...previousParams,
              limit: Number(newPerPage),
              page: 1,
            }),
          );

          return searchParams;
        },
        { preventScrollReset: true },
      );
    },
    [key, setSearchParams],
  );

  const handleSortChange = React.useCallback(
    (column: { selector?: string }, sortDirection: string) => {
      const { selector } = column;
      if (!selector) return;

      setSearchParams(
        (searchParams) => {
          const previousParams = parseSearchParams(searchParams, key);
          searchParams.set(
            key,
            stringify({
              ...previousParams,
              sort: { col: selector, dir: sortDirection },
              page: 1,
            }),
          );

          return searchParams;
        },
        { preventScrollReset: true },
      );
    },
    [key, setSearchParams],
  );

  return {
    handlePageChange,
    handlePerRowsChange,
    handleSortChange,
  };
}

// TODO: Hunter - should remove this abstraction
export function useDefaultTableSearchParams({
  key,
  filters,
  searchParams,
}: {
  key: string;
  filters: { [x: string]: any };
  searchParams: URLSearchParams;
}) {
  return React.useMemo(() => {
    const parsedSearchParams = parseTableSearchParams(searchParams, key);
    const { page, limit, sort } = parsedSearchParams;
    const queryParams = apiService.getTableParams(page, limit, sort, {
      ...filters,
    });

    return {
      queryParams,
      parsedSearchParams,
    };
  }, [searchParams, key, filters]);
}

export const DEFAULT_TABLE_PARAMS = {
  page: 1,
  limit: 10,
  sort: { col: "id", dir: "desc" },
};

export function getTableParamsFromRequest({
  request,
  key,
  filters,
  fallbackData = DEFAULT_TABLE_PARAMS,
}: {
  request: Request;
  key: string;
  filters?: { [key: string]: any };
  fallbackData?: Partial<typeof DEFAULT_TABLE_PARAMS>;
}) {
  const url = new URL(request.url);
  const { page: defaultPage, limit: defaultLimit, sort: defaultSort } = fallbackData;

  const tableSearchParams = parseSearchParams(url.searchParams, key);

  const { page, limit, sort } = tableSearchParams ?? fallbackData;

  const queryParams = apiService.getTableParams(
    page ?? defaultPage,
    limit ?? defaultLimit,
    sort ?? defaultSort,
    filters,
  );

  return queryParams;
}

export function parseTableSearchParams(
  searchParams: URLSearchParams,
  key: string,
  fallbackParams: Partial<typeof DEFAULT_TABLE_PARAMS> = DEFAULT_TABLE_PARAMS,
) {
  const parsed = parseSearchParams(searchParams, key);
  const schema = createTableSearchParamsSchema(fallbackParams);

  // Attempt to parse the values, but if it fails, return the default params
  try {
    return schema.parse(parsed);
  } catch (_e) {
    return {
      ...DEFAULT_TABLE_PARAMS,
      ...fallbackParams,
      sort: {
        ...DEFAULT_TABLE_PARAMS.sort,
        ...fallbackParams.sort,
      },
    };
  }
}

const TableSearchParamsSchema = z.object({
  page: z.coerce.number().int().positive().default(DEFAULT_TABLE_PARAMS.page).catch(DEFAULT_TABLE_PARAMS.page),
  limit: z.coerce.number().int().positive().default(DEFAULT_TABLE_PARAMS.limit).catch(DEFAULT_TABLE_PARAMS.limit),
  sort: z
    .object({
      col: z.string().default(DEFAULT_TABLE_PARAMS.sort.col).catch(DEFAULT_TABLE_PARAMS.sort.col),
      dir: z.string().default(DEFAULT_TABLE_PARAMS.sort.dir).catch(DEFAULT_TABLE_PARAMS.sort.dir),
    })
    .default(DEFAULT_TABLE_PARAMS.sort)
    .catch(DEFAULT_TABLE_PARAMS.sort),
});

function createTableSearchParamsSchema(fallback: Partial<typeof DEFAULT_TABLE_PARAMS> = {}) {
  const FALLBACK_DEFAULTS = {
    ...DEFAULT_TABLE_PARAMS,
    ...fallback,
    sort: {
      ...DEFAULT_TABLE_PARAMS.sort,
      ...fallback.sort,
    },
  } satisfies z.infer<typeof TableSearchParamsSchema>;

  return z.object({
    page: z.coerce.number().int().positive().default(FALLBACK_DEFAULTS.page).catch(FALLBACK_DEFAULTS.page),
    limit: z.coerce.number().int().positive().default(FALLBACK_DEFAULTS.limit).catch(FALLBACK_DEFAULTS.limit),
    sort: z
      .object({
        col: z.string().default(FALLBACK_DEFAULTS.sort.col).catch(FALLBACK_DEFAULTS.sort.col),
        dir: z.string().default(FALLBACK_DEFAULTS.sort.dir).catch(FALLBACK_DEFAULTS.sort.dir),
      })
      .default(FALLBACK_DEFAULTS.sort)
      .catch(FALLBACK_DEFAULTS.sort),
  });
}
