import { useIsMutating } from "@tanstack/react-query";
import * as React from "react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "~/shared/components/ds/AlertDialog";
import { Badge } from "~/shared/components/ds/Badge";
import { Button, ButtonIcon } from "~/shared/components/ds/Button";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerNestedRoot,
  DrawerTitle,
  DrawerTrigger,
} from "~/shared/components/ds/Drawer";
import { Icon, IconUse } from "~/shared/components/ds/icons/Icon";
import {
  LayoutSheet,
  Sheet,
  SheetClose,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "~/shared/components/ds/Sheet";
import useMediaQuery from "~/shared/hooks/useMediaQuery";
import { ROLE_MERGE_FORM_ID } from "~/team/views/JobView/constants/form.constants";

export function RoleMergeSheetLayout({
  title,
  description,
  trigger,
  formId,
  children,
}: {
  title: string;
  description: string;
  trigger: React.ReactNode;
  formId: string;
  children: (setIsOpen: React.Dispatch<React.SetStateAction<boolean>>) => React.ReactNode;
}) {
  const roleMergeMutationCount = useIsMutating({ mutationKey: [ROLE_MERGE_FORM_ID] });
  const isRoleMergeInProgress = roleMergeMutationCount > 0;

  const [isOpen, setIsOpen] = React.useState(false);
  const isAboveTablet = useMediaQuery("(min-width: 768px)");

  function handleOpenChange(open: boolean) {
    if (isRoleMergeInProgress && !open) {
      return;
    }
    setIsOpen(open);
  }

  const cancelButton = (
    <Button disabled={isRoleMergeInProgress} variant="outline">
      Cancel
    </Button>
  );
  const mergeButton = (
    <Button
      disabled={isRoleMergeInProgress}
      isLoading={isRoleMergeInProgress}
      variant="default"
      prefix={
        <ButtonIcon>
          <IconUse id="git-merge-line" />
        </ButtonIcon>
      }
    >
      Merge
    </Button>
  );

  const confirmTitle = "Are you sure?";
  const confirmDescription =
    "Merging roles will merge the source roles's data into the target role's profile. This action cannot be undone.";
  const confirmMergeButton = (
    <Button
      variant="destructive"
      type="submit"
      form={formId}
      prefix={
        <ButtonIcon>
          <IconUse id="git-merge-line" />
        </ButtonIcon>
      }
    >
      Confirm Merge
    </Button>
  );

  return isAboveTablet ? (
    <Sheet open={isOpen} onOpenChange={handleOpenChange}>
      <SheetTrigger asChild>{trigger}</SheetTrigger>
      <LayoutSheet>
        <SheetHeader className="flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <SheetTitle>{title}</SheetTitle>
            <SheetDescription>{description}</SheetDescription>
          </div>
          <WarningBadge />
        </SheetHeader>

        <div className="relative z-10 flex max-h-full flex-1 flex-col overflow-y-auto">
          {children(setIsOpen)}
          <div className="bg-ds-bg-weaker sticky bottom-0 p-6">
            <div className="flex justify-end gap-4">
              <SheetClose asChild>{cancelButton}</SheetClose>
              <AlertDialog>
                <AlertDialogTrigger asChild>{mergeButton}</AlertDialogTrigger>
                <AlertDialogContent>
                  <AlertDialogHeader>
                    <AlertDialogTitle>{confirmTitle}</AlertDialogTitle>
                    <AlertDialogDescription>{confirmDescription}</AlertDialogDescription>
                  </AlertDialogHeader>
                  <AlertDialogFooter>
                    <AlertDialogAction asChild>{confirmMergeButton}</AlertDialogAction>
                    <AlertDialogCancel>Cancel</AlertDialogCancel>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialog>
            </div>
          </div>
        </div>
      </LayoutSheet>
    </Sheet>
  ) : (
    <Drawer open={isOpen} onOpenChange={handleOpenChange}>
      <DrawerTrigger asChild>{trigger}</DrawerTrigger>
      <DrawerContent className="h-auto max-h-[90dvh]">
        <div className="overflow-y-auto">
          <DrawerHeader className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
              <DrawerTitle>{title}</DrawerTitle>
              <DrawerDescription>{description}</DrawerDescription>
            </div>
            <WarningBadge />
          </DrawerHeader>
          {children(setIsOpen)}
        </div>
        <DrawerFooter className="bg-ds-bg-weaker">
          <DrawerClose asChild>{cancelButton}</DrawerClose>
          <DrawerNestedRoot>
            <DrawerTrigger asChild>{mergeButton}</DrawerTrigger>
            <DrawerContent>
              <DrawerHeader>
                <DrawerTitle>{confirmTitle}</DrawerTitle>
                <DrawerDescription className="text-pretty">{confirmDescription}</DrawerDescription>
              </DrawerHeader>
              <DrawerFooter>
                <DrawerClose asChild>
                  <Button variant="outline">Cancel</Button>
                </DrawerClose>
                <DrawerClose asChild>{confirmMergeButton}</DrawerClose>
              </DrawerFooter>
            </DrawerContent>
          </DrawerNestedRoot>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}

function WarningBadge() {
  return (
    <Badge
      colorScheme="warning"
      variant="subtle"
      shape="rectangle"
      icon={
        <Icon>
          <IconUse id="alert-line" />
        </Icon>
      }
      className="mx-auto w-fit sm:m-0"
    >
      This cannot be undone.
    </Badge>
  );
}
