import * as CommandPrimitive from "cmdk";
import * as React from "react";
import { twMerge } from "tailwind-merge";
import { Badge, badgeIconVariants, BadgeVariants } from "~/shared/components/ds/Badge";
import { PopoverContent, PopoverTrigger } from "~/shared/components/ds/Popover";
import { Icon, IconUse } from "~/shared/components/ds/icons/Icon";
import { IconLoader } from "~/shared/components/icons/IconLoader/IconLoader";
import { focusRingStyles } from "~/shared/styles/focus";

export const ComboboxTrigger = React.forwardRef<
  React.ElementRef<typeof PopoverTrigger>,
  React.ComponentPropsWithoutRef<typeof PopoverTrigger>
>(({ className, children, ...rest }, ref) => {
  return (
    <>
      <PopoverTrigger
        role="combobox"
        ref={ref}
        className={twMerge(
          focusRingStyles({ variant: "input" }),
          "border-ds-stroke-tertiary bg-ds-field-1 text-ds-text-primary aria-[invalid=true]:border-ds-state-error aria-[placeholder]:text-ds-text-placeholder aria-[invalid=true]:ring-ds-red-light disabled:aria-[readonly=true]:border-ds-stroke-tertiary disabled:aria-[readonly=true]:bg-ds-bg-foundation disabled:aria-[readonly=true]:text-ds-text-tertiary group flex h-10 w-full items-center justify-between whitespace-nowrap rounded-md border px-3 py-1 text-left text-sm disabled:cursor-not-allowed disabled:opacity-50 aria-[invalid=true]:ring-2 disabled:aria-[readonly=true]:opacity-100",
          className,
        )}
        {...rest}
      >
        <span className="line-clamp-1 inline-block min-h-0 min-w-0 flex-1 justify-start truncate">{children}</span>
        <Icon aria-hidden className="group-disabled:group-aria-[readonly=true]:text-ds-icon-tertiary h-4 w-4 shrink-0">
          <IconUse id="expand-up-down-line" />
        </Icon>
      </PopoverTrigger>
    </>
  );
});
ComboboxTrigger.displayName = "ComboboxTrigger";

export function ComboboxContent({
  children,
  className,
  shouldFilter = true,
}: {
  shouldFilter?: boolean;
  className?: string;
  children?: React.ReactNode;
}) {
  return (
    <PopoverContent
      align="start"
      side="bottom"
      sideOffset={8}
      className={twMerge("w-[var(--radix-popover-trigger-width)] overflow-clip shadow-sm", className)}
    >
      <CommandPrimitive.CommandRoot shouldFilter={shouldFilter}>{children}</CommandPrimitive.CommandRoot>
    </PopoverContent>
  );
}

type ComboboxInputProps = React.ComponentPropsWithoutRef<typeof CommandPrimitive.CommandInput> & {
  loading?: boolean;
};
export const ComboboxInput = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.CommandInput>,
  ComboboxInputProps
>(({ loading, className, ...rest }, ref) => {
  return (
    <div className="border-ds-stroke-tertiary bg-ds-bg-foundation sticky top-0 z-10 flex flex-row-reverse items-center border-b">
      <CommandPrimitive.CommandInput
        className={twMerge(
          "font-primary text-ds-text-primary placeholder:text-ds-text-placeholder peer flex h-10 w-full border-none bg-transparent px-3 py-1 text-sm outline-none transition-colors focus:outline-none disabled:cursor-not-allowed",
          className,
        )}
        ref={ref}
        {...rest}
      />
      <span className="text-ds-icon-secondary -mr-3 flex w-auto shrink-0 items-center px-3" aria-hidden>
        {loading ? (
          <IconLoader animate className="h-4 w-4" />
        ) : (
          <Icon className="h-4 w-4">
            <IconUse id="search-line" />
          </Icon>
        )}
      </span>
    </div>
  );
});
ComboboxInput.displayName = "ComboboxInput";

export const ComboboxList = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.CommandList>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.CommandList>
>(({ className, children, ...rest }, ref) => {
  return (
    <CommandPrimitive.CommandList ref={ref} {...rest}>
      <CommandPrimitive.CommandGroup className={twMerge("max-h-[300px] overflow-y-auto p-1", className)}>
        {children}
      </CommandPrimitive.CommandGroup>
    </CommandPrimitive.CommandList>
  );
});
ComboboxList.displayName = "ComboboxList";

interface ComboboxItemProps extends React.ComponentPropsWithoutRef<typeof CommandPrimitive.CommandItem> {
  "data-checked"?: boolean;
}
export const ComboboxItem = React.forwardRef<React.ElementRef<typeof CommandPrimitive.CommandItem>, ComboboxItemProps>(
  ({ className, children, ...rest }, ref) => {
    return (
      <CommandPrimitive.CommandItem
        className={twMerge(
          "text-ds-text-secondary aria-selected:bg-ds-bg-weaker aria-selected:text-ds-text-primary data-[checked=true]:text-ds-text-primary group/item relative flex w-full cursor-default select-none items-center justify-between gap-1 overflow-clip rounded p-2 text-sm outline-none data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
          className,
        )}
        ref={ref}
        {...rest}
      >
        {children}
      </CommandPrimitive.CommandItem>
    );
  },
);
ComboboxItem.displayName = "ComboboxItem";

interface ComboboxCheckProps {
  checked?: boolean;
}
export function ComboboxCheck({ checked }: ComboboxCheckProps) {
  return (
    <Icon className={twMerge("h-4 w-4 shrink-0", checked ? "opacity-100" : "opacity-0")} aria-hidden>
      <IconUse id="checkbox-check" />
    </Icon>
  );
}

export const ComboboxEmpty = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.CommandEmpty>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.CommandEmpty>
>(({ className, ...rest }, ref) => (
  <CommandPrimitive.CommandEmpty
    ref={ref}
    className={twMerge("text-ds-text-primary py-6 text-center text-sm", className)}
    {...rest}
  />
));
ComboboxEmpty.displayName = "ComboboxEmpty";

export const ComboboxSelectionGroup = React.forwardRef<HTMLDivElement, React.ComponentPropsWithoutRef<"div">>(
  ({ className, ...rest }, ref) => {
    return <div ref={ref} className={twMerge("flex flex-wrap gap-2", className)} {...rest} />;
  },
);
ComboboxSelectionGroup.displayName = "ComboboxSelectionGroup";

export const ComboboxSelectionBadge = React.forwardRef<
  HTMLButtonElement,
  React.ComponentPropsWithoutRef<"button"> & Pick<BadgeVariants, "colorScheme" | "shape">
>(({ className, children, colorScheme = "neutral", shape = "pill", ...rest }, ref) => {
  return (
    <Badge interactive colorScheme={colorScheme} shape={shape} className={className} asChild>
      <button ref={ref} {...rest}>
        {children}
        <Icon className={badgeIconVariants()}>
          <IconUse id="close-line" />
        </Icon>
      </button>
    </Badge>
  );
});
ComboboxSelectionBadge.displayName = "ComboboxSelectionBadge";
