import { useSuspenseInfiniteQuery } from "@tanstack/react-query";
import * as CommandPrimitive from "cmdk";
import * as fuzzy from "fuzzy";
import * as React from "react";
import { useInView } from "react-intersection-observer";
import { ComboboxCheck, ComboboxInput, ComboboxItem, ComboboxList } from "~/shared/components/ds/Combobox";
import { ListLoadMore } from "~/shared/components/ListLoadMore";
import { useDebounce } from "~/shared/hooks/useDebounce";

export function CommandListNew<TListItem extends { id: number; name: string }>({
  label,
  infiniteQueryOptions,
  selection,
  className,
  onItemSelect,
  listItemRender,
}: {
  label: string;
  infiniteQueryOptions: (params: { [key: string]: unknown }) => any;
  selection: TListItem | null;
  className?: string;
  onItemSelect: (item: TListItem | null) => void;
  listItemRender?: (item: TListItem) => React.ReactNode;
}) {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [search, setSearch] = React.useState("");
  const debouncedSearchTerm = useDebounce(search, 500);
  const params = React.useMemo(() => {
    return debouncedSearchTerm ? { term: debouncedSearchTerm } : {};
  }, [debouncedSearchTerm]);
  const deferredParams = React.useDeferredValue(params);
  const { data, hasNextPage, fetchNextPage, isFetchingNextPage } = useSuspenseInfiniteQuery(
    infiniteQueryOptions(deferredParams),
  );
  const isDeferred = params !== deferredParams;

  const filteredAndSortedData = React.useMemo(() => {
    const flattenedData = data.pages.flatMap((page: any) => {
      return page.items;
    });
    if (!selection || !fuzzy.match(debouncedSearchTerm, selection.name)) {
      return flattenedData;
    }

    // Sort the data so that the selected item is always at the top
    return [selection, ...flattenedData.filter((val) => val.id !== selection.id)];
  }, [data, selection, debouncedSearchTerm]);

  const { ref: loadMoreRef, inView: loadMoreInView } = useInView();
  React.useEffect(() => {
    if (loadMoreInView && hasNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, fetchNextPage, loadMoreInView]);

  React.useEffect(() => {
    if (inputRef.current) {
      /** Need to focus the input when the component mounts because the component could initially suspend. */
      inputRef.current.focus();
    }
  }, []);

  return (
    <CommandPrimitive.CommandRoot shouldFilter={false}>
      <ComboboxInput
        ref={inputRef}
        loading={isDeferred}
        placeholder={`Search ${label}...`}
        onValueChange={(value) => {
          setSearch(value);
        }}
      />
      <ComboboxList className={className}>
        {filteredAndSortedData.map((item) => {
          const isSelected = selection?.id === item.id;

          return (
            <ComboboxItem
              key={item.id}
              value={item.id.toString()}
              data-checked={isSelected}
              onSelect={() => {
                onItemSelect(isSelected ? null : item);
              }}
            >
              {listItemRender?.(item) ?? item.name}
              <ComboboxCheck checked={isSelected} />
            </ComboboxItem>
          );
        })}
        {hasNextPage && <ListLoadMore loading={isFetchingNextPage} ref={loadMoreRef} />}
        {filteredAndSortedData.length === 0 && !isDeferred && (
          <div className="text-ds-text-primary py-6 text-center text-sm">No results</div>
        )}
      </ComboboxList>
    </CommandPrimitive.CommandRoot>
  );
}
