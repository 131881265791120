import PropTypes from "prop-types";
import styled from "styled-components";
import Avatar from "~/shared/components/Avatar";
import Tooltip from "~/shared/components/Tooltip";
import InlineLink from "../InlineLink";
import DataCell from "./DataCell";

const AvatarWrap = styled.div`
  width: 80%;
  position: relative;
  display: flex;
`;

const AvatarLink = styled(InlineLink)`
  text-decoration: none;

  &:first-child {
    transform: translateX(11px);
  }
`;

const UserLink = ({ user, children }) =>
  user.type === "participant" ? <AvatarLink href={`/participants/${user.id}`}>{children}</AvatarLink> : children;

const AvatarCell = ({ data, ...props }) => {
  if (!data) return null;
  const { user } = data;

  return (
    <DataCell {...props} style={{ width: "100%" }}>
      <AvatarWrap>
        {user && (
          <Tooltip content={`${user.firstName} ${user.lastName}`}>
            <span>
              <UserLink user={user}>
                <Avatar
                  src={user.headshot?.location}
                  alt={`${user.firstName} ${user.lastName}`}
                  fallback={[user.firstName, user.lastName]}
                  size={33}
                  style={{ width: "33px" }}
                />
              </UserLink>
            </span>
          </Tooltip>
        )}
      </AvatarWrap>
    </DataCell>
  );
};

UserLink.propTypes = {
  user: PropTypes.object,
  children: PropTypes.node,
};

AvatarCell.propTypes = {
  data: PropTypes.object,
};

export default AvatarCell;
