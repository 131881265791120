import PropTypes from "prop-types";
import styled from "styled-components";
import { hover } from "~/shared/styles/helpers";
import UnstyledButton from "./UnstyledButton";

const ActionText = styled(UnstyledButton)`
  text-decoration: underline;
  color: inherit;

  ${(props) =>
    props.delete &&
    `
    --hover-color: var(--error-red-dark);
    color: var(--error-red);
  `}

  ${hover(`
    color: var(--hover-color, var(--primary-green));
  `)}
`;

export const ActionDivider = styled.span`
  display: inline-block;
  padding: 0 4px;
`;

ActionText.propTypes = {
  delete: PropTypes.bool,
};

export default ActionText;
