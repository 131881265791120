import { fetchAuthSession } from "aws-amplify/auth";
import React, { useContext, useEffect, useState } from "react";
import { Socket, io } from "socket.io-client";
import config from "~/config";
import { useUserProfile } from "~/shared/api/user/user";

interface SocketProviderProps {
  children?: React.ReactNode;
}
export function SocketProvider({ children }: SocketProviderProps) {
  const [socket, setSocket] = useState<Socket | null>(null);
  const { data: user } = useUserProfile();

  useEffect(() => {
    if (!user) return;
    let newSocket: Socket | null = null;

    async function getAccessToken() {
      const session = await fetchAuthSession();
      return session.tokens?.accessToken.toString() ?? "";
    }
    (async function connect() {
      if (config.socketURL) {
        const token = await getAccessToken();
        newSocket = io(config.socketURL, {
          reconnection: true,
          reconnectionAttempts: 3,
          transports: ["websocket"],
          auth: {
            token,
          },
          query: {
            token,
          },
        });
      }
      newSocket?.on?.("connect", () => {
        setSocket(newSocket);
      });
    })();

    return () => {
      setSocket(null);
      newSocket?.disconnect?.();
    };
  }, [user]);

  return <SocketContext.Provider value={{ socket }}>{children}</SocketContext.Provider>;
}
SocketProvider.displayName = "SocketProvider";

const SocketContext = React.createContext<{ socket: Socket | null }>({
  socket: null,
});

export function useSocket() {
  const { socket } = useContext(SocketContext);
  return socket;
}

/**
 * Custom hook for handling socket events.
 *
 * @template TEvent - The type of socket event.
 * @param {TEvent} event - The socket event to listen for.
 * @param {(data: SocketEvent[TEvent]) => void} callback - The callback function to be executed when the event is
 *   triggered. Make sure to use `useCallback` to prevent unnecessary re-renders.
 */
export function useSocketEvent<TEvent extends keyof SocketPayloads>(
  event: TEvent,
  callback: (data: SocketPayloads[TEvent]) => void,
) {
  const socket = useSocket();

  useEffect(() => {
    if (!socket) return;

    socket.on(event, callback);

    return () => {
      socket.off(event, callback);
    };
  }, [socket, event, callback]);
}

interface SocketPayloads {
  newNotifications: unknown;
}
