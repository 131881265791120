import * as React from "react";
import ReactDOM from "react-dom";
import { twMerge } from "tailwind-merge";
import { Drawer as DrawerPrimitive } from "vaul";
import { heading } from "~/shared/styles/heading";
import { copy } from "~/shared/styles/text";

/**
 * This is a mobile-specific component that slides in from the bottom of the screen and is dismissible.
 *
 * It's best to use this component to handle mobile-specific versions of other components like `Dialog`, `Modal` or
 * `Sheet`.
 */
export function Drawer({
  /* Intentionally plucking this prop out to prevent it from being passed to the `DrawerPrimitive.Root` component. It does not work with our application layout. */
  shouldScaleBackground,
  children,
  ...rest
}: React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Root>) {
  return (
    <DrawerPrimitive.Root shouldScaleBackground={false} {...rest}>
      <DrawerFooterActionsPortalProvider>{children}</DrawerFooterActionsPortalProvider>
    </DrawerPrimitive.Root>
  );
}

export const DrawerNestedRoot = DrawerPrimitive.NestedRoot;
export const DrawerTrigger = DrawerPrimitive.Trigger;

const DrawerPortal = DrawerPrimitive.Portal;

export const DrawerClose = DrawerPrimitive.Close;

export const DrawerOverlay = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Overlay>
>(({ className, ...rest }, ref) => {
  return (
    <DrawerPrimitive.Overlay
      ref={ref}
      className={twMerge("bg-ds-neutral-900/80 fixed inset-0 z-50", className)}
      {...rest}
    />
  );
});
DrawerOverlay.displayName = DrawerPrimitive.Overlay.displayName;

export const DrawerContent = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Content>
>(({ className, children, ...rest }, ref) => {
  return (
    <DrawerPortal>
      <DrawerOverlay />
      <DrawerPrimitive.Content
        ref={ref}
        className={twMerge(
          "bg-ds-bg-foundation fixed inset-x-0 bottom-0 z-50 mt-24 flex h-auto flex-col rounded-t-[10px] border",
          className,
        )}
        {...rest}
      >
        <div className="bg-muted mx-auto mt-4 h-2 w-[100px] flex-shrink-0 rounded-full" />
        {children}
      </DrawerPrimitive.Content>
    </DrawerPortal>
  );
});
DrawerContent.displayName = "DrawerContent";

export function DrawerHeader({ className, ...rest }: React.ComponentPropsWithoutRef<"div">) {
  return <div className={twMerge("grid gap-1.5 p-4 text-center sm:text-left", className)} {...rest} />;
}

export function DrawerFooter({ className, ...rest }: React.ComponentPropsWithoutRef<"div">) {
  const [, setFooterActionsPortal] = React.useContext(DrawerFooterActionsPortalContext) ?? [];

  return (
    <div
      ref={setFooterActionsPortal}
      className={twMerge("mt-auto flex flex-col gap-2 p-4 empty:hidden", className)}
      {...rest}
    />
  );
}

const DrawerFooterActionsPortalContext = React.createContext<
  [HTMLDivElement | null, React.Dispatch<React.SetStateAction<HTMLDivElement | null>>] | null
>(null);

interface DrawerFooterActionsPortalProviderProps {
  children: React.ReactNode;
}
export function DrawerFooterActionsPortalProvider({ children }: DrawerFooterActionsPortalProviderProps) {
  const sheetFooterActionsPortalState = React.useState<HTMLDivElement | null>(null);

  return (
    <DrawerFooterActionsPortalContext.Provider value={sheetFooterActionsPortalState}>
      {children}
    </DrawerFooterActionsPortalContext.Provider>
  );
}

export function DrawerFooterActionsPortal({ children }: { children: React.ReactNode }) {
  const [portalContentElement] = React.useContext(DrawerFooterActionsPortalContext) ?? [];

  return portalContentElement ? ReactDOM.createPortal(children, portalContentElement) : null;
}

export const DrawerTitle = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Title>
>(({ className, ...rest }, ref) => {
  return <DrawerPrimitive.Title ref={ref} className={heading({ variant: "20", className })} {...rest} />;
});
DrawerTitle.displayName = DrawerPrimitive.Title.displayName;

export const DrawerDescription = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Description>
>(({ className, ...rest }, ref) => {
  return (
    <DrawerPrimitive.Description
      ref={ref}
      className={copy({
        variant: "14",
        color: "secondary",
        className: twMerge("text-balance", className),
      })}
      {...rest}
    />
  );
});
DrawerDescription.displayName = DrawerPrimitive.Description.displayName;
