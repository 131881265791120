import * as Sentry from "@sentry/react";
import { QueryClient, queryOptions, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { USER_ACCESS, USER_ADMIT_DECISIONS, USER_TYPES } from "~/shared/api/user/user.constants";

import { fetchMFAPreference, signOut } from "aws-amplify/auth";
import { useSWRConfig } from "swr";
import api from "~/shared/api/api";
import { PARTICIPANT_QUERIES } from "~/shared/api/participants";
import { Params } from "~/shared/api/types/params";
import { posthogClient } from "~/shared/lib/posthog/posthog-client";

export const USER_KEYS = {
  all: ["user"] as const,
  detail: (id: string) => [...USER_KEYS.all, id] as const,
  demographics: (id: string) => [...USER_KEYS.detail(id), "demographics"] as const,
  surveys: (id: string) => [...USER_KEYS.detail(id), "surveys"] as const,
  survey: (userId: string, assignedId: string) => [...USER_KEYS.surveys(userId), "detail", assignedId] as const,
};

export const USER_OPTIONS = {
  user: () => {
    return queryOptions({
      queryKey: USER_KEYS.all,
      queryFn: async ({ signal }) => {
        try {
          const response = await api.get<UserProfileResponse>("/auth/profile", {
            signal,
          });

          Sentry.setUser({
            email: response.data.email,
            username: response.data.username,
          });

          Sentry.setTag("user_type", response.data.type);

          posthogClient?.identify(response.data.id.toString(), {
            email: response.data.email,
            username: response.data.username,
            user_type: response.data.type,
            programs: response.data.profile?.programs?.map((program) => program.id) ?? [],
          });
          posthogClient?.setPersonPropertiesForFlags({
            id: response.data.id.toString(),
            email: response.data.email,
            username: response.data.username,
            user_type: response.data.type,
            programs: response.data.profile?.programs?.map((program) => program.id) ?? [],
          });

          return response.data;
        } catch (e) {
          /**
           * Nuke cognito auth state if auth profile endpoint is not working as expected even though cognito auth is
           * valid.
           */
          await signOut();
          throw e;
        }
      },
      retry: 3,
      staleTime: 60 * 1000 * 10, // 10min
    });
  },
  userAuthPreferences: () => {
    return queryOptions({
      queryKey: [...USER_OPTIONS.user().queryKey, "authPreferences"] as const,
      queryFn: async () => {
        const preferences = await fetchMFAPreference();
        return preferences;
      },
    });
  },
  demographics: (id: string) => {
    return queryOptions({
      queryKey: USER_KEYS.demographics(id),
      queryFn: async ({ signal }) => {
        const response = await api.get(`/users/${id}/demographics`, {
          signal,
        });

        return response.data;
      },
    });
  },
  surveys: (id: string, params: Params) => {
    return queryOptions({
      queryKey: [...USER_KEYS.surveys(id), params],
      queryFn: async ({ signal }) => {
        const response = await api.get(`/users/${id}/assigned-surveys`, {
          ...params,
          signal,
        });

        return response.data;
      },
    });
  },
  survey: (userId: string, assignedId: string) => {
    return queryOptions({
      queryKey: USER_KEYS.survey(userId, assignedId),
      queryFn: async ({ signal }) => {
        const response = await api.get(`/users/${userId}/assigned-surveys/${assignedId}`, {
          signal,
        });

        return response.data;
      },
    });
  },
};

// TODO: This should be moved to a shared location and more robustly typed
export interface UserProfileResponse {
  type: keyof typeof USER_TYPES;
  admitDecision: keyof typeof USER_ADMIT_DECISIONS;
  codeOfConduct: boolean;
  onboardingStep: number;
  id: number;
  headshot?: {
    location?: string;
  };
  active: boolean;
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  status: "Active" | "Pending" | "None";
  statuses?: { status: { id: number; type: string } }[];
  roles: (keyof typeof USER_ACCESS)[];
  segments: { name: string }[];
  featureAnnouncements: { id: number; featureId: number }[];
  exo?: Partial<Omit<UserProfileResponse, "profile">>;
  profile?: {
    originalResume?: string;
    oneLiner?: string;
    finalResume?: string;
    totalCompensation?: string;
    linkedin?: string;
    locations?: { id: number; name: string }[];
    skills?: { id: number; name: string }[];
    interviewer?: Partial<Omit<UserProfileResponse, "profile">>;
    dateAvailable: string;
    programs?: Array<{ id: number; name: string }>;
    workEnvironment?: Array<string>;
    employmentType?: Array<string>;
    compensationExpectation?: number;
    jobFunctions?: Array<{ name: string; id: number }>;
    industries?: Array<{ name: string; id: number }>;
  };
  partner?: {
    name: string;
  };
  ldHash: string;
  previouslyActivated: boolean;
}

export function useUserProfile() {
  return useQuery({
    ...USER_OPTIONS.user(),
    throwOnError: false,
    retry: false,
  });
}

export function useLogout() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      await signOut();
    },
    onSuccess: () => {
      queryClient.removeQueries({ queryKey: USER_OPTIONS.user().queryKey });
    },
  });
}

export function useActivateParticipantAccount(id: string | number) {
  const queryClient = useQueryClient();
  const { mutate } = useSWRConfig();

  return useMutation<unknown, Error, Record<string, unknown>>({
    mutationFn: async (data) => {
      const response = await api.post(`users/${id}/activate`, {
        ...data,
        // We always want to send a formal welcome email when activating a user account
        formalWelcome: true,
      });

      return response.data;
    },
    onSuccess: () => {
      return Promise.all([
        mutate(checkParticipantKey),
        queryClient.invalidateQueries({
          queryKey: PARTICIPANT_QUERIES.participant(id.toString()).queryKey,
        }),
      ]);
    },
  });
}

export function useReactivateParticipantAccount(id: string | number) {
  const { mutate } = useSWRConfig();

  return useMutation<unknown, Error, Record<string, unknown>>({
    mutationFn: async (data) => {
      const response = await api.post(`users/${id}/activate`, {
        ...data,
        // We don't want to send a formal welcome email when reactivating a user account
        formalWelcome: false,
      });

      return response.data;
    },
    onSuccess: () => {
      mutate(checkParticipantKey);
    },
  });
}

export function useDeactivateAccount(id: number) {
  const { mutate } = useSWRConfig();

  return useMutation({
    mutationFn: async () => {
      await api.post(`/users/${id}/deactivate`, {});
    },
    onSuccess: () => {
      mutate(checkParticipantKey);
    },
  });
}

function checkParticipantKey(args: string | [string, unknown]) {
  return typeof args === "string" && args.startsWith("participants/");
}

export async function getUserType(queryClient: QueryClient) {
  const user = await queryClient.ensureQueryData(USER_OPTIONS.user());

  return user.type;
}
