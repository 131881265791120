import styled from "styled-components";
import screen from "superior-mq";
import { bp } from "~/shared/styles/helpers";

const ButtonGroup = styled.div`
  display: grid;
  grid-column-gap: 12px;
  grid-auto-flow: column;
  justify-content: start;

  ${(props) =>
    props.span &&
    `
    grid-column: span ${props.span};
  `}

  ${screen.below(
    bp.mobileSm,
    `
    grid-column-gap: 8px;
  `,
  )}
`;

export default ButtonGroup;
