import { NavLink, NavLinkProps } from "react-router-dom";
import { VariantProps } from "tailwind-variants";
import { tabsStyles } from "~/shared/components/ds/Tabs";

interface TabNavigationProps extends React.ComponentPropsWithoutRef<"nav">, VariantProps<typeof tabsStyles> {}
export function TabNavigation({ className, ...rest }: TabNavigationProps) {
  const { list } = tabsStyles();

  return <nav className={list({ className })} {...rest} />;
}

interface TabNavigationItemProps extends Omit<NavLinkProps, "className">, VariantProps<typeof tabsStyles> {
  className?: string;
}
export function TabNavigationItem({ className, size, ...rest }: TabNavigationItemProps) {
  const { trigger } = tabsStyles();

  return <NavLink className={trigger({ size })} {...rest} />;
}
