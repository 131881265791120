import { ErrorMessage } from "@hookform/error-message";
import { rem } from "polished";
import PropTypes from "prop-types";
import React from "react";
import { Mention, MentionsInput } from "react-mentions";
import styled from "styled-components";
import apiService from "~/shared/api/api-service";
import AvatarTitle from "~/shared/components/AvatarTitle";
import HelperText from "./HelperText";
import Label from "./Label";

const TextareaWrap = styled.div`
  position: relative;
  grid-column: span 2;
  max-width: 100%;
`;

const GrowWrap = styled.div`
  display: grid;
  max-width: 100%;
  overflow: hidden;
  max-height: 150px;

  &::after {
    content: attr(data-value) " ";
    white-space: pre-wrap;
    visibility: hidden;
  }

  > textarea {
    resize: none;
    overflow: hidden;
  }

  &::after,
  > textarea {
    width: 100%;
    border: var(--border);
    padding: 13px 12px;
    font: inherit;
    grid-area: 1 / 1 / 2 / 2;
    border-radius: var(--border-radius);
    background-color: var(--off-white);
  }
`;

const mentionStyle = {
  gridArea: "1 / 1 / 2 / 2",
  marginBottom: "30px",
  minHeight: "72px",

  "&multiLine": {
    input: {
      border: "var(--border)",
      padding: "13px 12px",
      font: "inherit",
      borderRadius: "var(--border-radius)",
      backgroundColor: "var(--off-white)",
      overflowY: "auto",
      maxHeight: "150px",
    },
  },
  suggestions: {
    borderRadius: "var(--input-border-radius,var(--border-radius))",
    list: {
      backgroundColor: "white",
      border: "1px solid rgba(0,0,0,0.15)",
      fontSize: 14,
      borderRadius: "var(--input-border-radius,var(--border-radius))",
    },
    item: {
      padding: "5px 15px",
      borderBottom: "1px solid rgba(0,0,0,0.15)",
      "&focused": {
        backgroundColor: "#cee4e5",
      },
    },
  },
};

const StyledTextarea = styled.textarea`
  outline: none;
  appearance: none;

  &:focus {
    background-color: var(--white);
    border-color: var(--primary-green);
    box-shadow: var(--focus-shadow);
  }

  && {
    ${(props) =>
      props.errors &&
      props.errors[props.name] &&
      `
      border-color: var(--error-red);
    `};
  }
`;

const Count = styled.div`
  text-align: right;
  font-size: ${rem(14)};
`;

const Textarea = React.forwardRef(
  (
    {
      name,
      value,
      label,
      errors = {},
      hideLabel,
      required,
      placeholder,
      infoText,
      disabled,
      className,
      rows = 3,
      maxLength,
      allowMentions = false,
      ...rest
    },
    ref,
  ) => {
    const id = `textarea-${name}`;
    const remaining = maxLength - value?.length;

    const mentionFormatter = (option) => ({
      firstName: option.firstName,
      lastName: option.lastName,
      display: `${option.firstName} ${option.lastName}`,
      id: option.id,
      email: option.email,
      headshot: option?.headshot?.location,
    });

    const fetchData = (search, cb) => {
      if (!search) return [];

      const fullPath = `staff?limit=50&term=${search}`;

      apiService
        .simpleSearch(fullPath)
        .then((response) => response.data)
        .then((response) => response.items.map((user) => mentionFormatter(user)))
        .then(cb);
    };

    const renderSuggestion = (entry) => {
      return (
        <AvatarTitle
          src={entry.headshot}
          alt={entry.display}
          size={40}
          gutter={16}
          fallback={[entry.firstName, entry.lastName]}
          fallbackFontSize={16}
        >
          <div>
            {entry.firstName} {entry.lastName}
          </div>
          <span>{entry.email}</span>
        </AvatarTitle>
      );
    };

    return (
      <TextareaWrap className={className}>
        {label != null && (
          <Label htmlFor={id}>
            {label}
            {required ? "*" : null}
          </Label>
        )}
        <GrowWrap data-value={value}>
          {allowMentions ? (
            <MentionsInput
              value={value || ""}
              className="mentions"
              onChange={rest.onChange}
              placeholder="Enter your text... use @ to tag a team member!"
              a11ySuggestionsListLabel={"Suggested team members for mention"}
              style={mentionStyle}
            >
              <Mention
                markup="@[__display__](__id__)"
                renderSuggestion={renderSuggestion}
                displayTransform={(id, display) => `@${display}`}
                trigger="@"
                data={fetchData}
              />
            </MentionsInput>
          ) : (
            <StyledTextarea
              as={StyledTextarea}
              ref={ref}
              value={value}
              name={name}
              id={id}
              disabled={disabled}
              placeholder={placeholder}
              rows={rows}
              errors={errors}
              {...rest}
            />
          )}
        </GrowWrap>

        {value && maxLength ? (
          <Count style={{ color: remaining < 0 ? "var(--error-red)" : "inherit" }}>{remaining}</Count>
        ) : null}

        {(infoText || errors[name]) && (
          <HelperText>
            {!!infoText && !errors[name] && <p>{infoText}</p>}
            {errors[name] && (
              <HelperText.Error>
                <ErrorMessage errors={errors} name={name} />
              </HelperText.Error>
            )}
          </HelperText>
        )}
      </TextareaWrap>
    );
  },
);

Textarea.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  errors: PropTypes.object,
  hideLabel: PropTypes.bool,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  infoText: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  rows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Textarea;
