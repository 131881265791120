import Tooltip from "~/shared/components/Tooltip";
import { formatToZonedDateTime } from "~/shared/util/formatText";

// created at & updated at are stored in UTC
const DateWithHover = ({ date }) => (
  <div>
    <Tooltip content={formatToZonedDateTime(date)}>
      <span>{formatToZonedDateTime(date)}</span>
    </Tooltip>
  </div>
);

export default DateWithHover;
