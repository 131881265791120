import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import * as React from "react";
import { twMerge } from "tailwind-merge";
import { VariantProps, tv } from "tailwind-variants";
import { Icon, IconUse } from "~/shared/components/ds/icons/Icon";

export const DropdownMenu = DropdownMenuPrimitive.Root;

export const DropdownMenuTrigger = DropdownMenuPrimitive.Trigger;

export const DropdownMenuGroup = DropdownMenuPrimitive.Group;

export const DropdownMenuPortal = DropdownMenuPrimitive.Portal;

export const DropdownMenuSub = DropdownMenuPrimitive.Sub;

export const DropdownMenuSubTrigger = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.SubTrigger>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.SubTrigger> & {
    inset?: boolean;
  }
>(({ className, inset, children, ...props }, ref) => (
  <DropdownMenuPrimitive.SubTrigger
    ref={ref}
    className={twMerge(
      "focus:bg-ds-bg-weaker data-[state=open]:bg-ds-bg-weaker flex cursor-default select-none items-center rounded-sm p-2 text-sm outline-none",
      inset && "pl-8",
      className,
    )}
    {...props}
  >
    {children}
    <Icon className="ml-auto h-4 w-4">
      <IconUse id="arrow-right-s-line" />
    </Icon>
  </DropdownMenuPrimitive.SubTrigger>
));
DropdownMenuSubTrigger.displayName = DropdownMenuPrimitive.SubTrigger.displayName;

export const DropdownMenuSubContent = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.SubContent>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.SubContent>
>(({ className, ...props }, ref) => (
  <DropdownMenuPrimitive.SubContent
    ref={ref}
    className={twMerge(
      "border-ds-stroke-tertiary bg-ds-bg-foundation text-ds-text-primary z-50 min-w-[8rem] overflow-hidden rounded-md border p-1",
      "data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
      className,
    )}
    {...props}
  />
));
DropdownMenuSubContent.displayName = DropdownMenuPrimitive.SubContent.displayName;

interface DropdownMenuContentProps extends React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Content> {
  container?: DropdownMenuPrimitive.DropdownMenuPortalProps["container"];
}
export const DropdownMenuContent = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Content>,
  DropdownMenuContentProps
>(({ className, container, sideOffset = 4, ...rest }, ref) => {
  const containerFromContext = React.useContext(DropdownMenuContainerContext) ?? container;

  return (
    <DropdownMenuPrimitive.Portal container={containerFromContext}>
      <DropdownMenuPrimitive.Content
        ref={ref}
        sideOffset={sideOffset}
        className={twMerge(
          "border-ds-stroke-tertiary bg-ds-bg-foundation text-ds-text-primary z-50 min-w-[8rem] overflow-hidden rounded-md border p-1",
          "data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
          className,
        )}
        {...rest}
      />
    </DropdownMenuPrimitive.Portal>
  );
});
DropdownMenuContent.displayName = DropdownMenuPrimitive.Content.displayName;

export type DropdownMenuItemProps = React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Item> &
  VariantProps<typeof dropdownMenuItemStyles>;
export const DropdownMenuItem = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Item>,
  DropdownMenuItemProps
>(({ className, inset, variant = "default", ...rest }, ref) => (
  <DropdownMenuPrimitive.Item
    ref={ref}
    className={dropdownMenuItemStyles({
      inset,
      variant,
      className,
    })}
    {...rest}
  />
));
DropdownMenuItem.displayName = DropdownMenuPrimitive.Item.displayName;

const dropdownMenuItemStyles = tv({
  base: "relative flex cursor-default select-none items-center gap-2 rounded px-2 py-1.5 text-sm outline-none transition-colors data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
  variants: {
    inset: {
      true: "pl-8",
    },
    variant: {
      default: "text-ds-text-primary focus:bg-ds-bg-weaker focus:text-ds-text-primary",
      destructive:
        "text-ds-state-error hover:bg-ds-red-lightest hover:text-ds-state-error focus:bg-ds-red-lightest focus:text-ds-state-error",
    },
  },
  defaultVariants: {
    inset: false,
    variant: "default",
  },
});

interface DropdownMenuCheckboxItemProps
  extends React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.CheckboxItem> {
  checkPosition?: "start" | "end";
}
export const DropdownMenuCheckboxItem = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.CheckboxItem>,
  DropdownMenuCheckboxItemProps
>(({ checkPosition = "start", className, children, checked, ...props }, ref) => (
  <DropdownMenuPrimitive.CheckboxItem
    ref={ref}
    className={twMerge(
      "focus:bg-ds-bg-weaker focus:text-ds-text-primary relative flex cursor-default select-none items-center gap-2 rounded-sm p-2 text-sm outline-none transition-colors data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
      className,
    )}
    checked={checked}
    {...props}
  >
    {checkPosition === "start" && (
      <span className="flex h-4 w-4 shrink-0 items-center justify-center">
        <DropdownMenuPrimitive.ItemIndicator>
          <Icon className="h-4 w-4">
            <IconUse id="checkbox-check" />
          </Icon>
        </DropdownMenuPrimitive.ItemIndicator>
      </span>
    )}
    <span className="block min-w-0 flex-1 truncate">{children}</span>
    {checkPosition === "end" && (
      <span className="flex h-4 w-4 shrink-0 items-center justify-center">
        <DropdownMenuPrimitive.ItemIndicator>
          <Icon className="h-4 w-4">
            <IconUse id="checkbox-check" />
          </Icon>
        </DropdownMenuPrimitive.ItemIndicator>
      </span>
    )}
  </DropdownMenuPrimitive.CheckboxItem>
));
DropdownMenuCheckboxItem.displayName = DropdownMenuPrimitive.CheckboxItem.displayName;

export const DropdownMenuLabel = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Label>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Label> & {
    inset?: boolean;
  }
>(({ className, inset, ...rest }, ref) => (
  <DropdownMenuPrimitive.Label
    ref={ref}
    className={twMerge("px-2 py-1.5 text-sm font-medium", inset && "pl-8", className)}
    {...rest}
  />
));
DropdownMenuLabel.displayName = DropdownMenuPrimitive.Label.displayName;

export const DropdownMenuSeparator = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Separator>
>(({ className, ...rest }, ref) => (
  <DropdownMenuPrimitive.Separator
    ref={ref}
    className={twMerge("bg-ds-stroke-tertiary -mx-1 my-1 h-px", className)}
    {...rest}
  />
));
DropdownMenuSeparator.displayName = DropdownMenuPrimitive.Separator.displayName;

export function DropdownMenuShortcut({ className, ...rest }: React.HTMLAttributes<HTMLSpanElement>) {
  return <span className={twMerge("ml-auto text-xs tracking-widest opacity-60", className)} {...rest} />;
}

export function DropdownMenuContainerProvider({
  children,
  container,
}: {
  children: React.ReactNode;
  container?: DropdownMenuPrimitive.DropdownMenuPortalProps["container"];
}) {
  return <DropdownMenuContainerContext.Provider value={container}>{children}</DropdownMenuContainerContext.Provider>;
}

const DropdownMenuContainerContext =
  React.createContext<DropdownMenuPrimitive.DropdownMenuPortalProps["container"]>(undefined);
