import * as React from "react";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { VariantProps, tv } from "tailwind-variants";
import { ExternalSavedRole, InternalSavedRole, PayType } from "~/shared/api/participants";
import { CompanyAvatar } from "~/shared/components/ds/Avatar";
import { Badge } from "~/shared/components/ds/Badge";
import { TableCell } from "~/shared/components/ds/Table";
import { Icon, IconUse } from "~/shared/components/ds/icons/Icon";
import { RoleType } from "~/shared/constants/savedRoles.constants";
import { focusRingStyles } from "~/shared/styles/focus";
import { copy } from "~/shared/styles/text";

export function SavedRoleLogoCellContent({ row }: { row: InternalSavedRole | ExternalSavedRole }) {
  const { company } = row;
  const companyLogo = typeof company !== "string" ? (company.logo?.location ?? "") : "";
  const companyName = typeof company === "string" ? company : company.name;

  if (companyLogo?.length === 0) return <span className="sr-only">Logo not available</span>;

  return (
    <CompanyAvatar
      alt={`${companyName} logo`}
      fallback={[companyName]}
      size={{
        initial: "sm",
        lg: "base",
      }}
      logo={companyLogo}
    />
  );
}

export function SavedRoleRoleCell({ row }: { row: InternalSavedRole | ExternalSavedRole }) {
  const { company, type, job, isRemote, compensation, payType } = row;
  const jobTitle = typeof job === "string" ? job : job.title;

  switch (type) {
    case "internal": {
      return (
        <RoleCellLayout
          company={company.name}
          compensation={compensation}
          isRemote={isRemote}
          type={type}
          payType={payType}
        >
          {row.job?.url && row.job.url?.length > 0 ? (
            <Link
              to={row.job.url}
              className={copy({
                variant: {
                  initial: "14",
                  lg: "16",
                },
                className: focusRingStyles({
                  className: "linkOverlay font-medium no-underline hover:opacity-75",
                }),
              })}
              target="_blank"
              rel="noreferrer noopener"
            >
              {jobTitle}
            </Link>
          ) : (
            <span
              className={copy({
                variant: {
                  initial: "14",
                  lg: "16",
                },
                weight: "medium",
              })}
            >
              {jobTitle}
            </span>
          )}
        </RoleCellLayout>
      );
    }
    case "external": {
      return (
        <RoleCellLayout
          company={typeof company === "string" ? company : company.name}
          compensation={compensation}
          isRemote={isRemote}
          type={type}
          payType={payType}
        >
          {row.url && row.url?.length > 0 ? (
            <Link
              to={row.url}
              className={copy({
                variant: {
                  initial: "14",
                  lg: "16",
                },
                className: focusRingStyles({
                  className: "linkOverlay font-medium no-underline hover:opacity-75",
                }),
              })}
              target="_blank"
              rel="noreferrer noopener"
            >
              {jobTitle}
            </Link>
          ) : (
            <span
              className={copy({
                variant: {
                  initial: "14",
                  lg: "16",
                },
                weight: "medium",
              })}
            >
              {jobTitle}
            </span>
          )}
        </RoleCellLayout>
      );
    }
    default: {
      return <TableCell />;
    }
  }
}

function RoleCellLayout({
  isRemote,
  type,
  company,
  compensation,
  payType,
  children,
}: {
  isRemote: boolean;
  type: RoleType;
  company: string;
  payType?: PayType;
  compensation?: number;
  children?: React.ReactNode;
}) {
  return (
    <TableCell>
      <span className="linkBox relative flex w-fit flex-col gap-1">
        <span className="inline-flex shrink-0 flex-wrap items-baseline gap-x-3 gap-y-1">
          {children}
          <span className="flex gap-1">
            {isRemote && (
              <Badge
                size="sm"
                colorScheme="blue"
                icon={
                  <Icon>
                    <IconUse id="base-station-fill" />
                  </Icon>
                }
              >
                Remote
              </Badge>
            )}
            {type === "external" && (
              <Badge
                size="sm"
                colorScheme="purple"
                icon={
                  <Icon>
                    <IconUse id="external-link-line" />
                  </Icon>
                }
              >
                External
              </Badge>
            )}
          </span>
        </span>
        <span
          className={copy({
            variant: "14",
            color: "secondary",
            className: "line-clamp-2 flex-1",
          })}
        >
          {company}
        </span>
        <span className="text-ds-text-tertiary block pt-1 text-xs lg:hidden">
          <Comp comp={compensation} payType={payType} />
        </span>
      </span>
    </TableCell>
  );
}

export function Comp({ comp, payType }: { comp?: number; payType?: PayType }) {
  if (!comp) return null;

  function getPayTypeLabel(payType?: PayType) {
    if (!payType) return "";
    const payTypeLabel = {
      Salary: "/yr",
      Hourly: "/hr",
    } as const;

    return payTypeLabel[payType];
  }

  function getFormattedComp(comp: number) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(comp);
  }

  return (
    <span className="whitespace-nowrap">
      {getFormattedComp(comp)} <span className="text-ds-text-tertiary">{getPayTypeLabel(payType)}</span>
    </span>
  );
}

export function NotesCellContentLayout({ children, className }: { className?: string; children: React.ReactNode }) {
  return (
    <span className={twMerge("flex gap-2", className)}>
      <span className="flex h-5 items-center">
        <Icon className="h-4 w-4 shrink-0 text-current" aria-hidden>
          <IconUse id="file-text-line" />
        </Icon>
      </span>
      <span className="line-clamp-2 hidden text-ellipsis text-left text-current lg:[display:-webkit-box]">
        {children}
      </span>
    </span>
  );
}

export const NotesCellButton = React.forwardRef<
  HTMLButtonElement,
  React.ComponentPropsWithoutRef<"button"> & VariantProps<typeof notesCellButtonStyles>
>(({ state, children, className, ...rest }, ref) => {
  const styles = notesCellButtonStyles({ state, className });

  return (
    <button ref={ref} className={styles} {...rest}>
      {children}
    </button>
  );
});
NotesCellButton.displayName = "NotesCellButton";

const notesCellButtonStyles = tv({
  base: focusRingStyles({
    className:
      "transition-colors hover:bg-ds-bg-weaker active:bg-ds-bg-soft rounded-md px-2 py-1 flex items-center justify-center w-full lg:w-auto lg:justify-start",
  }),
  variants: {
    state: {
      empty: "text-ds-text-tertiary hover:text-ds-text-primary",
      filled: "text-ds-text-secondary hover:text-ds-text-primary",
    },
  },
  defaultVariants: {
    state: "empty",
  },
});
