import "@reach/menu-button/styles.css";
import * as Sentry from "@sentry/react";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Amplify } from "aws-amplify";
import * as React from "react";
import { Helmet } from "react-helmet";
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  Outlet,
  RouterProvider,
  ScrollRestoration,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { queryClient } from "~/shared/api/query-client";
import { IconSprite } from "~/shared/components/ds/icons/Icon";
import { LoadingContainer } from "~/shared/components/ds/Spinner";
import { Toaster } from "~/shared/components/ds/Toast/Toast";
import { RootError } from "~/shared/components/Error";
import Maintenance from "~/shared/components/Maintenance";
import { posthogClient } from "~/shared/lib/posthog/posthog-client";
import { PrivateRoutes } from "~/views/PrivateRoutes";
import { PublicRoutes } from "~/views/PublicRoutes";
import config from "./config";
import Symbols from "./shared/components/svg/Symbols";
import GlobalStyle from "./shared/styles/global-style";

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: config.cognito.poolID!,
      userPoolClientId: config.cognito.appID!,
    },
  },
});

if (config.sentry.dsn) {
  Sentry.init({
    dsn: config.sentry.dsn,
    environment: process.env.NODE_ENV,
    attachStacktrace: true,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
        networkDetailAllowUrls: [/^https:\/\/(dev-api|api|app)\.breakline\.org/],
      }),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: process.env.NODE_ENV === "development" ? 1.0 : 0.5,
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/(dev-api|api|app)\.breakline\.org/],
    // Session Replay
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: process.env.NODE_ENV === "production" ? 1.0 : 0,
  });
}

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);
const router = sentryCreateBrowserRouter([
  {
    path: "/",
    element: <RootRoute />,
    children: [...PublicRoutes(queryClient), ...PrivateRoutes(queryClient, posthogClient)],
  },
]);

function RootRoute() {
  return (
    <div>
      <Outlet />
      <ScrollRestoration />
    </div>
  );
}

function App() {
  if (
    Number.parseInt(config.maintenanceMode ?? "", 10) === 1 &&
    window.location.search !== `?mcode=${config.maintenanceModeCode}`
  ) {
    return (
      <>
        <Helmet
          htmlAttributes={{ lang: "en" }}
          defaultTitle="BreakLine"
          titleTemplate="%s | BreakLine"
          meta={[{ charSet: "UTF-8" }, { name: "robots", content: "noindex" }]}
        />
        <Maintenance />
        <GlobalStyle />
      </>
    );
  }
  return (
    <RootError>
      <React.Suspense fallback={<LoadingContainer level="full" />}>
        <Helmet
          htmlAttributes={{ lang: "en" }}
          defaultTitle="BreakLine"
          titleTemplate="%s | BreakLine"
          meta={[{ charSet: "UTF-8" }, { name: "robots", content: "noindex" }]}
        />
        <QueryClientProvider client={queryClient}>
          <Symbols />
          <IconSprite />
          <RouterProvider router={router} />

          <GlobalStyle />

          <Toaster />
          <ReactQueryDevtools position="left" initialIsOpen={false} />
        </QueryClientProvider>
      </React.Suspense>
    </RootError>
  );
}

export default App;
