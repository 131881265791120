import { FeatureAnnouncements } from "~/shared/components/FeatureAnnoucements/FeatureAnnouncements";
import { NavMenu } from "~/shared/components/NavigationMenu";
import { PageActionsPortalProvider } from "~/shared/components/PageLayout";
import { SidebarNav } from "~/shared/components/PrivateAppLayout/components/SidebarNav";
import { CommandMenuProvider } from "~/shared/components/PrivateAppLayout/components/SiteHeader/CommandMenu";
import {
  MobileNavMenuPortalContainer,
  MobileNavMenuPortalProvider,
} from "~/shared/components/PrivateAppLayout/components/SiteHeader/MobileNav";
import {
  SiteHeader,
  SiteHeaderPortalProvider,
} from "~/shared/components/PrivateAppLayout/components/SiteHeader/SiteHeader";
import { SidebarNavProvider, useSidebarNavState } from "~/shared/components/PrivateAppLayout/context/SidebarNavContext";
import useMediaQuery from "~/shared/hooks/useMediaQuery";
import { useReactQuerySubscription } from "~/shared/hooks/useReactQuerySubscription";
import { useElementStore } from "~/shared/stores/element-store";
import { Impersonation, useImpersonationState } from "./components/Impersonation";

interface PrivateAppLayoutProps {
  children: React.ReactNode;
}
export function PrivateAppLayout({ children }: PrivateAppLayoutProps) {
  useReactQuerySubscription();

  return (
    <CommandMenuProvider>
      <MobileNavMenuPortalProvider>
        <SidebarNavProvider>
          <SiteHeaderPortalProvider>
            <PageActionsPortalProvider>
              <Layout>{children}</Layout>
            </PageActionsPortalProvider>
          </SiteHeaderPortalProvider>
        </SidebarNavProvider>
      </MobileNavMenuPortalProvider>
    </CommandMenuProvider>
  );
}

interface LayoutProps {
  children: React.ReactNode;
}
function Layout({ children }: LayoutProps) {
  const isTablet = useMediaQuery("(min-width: 768px)");
  const { isOpen } = useSidebarNavState() ?? {};
  const { value: impersonation } = useImpersonationState();
  const setElement = useElementStore((state) => state.setElement);

  return (
    <div
      className="bg-ds-bg-foundation relative h-full min-h-screen"
      style={
        {
          "--siteheader-position-y": impersonation ? "44px" : "0px",
          "--siteheader-height": isTablet ? "71px" : "58px",
          /** Sidebar width is always `0` on mobile. Otherwise respect the `isOpen` state value. */
          "--sidebar-width": isTablet ? (isOpen ? "268px" : "81px") : "0px",
        } as React.CSSProperties
      }
    >
      <aside className="pointer-events-auto inset-0 z-30 hidden w-[var(--sidebar-width)] md:fixed md:flex">
        <SidebarNav>
          <NavMenu isOpen={isOpen} />
        </SidebarNav>
      </aside>
      <Impersonation />
      <div className="sticky left-0 top-[var(--siteheader-position-y)] z-20 h-[var(--siteheader-height)] w-[calc(100%_-_var(--sidebar-width))] translate-x-[var(--sidebar-width)]">
        <SiteHeader />
      </div>

      <div className="pointer-events-none fixed left-0 top-[var(--siteheader-height)] z-20 h-full min-h-[calc(100dvh_-_var(--siteheader-height))] w-full md:hidden">
        {/** Mobile Nav Menu will be injected into the DOM here */}
        <MobileNavMenuPortalContainer />
      </div>
      <main className="text-ds-text-primary relative z-10 w-full pl-[var(--sidebar-width)]">{children}</main>
      <div
        className="pointer-events-none fixed inset-0 top-[var(--siteheader-height)] z-10 w-[calc(100%_-_var(--sidebar-width))] translate-x-[var(--sidebar-width)]"
        ref={(node) => {
          setElement({ name: "layoutSheetRef", element: node });
        }}
      />
      <FeatureAnnouncements />
    </div>
  );
}
