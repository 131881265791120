import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import { differenceInHours, format, parseISO } from "date-fns";
import { rem } from "polished";
import * as React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import apiService from "~/shared/api/api-service";
import { JOBS_KEYS, JOBS_QUERY_OPTIONS } from "~/shared/api/jobs";
import { PIPELINE_QUERIES } from "~/shared/api/pipeline";
import { H4 } from "~/shared/components/H";
import InlineLink from "~/shared/components/InlineLink";
import Pagination from "~/shared/components/Pagination";
import ResourceInfoBlock from "~/shared/components/ResourceInfoBlock";
import UnstyledButton from "~/shared/components/UnstyledButton";
import { toast } from "~/shared/components/ds/Toast/Toast";
import CheckInput from "~/shared/components/forms/CheckInput";
import Combo from "~/shared/components/forms/Combo";
import Table, { DataCell } from "~/shared/components/table";
import AddedByCell from "~/shared/components/table/AddedByCell";
import AvatarCell from "~/shared/components/table/AvatarCell";
import VisibleCell from "~/shared/components/table/VisibleCell";
import { gamePlanStatusOptions } from "~/shared/util/brainstormHelpers";
import { getOptionFromValue, rankOptions } from "~/shared/util/forms";
import { generatePdf } from "~/shared/util/pdfGenerator";
import { INTERVIEW_PATHS, JOB_PATHS } from "~/team/constants/paths.constants";
import { TableLoader, TableOverlay } from "../../../../shared/components/TableOverlay";
import { useDefaultTableSearchParams, useTableSearchParamsCallbacks } from "../../../../shared/util/tableHelpers";

const StatusWrap = styled.div`
  display: flex;
  align-content: center;
  height: 36px;
`;

const UpdateLabel = styled.span`
  display: block;
  font-size: ${rem(12)};
`;

const tableCols = [
  {
    name: "Name",
    selector: "participant",
    minWidth: "240px",
    cell: (row) => {
      const latestStatusUpdate = row.latestStatusUpdate ? parseISO(row.latestStatusUpdate) : undefined;

      return (
        <ResourceInfoBlock
          participant={row.participant}
          participantNameLinkInNewTab={false}
          updateLabel={
            latestStatusUpdate ? (
              <UpdateLabel
                style={{
                  marginTop: 3,
                  color: differenceInHours(new Date(), latestStatusUpdate) > 72 ? "var(--error-red)" : undefined,
                }}
              >
                Updated {format(latestStatusUpdate, "MM/dd/yy - h:mm a")}
              </UpdateLabel>
            ) : null
          }
        />
      );
    },
  },
  {
    name: "Status",
    selector: "row.latestStatus",
    sort: false,
    maxWidth: "180px",
    minWidth: "auto",
    cell: (row) => (
      <DataCell name="Status">
        <InlineLink href={row.interview ? INTERVIEW_PATHS.detail(row.interview?.id) : JOB_PATHS.gamePlanDetail(row.id)}>
          {row.latestStatus}
        </InlineLink>
      </DataCell>
    ),
  },
  {
    name: "Current Opps",
    selector: "row.profile",
    minWidth: "auto",
    maxWidth: "100px",
    cell: (row) => <DataCell name="Current Opportunities" value={row.participant.profile?.currentOpps || "0"} />,
  },
  {
    name: "Rank",
    selector: "rank",
    maxWidth: "80px",
    minWidth: "80px",
    cell: (row) => {
      let rank = getOptionFromValue(row.rank, rankOptions).label;

      if (rank) {
        rank = rank.replace("Rank ", "");
      }

      return <DataCell name="Rank" value={rank} />;
    },
  },
  {
    name: "EXO",
    selector: "row.addedBy",
    sort: false,
    maxWidth: "80px",
    minWidth: "80px",
    cell: (row) => <AvatarCell name="Manager" data={{ user: row?.participant?.exo }} />,
  },
  {
    name: "Added By",
    selector: "row.addedBy",
    sort: false,
    maxWidth: "102px",
    cell: (row) => <AddedByCell name="Added By" data={row} />,
  },
  {
    name: "Visible",
    selector: "visible",
    maxWidth: "80px",
    minWidth: "80px",
    cell: (row) => <VisibleCell name="Visible" value={row.visible} />,
  },
];

export const PARTICIPANTS_TABLE_SEARCH_PARAMS_KEY = "participantsTable";

export function GamePlanParticipants({ children }) {
  return (
    <section>
      <H4 as="h2" style={{ marginTop: "30px" }}>
        Participants In Game Plan
      </H4>
      {children}
    </section>
  );
}

/**
 * @param {function} onUpdate Function to call when a participant is updated (allows other components to react)
 * @param {object} added Just a signal that another component has added a user (allows this component to react (call
 *   mutate to get fresh data))
 * @returns
 */
const GamePlanParticipantsTable = () => {
  const { jobId } = useParams();
  const [selectedItems, setSelectedItems] = React.useState([]);
  const queryClient = useQueryClient();
  const [toggledClearRows, setToggledClearRows] = React.useState(false);
  const [status, setStatus] = React.useState(gamePlanStatusOptions[0]);
  const [searchParams, setSearchParams] = useSearchParams();
  const { queryParams, parsedSearchParams } = useDefaultTableSearchParams({
    key: PARTICIPANTS_TABLE_SEARCH_PARAMS_KEY,
    searchParams,
  });
  const deferredParams = React.useDeferredValue(queryParams);
  const isDeferred = JSON.stringify(queryParams) !== JSON.stringify(deferredParams);
  const { handlePageChange, handlePerRowsChange, handleSortChange } = useTableSearchParamsCallbacks({
    key: PARTICIPANTS_TABLE_SEARCH_PARAMS_KEY,
    setSearchParams,
  });

  const { data: job } = useSuspenseQuery(JOBS_QUERY_OPTIONS.detail(jobId));

  const { data: brainstorms, isFetching } = useSuspenseQuery(JOBS_QUERY_OPTIONS.brainstormsList(jobId, deferredParams));
  const isPending = isFetching || isDeferred;

  const { data: updatedList, isError: isUpdateError, setConfig: updateItems } = apiService.useWrite();

  React.useEffect(() => {
    if (typeof updatedList !== "undefined" && !isUpdateError) {
      setSelectedItems([]);
      setToggledClearRows(true);
      toast.success("Update Succeeded");

      queryClient.invalidateQueries({ queryKey: JOBS_KEYS.detail(jobId) });
      queryClient.invalidateQueries({ queryKey: PIPELINE_QUERIES.all() });
    }
    if (isUpdateError) {
      toast.error("Could not update participants");
    }
  }, [updatedList, isUpdateError, queryClient, jobId]);

  React.useEffect(() => {
    if (toggledClearRows) {
      setToggledClearRows(false);
    }
  }, [toggledClearRows]);

  const handleSelect = React.useCallback((state) => {
    setSelectedItems(state.selectedRows);
  }, []);

  const handleStatusChange = (selected) => {
    setStatus(selected);
  };

  const updateStatus = async () => {
    const participants = selectedItems.map((u) => u.participant.id);
    let path;
    let updateData;
    let op = "patch";

    if (status.value === "Remove") {
      path = `/jobs/${jobId}/brainstorm/delete`;
      updateData = { participants };
      op = "save";
    } else if (status.value === "Export") {
      await generatePdf(job.title, selectedItems);

      setSelectedItems([]);
      setToggledClearRows(true);
      return;
    } else if (status.value === "Visible") {
      path = `/jobs/${jobId}/brainstorm`;
      updateData = { visible: status.visible, participants };
    } else {
      path = `/jobs/${jobId}/brainstorm`;
      updateData = { status: status.value, participants };
    }

    updateItems({
      op,
      args: [path, updateData],
    });

    queryClient.invalidateQueries({ queryKey: JOBS_KEYS.brainstorms(jobId) });
  };

  return (
    <TableOverlay pending={isPending}>
      <Table
        columns={tableCols}
        data={brainstorms?.items}
        pagination={false}
        onSort={handleSortChange}
        selectableRows
        selectableRowDisabled={(row) => !!row.interview}
        selectableRowsComponent={CheckInput}
        selectableRowsComponentProps={{
          hideOnDisabled: true,
        }}
        onSelectedRowsChange={handleSelect}
        clearSelectedRows={toggledClearRows}
      />
      {!!selectedItems.length && (
        <StatusWrap>
          <Combo options={gamePlanStatusOptions} value={status} onChange={handleStatusChange} width={235} small />
          <UnstyledButton style={{ marginLeft: "30px", textDecoration: "underline" }} onClick={updateStatus}>
            {status.value !== "Remove" && status.value !== "Export" ? "Update" : status.value} {selectedItems.length}{" "}
            Selected User
            {selectedItems.length > 1 ? "s" : ""}
          </UnstyledButton>
        </StatusWrap>
      )}
      <Pagination
        rowCount={brainstorms?.total}
        currentPage={parsedSearchParams.page}
        rowsPerPage={parsedSearchParams.limit}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerRowsChange}
      />
      <TableLoader pending={isPending} />
    </TableOverlay>
  );
};

export default GamePlanParticipantsTable;
