import { QueryClient } from "@tanstack/react-query";
import { ApiError, ERROR_CODES } from "~/shared/lib/api-error";

// Initialize the query client once, and pass it to the router for use in actions and loaders.
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (count, error) => {
        if (error instanceof ApiError && (NO_RETRY_CASES as Array<string>).includes(error.details.code)) {
          return false;
        }

        // Gracefully retry if recoverable error
        return count < 3;
      },
      staleTime: 1000 * 60 * 3, // 3 minutes
    },
  },
});

const NO_RETRY_CASES = [
  "NOT_FOUND",
  "NETWORK_ERROR",
  "BAD_REQUEST",
  "RATE_LIMITED",
  "TEAPOT",
  "UNAUTHORIZED",
] satisfies Array<keyof typeof ERROR_CODES>;
