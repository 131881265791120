import * as React from "react";
import { mergeRefs } from "react-merge-refs";
import { twMerge } from "tailwind-merge";
import { Icon, IconUse } from "~/shared/components/ds/icons/Icon";
import { IconLoader } from "~/shared/components/icons/IconLoader/IconLoader";

interface FilterSearchInputProps extends Omit<React.ComponentPropsWithoutRef<"input">, "value"> {
  onClearInput?: () => void;
  loading?: boolean;
  value?: string;
}
export const FilterSearchInput = React.forwardRef<HTMLInputElement, FilterSearchInputProps>(
  ({ className, onClearInput, value, loading, ...rest }, ref) => {
    const localRef = React.useRef<HTMLInputElement>(null);
    const [isClearButtonVisible, setIsClearButtonVisible] = React.useState(false);
    const [previousValue, setPreviousValue] = React.useState(value);

    if (previousValue !== value) {
      setPreviousValue(value);
      // whenever the value changes, update the clear button visibility state
      setIsClearButtonVisible(Boolean(value));
    }

    return (
      <div className="border-ds-stroke-tertiary flex items-center border-b">
        <span aria-hidden className="text-ds-icon-secondary -mr-3 flex w-auto shrink-0 items-center px-3">
          {!loading ? (
            <Icon className="h-4 w-4">
              <IconUse id="search-line" />
            </Icon>
          ) : (
            <IconLoader animate className="text-secondary-green h-4 w-4" />
          )}
        </span>
        <input
          {...rest}
          ref={mergeRefs([ref, localRef])}
          value={value}
          type="search"
          className={twMerge(
            "font-primary text-ds-text-primary placeholder:text-ds-text-placeholder peer flex h-10 w-full border-none bg-transparent px-3 py-1 text-sm outline-none transition-colors focus:outline-none disabled:cursor-not-allowed",
            className,
          )}
        />
        {isClearButtonVisible && (
          <button
            type="button"
            aria-label="Clear search input"
            className={
              "text-ds-icon-secondary hover:text-ds-icon-primary flex items-center self-stretch border-none bg-transparent px-2 transition-colors"
            }
            onClick={() => {
              onClearInput?.();
              setIsClearButtonVisible(false);
              localRef?.current?.focus();
            }}
          >
            <Icon aria-hidden className="h-4 w-4">
              <IconUse id="close-circle-line" />
            </Icon>
          </button>
        )}
      </div>
    );
  },
);

FilterSearchInput.displayName = "FilterSearchInput";
