import * as React from "react";
import { DrawerTrigger } from "~/shared/components/ds/Drawer";
import { Icon, IconUse } from "~/shared/components/ds/icons/Icon";
import { PopoverTrigger } from "~/shared/components/ds/Popover";

export const MergeSelectTrigger = React.forwardRef<
  HTMLButtonElement,
  React.ComponentPropsWithoutRef<"button"> & {
    isAboveTablet: boolean;
  }
>(({ children, isAboveTablet, ...rest }, forwardedRef) => {
  const TriggerComponent = isAboveTablet ? PopoverTrigger : DrawerTrigger;

  return (
    <TriggerComponent
      {...rest}
      ref={forwardedRef}
      type="button"
      className="bg-ds-bg-weaker border-ds-stroke-tertiary active:text-ds-text-tertiary group/button hover:bg-ds-neutral-50 aria-[invalid=true]:border-ds-state-error aria-[invalid=true]:ring-ds-red-light flex w-full items-center justify-center rounded-b-lg border border-transparent p-2 transition-colors duration-300 aria-[invalid=true]:ring-2"
    >
      <span className="group-hover/button:bg-ds-neutral-200/50 flex gap-x-1.5 rounded px-1 py-0.5 pl-2 transition-colors duration-300">
        <span className="text-xs">{children}</span>
        <Icon className="grid size-4 shrink-0 place-items-center">
          <IconUse id="expand-up-down-line" />
        </Icon>
      </span>
    </TriggerComponent>
  );
});
