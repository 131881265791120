import * as DialogPrimitive from "@radix-ui/react-dialog";
import * as React from "react";
import { twMerge } from "tailwind-merge";
import { Icon, IconUse } from "~/shared/components/ds/icons/Icon";
import { focusRingStyles } from "~/shared/styles/focus";
import { heading } from "~/shared/styles/heading";
import { copy } from "~/shared/styles/text";

export const Dialog = DialogPrimitive.Root;

export const DialogTrigger = DialogPrimitive.Trigger;

export const DialogPortal = DialogPrimitive.Portal;

export const DialogClose = DialogPrimitive.Close;

export const DialogOverlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...rest }, ref) => (
  <DialogPrimitive.Overlay
    ref={ref}
    className={twMerge(
      "bg-ds-neutral-900/80 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 fixed bottom-0 left-0 right-0 top-0 z-50 grid place-items-center overflow-y-auto",
      className,
    )}
    {...rest}
  />
));
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;

export const DialogContentLayout = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content>
>(({ children, ...rest }, ref) => (
  <DialogPortal>
    <DialogOverlay>
      <DialogContent ref={ref} {...rest}>
        {children}
        <DialogPrimitive.Close
          className={focusRingStyles({
            className:
              "absolute right-4 top-4 rounded-sm opacity-70 transition-opacity hover:opacity-100 disabled:pointer-events-none",
          })}
        >
          <Icon className="h-4 w-4" aria-hidden>
            <IconUse id="close-line" />
          </Icon>
          <span className="sr-only">Close</span>
        </DialogPrimitive.Close>
      </DialogContent>
    </DialogOverlay>
  </DialogPortal>
));
DialogContentLayout.displayName = "DialogContentLayout";

export const DialogContent = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content>
>(({ children, className, ...rest }, ref) => {
  return (
    <DialogPrimitive.Content
      ref={ref}
      className={twMerge(
        "bg-ds-bg-foundation data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-bottom-[5%] data-[state=open]:slide-in-from-bottom-[5%] relative z-50 grid w-full max-w-lg gap-4 border p-6 shadow-lg duration-200 sm:rounded-lg",
        className,
      )}
      {...rest}
    >
      {children}
    </DialogPrimitive.Content>
  );
});
DialogContent.displayName = DialogPrimitive.Content.displayName;

export function DialogHeader({ className, ...rest }: React.HTMLAttributes<HTMLDivElement>) {
  return <div className={twMerge("flex flex-col gap-2 text-center sm:text-left", className)} {...rest} />;
}

export function DialogFooter({ className, ...rest }: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={twMerge("flex flex-col-reverse gap-2 sm:flex-row sm:items-center sm:justify-end", className)}
      {...rest}
    />
  );
}

export const DialogTitle = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...rest }, ref) => {
  return <DialogPrimitive.Title ref={ref} className={heading({ variant: "20", className })} {...rest} />;
});
DialogTitle.displayName = DialogPrimitive.Title.displayName;

export const DialogDescription = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ className, ...rest }, ref) => {
  return (
    <DialogPrimitive.Description
      ref={ref}
      className={copy({
        variant: "14",
        color: "secondary",
        className,
      })}
      {...rest}
    />
  );
});
DialogDescription.displayName = DialogPrimitive.Description.displayName;
