import { rem } from "polished";
import PropTypes from "prop-types";
import styled from "styled-components";
import InlineLink from "~/shared/components/InlineLink";
import Tooltip from "./Tooltip";

const Content = styled.div`
  max-width: 320px;
  min-width: 200px;

  span,
  a {
    font-size: ${rem(14)};
  }
`;

const TooltipContent = ({ user }) => {
  if (!user) return;

  return (
    <Content>
      <strong>
        {user.firstName} {user.lastName}
      </strong>
      <br />
      {user.phone !== "" ? (
        <>
          <InlineLink href={`tel:${user.phone}`}>{user.phone}</InlineLink>
          <br />
        </>
      ) : null}
      {user.email ? <InlineLink href={`mailto:${user.email}`}>{user.email}</InlineLink> : null}
    </Content>
  );
};

const ParticipantTooltip = ({ children, user }) => (
  <Tooltip interactive={true} content={<TooltipContent user={user} />}>
    {children}
  </Tooltip>
);

ParticipantTooltip.defaultProps = {
  children: PropTypes.node,
  user: PropTypes.object.isRequired,
};

export default ParticipantTooltip;
