import { FilterGroupState } from "~/shared/components/FiltersV2/types/filters";

/**
 * Maps the filter group state to a record of filter values.
 *
 * @param filters - The filter group state object.
 * @returns A record of filter values.
 */
export function mapFilterGroupStateToRecord(filters: FilterGroupState) {
  return Object.keys(filters).reduce(
    (acc, key) => {
      const currentFilter = filters[key];
      if (!currentFilter || !Array.isArray(currentFilter)) return acc;

      acc[key] = currentFilter?.map((item) => ({
        value: String(item.id),
        name: item.name,
      }));

      return acc;
    },
    {} as Record<
      string,
      {
        value: string;
        name: string;
      }[]
    >,
  );
}
