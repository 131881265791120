import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useUpdateSavedRole } from "~/participant/hooks/useUpdateSavedRole";
import { Form, FormControl, FormField } from "~/shared/components/ds/Form";
import { Textarea } from "~/shared/components/ds/Textarea";

export function EditSavedRoleNotesForm({
  savedRoleId,
  notes,
  children,
  onSuccessfulSubmit,
  onFailedSubmit,
}: {
  savedRoleId: number;
  notes: string;
  children?: ((isSubmitting: boolean) => React.ReactNode) | React.ReactNode;
  onSuccessfulSubmit?: (data: EditSavedRoleNotesFormOutput) => void;
  onFailedSubmit?: () => void;
}) {
  const mutation = useUpdateSavedRole(savedRoleId);
  const form = useForm<EditSavedRoleNotesFormInput>({
    mode: "onSubmit",
    resolver: zodResolver(EditSavedRoleNotesFormSchema),
    defaultValues: {
      notes: notes ?? "",
    },
  });
  const { control, handleSubmit } = form;

  return (
    <Form {...form}>
      <form
        onSubmit={handleSubmit((data) => {
          mutation.mutate(
            { notes: data.notes },
            {
              onSuccess: () => {
                onSuccessfulSubmit?.(data);
              },
              onError: () => {
                onFailedSubmit?.();
              },
            },
          );
        })}
        id={EDIT_SAVED_ROLE_NOTES_FORM_ID}
      >
        <FormField
          control={control}
          name="notes"
          render={({ field }) => {
            return (
              <FormControl>
                <div
                  className="grow-wrap after:px-3 after:py-2 after:content-[attr(data-content)_'_']"
                  data-content={field.value}
                >
                  <FormControl>
                    <Textarea className="min-h-[200px]" {...field} placeholder="Add notes..." />
                  </FormControl>
                </div>
              </FormControl>
            );
          }}
        />
      </form>
      {typeof children === "function" ? children(mutation.isPending) : children}
    </Form>
  );
}
export const EDIT_SAVED_ROLE_NOTES_FORM_ID = "edit-saved-internal-notes-form-id";

const EditSavedRoleNotesFormSchema = z.object({
  notes: z.string().optional(),
});
type EditSavedRoleNotesFormInput = z.input<typeof EditSavedRoleNotesFormSchema>;
type EditSavedRoleNotesFormOutput = z.output<typeof EditSavedRoleNotesFormSchema>;
