import { queryOptions } from "@tanstack/react-query";
import api from "~/shared/api/api";
import { Params } from "~/shared/api/types/params";
import { PaginatedResponse } from "~/shared/types/pagination.types";
import { createInfiniteQuery } from "~/shared/util/createInfiniteQuery";
import { Stage } from "~/team/types/jobs.types";

export const PIPELINE_QUERIES = {
  all: () => ["pipeline"] as const,
  roles: () => [...PIPELINE_QUERIES.all(), "roles"] as const,
  rolesList: (params: Params) => {
    return queryOptions({
      queryKey: [...PIPELINE_QUERIES.roles(), params] as const,
      queryFn: async ({ signal }) => {
        const response = await api.get<PaginatedResponse<Role>>("/pipeline/roles", {
          ...params,
          signal,
        });

        return response.data;
      },
    });
  },
  rolesStatuses: () => {
    return queryOptions({
      queryKey: [...PIPELINE_QUERIES.roles(), "statuses"] as const,
      queryFn: async ({ signal }) => {
        const response = await api.get<PaginatedResponse<PipelineStatus>>("/job-pipeline-statuses", {
          signal,
        });

        return response.data;
      },
    });
  },
  interviews: () => [...PIPELINE_QUERIES.all(), "interviews"] as const,
  interviewsList: (params: Params) => {
    return queryOptions({
      queryKey: [...PIPELINE_QUERIES.interviews(), params] as const,
      queryFn: async ({ signal }) => {
        const response = await api.get<PaginatedResponse<Interview>>("/pipeline/interviews", {
          ...params,
          signal,
        });

        return response.data;
      },
    });
  },
};

interface Partner {
  id: string;
  name: string;
  logo: {
    location: string;
  };
  type: {
    id: number;
    name: string;
  };
  csm: User;
}
interface User {
  id: number;
  firstName: string;
  lastName: string;
  headshot?: {
    location?: string;
  };
}

export interface PipelineStatus {
  id: number;
  name: string;
}
export interface Role {
  id: number;
  activeInterviews: number;
  verified: boolean;
  title: string;
  weightedRevenue?: number;
  partner?: Partner;
  recruiter?: User;
  recruiters?: Array<User>;
  hiringManager: User;
  latestStage: string;
  nextInterviewDate: string;
  lastInterviewDate: string;
  activeTasks: number;
  pipelineStatus?: PipelineStatus | null;
}

interface Participant extends User {
  exo: User;
}
export interface Interview {
  id: number;
  participant?: Participant;
  partner?: Partner;
  job?: Role;
  latestStage: Stage;
  averageAlignmentScore: number;
  activeTasks: number;
  nextInterviewDate: string;
  status?: InterviewStatus;
}

export type InterviewStatus = "Interview Scheduled" | "Scheduling Interview" | "Waiting For Feedback";

export function useInfinitePipelineStatusFilter(params: Record<string, unknown>) {
  return createInfiniteQuery({
    fetchFn: async (params, { signal }) => {
      const response = await api.get<PaginatedResponse<PipelineStatus>>("/job-pipeline-statuses", {
        ...params,
        signal,
      });

      return response.data;
    },
    queryKeyFn: (params: Params) => {
      return [...PIPELINE_QUERIES.rolesStatuses().queryKey, params] as const;
    },
  })({
    params: {
      ...params,
    },
  });
}
