import * as React from "react";
import { z } from "zod";
import { Badge } from "~/shared/components/ds/Badge";
import { Banner, BannerCloseAction } from "~/shared/components/ds/Banner";
import { Icon, IconUse } from "~/shared/components/ds/icons/Icon";
import { useLocalStorage } from "~/shared/hooks/useLocalStorage";

export function Impersonation() {
  const { value, handlers } = useImpersonationState();
  const { remove } = handlers;

  if (value == null) return null;

  return (
    <Banner className="border-ds-primary-base sticky left-[var(--sidebar-width)] top-0 z-30 h-[var(--siteheader-position-y)] w-[calc(100%-var(--sidebar-width))]">
      <span className="flex items-center gap-[1ch]">
        <Badge
          className="absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-full rounded-t-none"
          variant="solid"
          colorScheme="primary"
          icon={
            <Icon>
              <IconUse id="id-card-fill" />
            </Icon>
          }
        >
          Impersonating
        </Badge>
        {TYPE_TO_TITLE[value.type]} &mdash; {value.firstName} {value.lastName}
        <BannerCloseAction onClick={remove} />
      </span>
    </Banner>
  );
}

const TYPE_TO_TITLE = {
  recruiter: "Recruiter",
  participant: "Participant",
} satisfies Record<z.infer<typeof ImpersonationSchema>["type"], string>;

export function useImpersonationState() {
  const [value, setValue, removeValue] = useLocalStorage(IMPERSONATION_KEY, null, {
    deserializer: (value) => {
      return ImpersonationSchema.parse(JSON.parse(value));
    },
  });

  const handlers = React.useMemo(() => {
    return {
      setValue: (value: z.infer<typeof ImpersonationSchema>) => {
        setValue(value);
        window.location.replace("/");
      },
      remove: () => {
        removeValue();
        window.location.replace("/");
      },
    };
  }, [removeValue, setValue]);

  return React.useMemo(() => {
    return {
      value,
      handlers,
    };
  }, [handlers, value]);
}

const ImpersonationSchema = z.object({
  id: z.coerce.number(),
  type: z.union([z.literal("recruiter"), z.literal("participant")]),
  firstName: z.string(),
  lastName: z.string(),
  username: z.string(),
});

export const IMPERSONATION_KEY = "BL_MD" as const;

export function getImpersonationState() {
  try {
    const storedValue = window.localStorage.getItem(IMPERSONATION_KEY);
    if (!storedValue) return null;

    return ImpersonationSchema.parse(JSON.parse(storedValue));
  } catch (error) {
    // If parsing fails, return null
    return null;
  }
}
