import { QueryClient } from "@tanstack/react-query";
import { RouteObject } from "react-router-dom";
import { TopLevelRouteErrorHandler } from "~/shared/components/Error";
import { lazyRetry } from "~/shared/util/lazyRetry";
import { loginLoader } from "~/shared/views/LoginView";
import { twoFactorAuthLoader } from "~/shared/views/TwoFactorAuthView";

export function getSharedRoutes(queryClient: QueryClient): Array<RouteObject> {
  return [
    // RESET PASSWORD
    {
      path: "reset-password",
      errorElement: <TopLevelRouteErrorHandler />,
      lazy: async () => {
        const { ResetPasswordRequestView } = await lazyRetry(
          () =>
            import(
              /* webpackChunkName: "ResetPasswordRequestView" */
              "~/shared/views/ResetPasswordRequestView"
            ),
          "ResetPasswordRequestView",
        );
        return {
          Component: ResetPasswordRequestView,
        };
      },
    },
    {
      path: "reset-password/:resetToken",
      errorElement: <TopLevelRouteErrorHandler />,
      lazy: async () => {
        const { ResetPasswordView } = await lazyRetry(
          () =>
            import(
              /* webpackChunkName: "ResetPasswordView" */
              "~/shared/views/ResetPasswordView"
            ),
          "ResetPasswordView",
        );
        return {
          Component: ResetPasswordView,
        };
      },
    },
    // SETUP
    {
      path: "setup/:resetToken",
      errorElement: <TopLevelRouteErrorHandler />,
      lazy: async () => {
        const { SetupView } = await lazyRetry(
          () =>
            import(
              /* webpackChunkName: "SetupView" */
              "~/shared/views/SetupView"
            ),
          "SetupView",
        );
        return {
          Component: SetupView,
        };
      },
    },

    // LOGIN
    {
      path: "login",
      loader: () => {
        return loginLoader(queryClient);
      },
      errorElement: <TopLevelRouteErrorHandler />,
      lazy: async () => {
        const { LoginView } = await lazyRetry(
          () =>
            import(
              /* webpackChunkName: "LoginView" */
              "~/shared/views/LoginView"
            ),
          "LoginView",
        );
        return {
          Component: LoginView,
        };
      },
    },

    // 2FA
    {
      path: "2fa",
      loader: (args) => {
        return twoFactorAuthLoader(args, queryClient);
      },
      errorElement: <TopLevelRouteErrorHandler />,
      lazy: async () => {
        const { TwoFactorAuthView } = await lazyRetry(
          () =>
            import(
              /* webpackChunkName: "TwoFactorAuthView" */
              "~/shared/views/TwoFactorAuthView"
            ),
          "TwoFactorAuthView",
        );
        return {
          Component: TwoFactorAuthView,
        };
      },
    },
  ];
}
