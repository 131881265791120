export const SAVED_ROLES_EVENTS = {
  SAVE_ROLE_CREATE_INITIATED: createSavedRoleEventKey("create_initiated"),
  SAVE_ROLE_DELETE_INITIATED: createSavedRoleEventKey("delete_initiated"),
} as const;

function createSavedRoleEventKey<const TKey extends string>(eventKey: TKey) {
  return `saved_role:${eventKey}` as const;
}

export const GAME_PLAN_EVENTS = {
  PS_ADD_TO_GAME_PLAN_SUCCESS: (payload: {
    jobId: number;
    gamePlanIds: Array<string>;
    flow: "direct" | "selected";
  }) => ({
    name: "ps_game_plan:add_success",
    payload,
  }),
} as const;
