import * as React from "react";
import { SetURLSearchParams, useSearchParams } from "react-router-dom";
import { stringify } from "~/shared/util/searchParams.helpers";

interface ComponentSearchParamsProps {
  paramKey: string;
  children: React.ReactNode;
  initialSearchParams?: Record<any, any>;
}
export function ComponentSearchParamsProvider({
  paramKey,
  children,
  initialSearchParams = {},
}: ComponentSearchParamsProps) {
  const params = useSearchParams({
    [paramKey]: stringify(initialSearchParams),
  });

  const value = React.useMemo(() => {
    return {
      paramKey,
      params,
    };
  }, [paramKey, params]);

  return <ComponentSearchParamsContext.Provider value={value}>{children}</ComponentSearchParamsContext.Provider>;
}

export function useComponentSearchParams() {
  const context = React.useContext(ComponentSearchParamsContext);
  if (context === null) {
    throw new Error("useComponentSearchParams must be used within a ComponentSearchParamsProvider");
  }
  return context;
}

const ComponentSearchParamsContext = React.createContext<{
  paramKey: string;
  params: [URLSearchParams, SetURLSearchParams];
} | null>(null);
