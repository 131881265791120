import { Slot, Slottable } from "@radix-ui/react-slot";
import * as React from "react";
import { tv } from "tailwind-variants";
import { Icon, IconUse } from "~/shared/components/ds/icons/Icon";

interface BannerProps extends React.ComponentPropsWithoutRef<"div"> {}
export const Banner = React.forwardRef<HTMLDivElement, BannerProps>(({ className, ...rest }, forwardedRef) => {
  const { container } = bannerStyles();

  return <div className={container({ className })} ref={forwardedRef} {...rest} />;
});
Banner.displayName = "Banner";

interface BannerInlineActionProps extends React.ComponentPropsWithoutRef<"button"> {
  asChild?: boolean;
}
export const BannerInlineAction = React.forwardRef<React.ElementRef<"button">, BannerInlineActionProps>(
  ({ asChild, className, ...rest }, forwardedRef) => {
    const Component = asChild ? Slot : "button";
    const { inlineAction } = bannerStyles();

    return <Component {...rest} className={inlineAction({ className })} ref={forwardedRef} />;
  },
);
BannerInlineAction.displayName = "BannerInlineAction";

interface BannerCloseActionProps extends React.ComponentPropsWithoutRef<"button"> {
  asChild?: boolean;
}
export const BannerCloseAction = React.forwardRef<React.ElementRef<"button">, BannerCloseActionProps>(
  ({ asChild, className, children, ...rest }, forwardedRef) => {
    const Component = asChild ? Slot : "button";
    const { closeAction } = bannerStyles();
    const ariaLabel = rest["aria-label"] ?? "Close banner";

    return (
      <Component {...rest} aria-label={ariaLabel} className={closeAction({ className })} ref={forwardedRef}>
        <Slottable>{children}</Slottable>
        <Icon aria-hidden>
          <IconUse id="close-line" />
        </Icon>
      </Component>
    );
  },
);
BannerCloseAction.displayName = "BannerCloseAction";

export const bannerStyles = tv({
  slots: {
    container: "relative font-normal text-sm w-full h-auto min-h-[44px] flex items-center gap-2 justify-center px-4",
    inlineAction: "font-medium p-0 m-0 underline underline-offset-2 [text-decoration-skip-ink:auto;] transition-colors",
    closeAction:
      "h-6 w-6 [&_svg]:w-5 [&_svg]:h-5 transition-colors absolute right-4 grid place-items-center text-ds-neutral-900/50 inset-y-center rounded bg-transparent hover:text-ds-neutral-900",
  },
  variants: {
    variant: {
      primary: {
        container: "bg-ds-primary-lighter text-ds-primary-dark border-y border-ds-primary-darker",
        inlineAction: "text-ds-primary-darker decoration-ds-primary-base hover:decoration-inherit hover:text-inherit",
      },
    },
  },
  defaultVariants: {
    variant: "primary",
  },
});
