import * as SwitchPrimitives from "@radix-ui/react-switch";
import * as React from "react";
import { twMerge } from "tailwind-merge";
import { focusRingStyles } from "~/shared/styles/focus";

const Switch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root>
>(({ className, ...rest }, ref) => {
  return (
    <SwitchPrimitives.Root
      className={focusRingStyles({
        className: twMerge(
          "border-ds-stroke-tertiary data-[state=checked]:border-ds-primary-base data-[state=checked]:bg-ds-primary-base data-[state=unchecked]:bg-ds-bg-soft peer inline-flex h-5 w-9 shrink-0 cursor-pointer items-center rounded-full border-2 shadow-sm transition-colors disabled:cursor-not-allowed disabled:opacity-50",
          className,
        ),
      })}
      {...rest}
      ref={ref}
    >
      <SwitchPrimitives.Thumb
        className={twMerge(
          "bg-ds-bg-foundation pointer-events-none block h-4 w-4 rounded-full shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-4 data-[state=unchecked]:translate-x-0",
        )}
      />
    </SwitchPrimitives.Root>
  );
});
Switch.displayName = SwitchPrimitives.Root.displayName;

export { Switch };
