import PropTypes from "prop-types";
import { useState } from "react";
import ReactModal from "react-modal";
import styled from "styled-components";
import screen from "superior-mq";
import create from "zustand";
import { useBodyScrollLock } from "~/shared/hooks/useBodyScrollLock";
import { bp } from "~/shared/styles/helpers";

export const useModal = () => {
  const initialState = {
    isOpen: false,
    data: {},
  };
  const [modal, setModal] = useState(initialState);

  /**
   * @param {string | boolean} name
   * @param {object} data
   */
  const toggleModal = (name = false, data = {}) => {
    if (!name) {
      setModal(initialState);
    } else {
      setModal((prevState) => (!!prevState.isOpen ? initialState : { isOpen: name, data: data }));
    }
  };

  return {
    modalOpen: modal.isOpen,
    modalData: modal.data,
    toggleModal,
  };
};

export const useGlobalModal = create((set) => ({
  modalOpen: false,
  toggleModal: (name = false) => {
    if (!name) return set({ modalOpen: false });

    return set((state) => ({
      modalOpen: state.modalOpen === name ? false : name,
    }));
  },
}));

/** Modal component */
const GullsModal = ({ isOpen, onRequestClose = () => {}, className, appElement = "#root", large, ...props }) => {
  ReactModal.setAppElement(appElement);
  const modalRef = useBodyScrollLock(!!isOpen);

  return (
    <>
      <ReactModal
        isOpen={!!isOpen}
        onRequestClose={onRequestClose}
        overlayClassName="Overlay"
        className="Modal"
        portalClassName={className}
        ref={modalRef}
        {...props}
      />
    </>
  );
};

const Modal = styled(GullsModal)`
  .Overlay {
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 48px 0;

    ${screen.below(
      bp.desktopSm,
      `
      padding: 0 60px;
    `,
    )}

    ${screen.below(
      bp.mobileSm,
      `
      padding: 16px;
    `,
    )}
  }

  .Modal {
    position: relative;
    width: 100%;
    max-width: ${(props) => (props.large ? 896 : 540)}px;
    border: 0;
    outline: none;
    background-color: var(--white);
    padding: 40px 40px 40px 28px;
    border-left: 12px solid ${(props) => (props.error ? "var(--error-red-dark)" : "var(--ultrabright-green)")};
    border-radius: 6px;

    ${screen.below(
      bp.mobileSm,
      `
      padding: 16px;
      border: 0;
    `,
    )}
  }
`;

Modal.propTypes = {
  isOpen: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  onRequestClose: PropTypes.func,
  className: PropTypes.string,
  appElement: PropTypes.string,
};

export default Modal;
