import { fetchAuthSession } from "aws-amplify/auth";
import { isAccessTokenExpired } from "~/shared/api/api";
import { queryClient } from "~/shared/api/query-client";
import { USER_OPTIONS } from "~/shared/api/user/user";

export async function authenticationTokenRefresh() {
  try {
    const { tokens } = await fetchAuthSession();

    const { accessToken } = tokens ?? {};
    const { payload } = accessToken ?? {};

    if (!payload) {
      throw new Error("No payload in access token");
    }

    if (isAccessTokenExpired(payload.exp)) {
      const { tokens } = await fetchAuthSession({ forceRefresh: true });
      queryClient.invalidateQueries({ queryKey: USER_OPTIONS.user().queryKey });

      // Return the new token
      return tokens?.accessToken.toString();
    }

    // Return the existing token
    return tokens?.accessToken.toString();
  } catch (_err) {
    // If the user is not authenticated, return null
    queryClient.removeQueries({ queryKey: USER_OPTIONS.user().queryKey });
    return null;
  }
}
