import { InfiniteData } from "@tanstack/react-query";
import { createInfiniteDataTransform } from "~/shared/util/createInfiniteDataTransform";

export function addFullNameToDataItems<TData extends { id: number; firstName: string; lastName: string }>(
  data: InfiniteData<{ items: Array<TData> }, number>,
) {
  return createInfiniteDataTransform(data, (item) => {
    return {
      ...item,
      name: `${item.firstName} ${item.lastName}`,
    };
  });
}

export function addFullName<TData extends Array<{ id: number; firstName: string; lastName: string }>>(
  data: TData | undefined,
) {
  if (!data) return [];

  return data?.map((item) => {
    return {
      ...item,
      name: `${item.firstName} ${item.lastName}`,
    };
  });
}
