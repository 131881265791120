import * as React from "react";
import * as ReactDOM from "react-dom";
import { twMerge } from "tailwind-merge";
import { heading } from "~/shared/styles/heading";
import { copy } from "~/shared/styles/text";

export function PageHeader({ children, className, ...rest }: React.ComponentPropsWithoutRef<"div">) {
  const [, setPageActionsPortal] = React.useContext(PageActionsPortalContext) ?? [];

  return (
    <div className={twMerge("flex flex-wrap justify-between gap-5 pb-12", className)} {...rest}>
      {children}
      <div id="page-actions-portal" className="empty:hidden" ref={setPageActionsPortal} />
    </div>
  );
}

export function PageHeading({ children, className, ...rest }: React.HTMLAttributes<HTMLHeadingElement>) {
  return (
    <h1 className={twMerge(pageHeadingStyles, className)} {...rest}>
      {children}
    </h1>
  );
}

const pageHeadingStyles = heading({
  variant: { initial: "30", md: "36" },
});

export function PageDescription({ children, className, ...rest }: React.HTMLAttributes<HTMLParagraphElement>) {
  return (
    <p
      className={copy({
        variant: "16",
        color: "secondary",
        className,
      })}
      {...rest}
    >
      {children}
    </p>
  );
}

export function PageDetails({ children, className, ...rest }: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={twMerge("flex flex-col gap-3", className)} {...rest}>
      {children}
    </div>
  );
}

const PageActionsPortalContext = React.createContext<
  [HTMLDivElement | null, React.Dispatch<React.SetStateAction<HTMLDivElement | null>>] | null
>(null);

interface PageActionsPortalProviderProps {
  children: React.ReactNode;
}
export function PageActionsPortalProvider({ children }: PageActionsPortalProviderProps) {
  const pageActionsPortalState = React.useState<HTMLDivElement | null>(null);

  return (
    <PageActionsPortalContext.Provider value={pageActionsPortalState}>{children}</PageActionsPortalContext.Provider>
  );
}

interface PageActionsProps {
  children: React.ReactNode;
}
export function PageActions({ children }: PageActionsProps) {
  const [portalContentElement] = React.useContext(PageActionsPortalContext) ?? [];

  return portalContentElement ? ReactDOM.createPortal(children, portalContentElement) : null;
}
