import * as PopoverPrimitive from "@radix-ui/react-popover";
import * as React from "react";
import { twMerge } from "tailwind-merge";
import { VariantProps, tv } from "tailwind-variants";
import { Icon, IconUse } from "~/shared/components/ds/icons/Icon";
import { focusRingStyles } from "~/shared/styles/focus";

interface FilterPopoverTriggerProps
  extends React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Trigger>,
    VariantProps<typeof trigger> {
  onClear?: () => void;
}
export const FilterPopoverTrigger = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Trigger>,
  FilterPopoverTriggerProps
>(({ className, onClear, status, children, ...rest }, ref) => {
  const { container } = trigger({ status });

  return (
    <div className="relative inline-block max-w-full">
      <PopoverPrimitive.Trigger asChild>
        <button {...rest} ref={ref} className={container({ className })}>
          <Icon className={twMerge("h-4 w-4 place-items-center", status === "active" ? "hidden" : "grid")} aria-hidden>
            <IconUse id="add-circle-line" />
          </Icon>
          <span className="stack-x-2 items-center">{children}</span>
        </button>
      </PopoverPrimitive.Trigger>
      <button
        aria-label="Clear filters"
        onClick={() => {
          onClear?.();
        }}
        className={focusRingStyles({
          className: twMerge(
            "text-ds-text-primary inset-y-center hover:bg-ds-primary-dark hover:text-ds-neutral-0 absolute right-2 grid h-5 w-5 place-items-center rounded-md border-none bg-transparent p-0 outline-none transition-colors",
            status === "active" ? "grid" : "hidden",
          ),
        })}
      >
        <Icon aria-hidden className="h-4 w-4">
          <IconUse id="close-line" />
        </Icon>
      </button>
    </div>
  );
});
FilterPopoverTrigger.displayName = "FilterPopoverTrigger";

const trigger = tv({
  slots: {
    container: focusRingStyles({
      className:
        "text-sm border rounded-md h-[32px] px-3 py-1.5 transition-colors stack-x-1 items-center disabled:cursor-not-allowed disabled:opacity-60 whitespace-nowrap",
    }),
  },
  variants: {
    status: {
      active: {
        container:
          "bg-ds-primary-lighter text-ds-text-primary border-transparent pr-[calc((theme(spacing.2)_*_2)_+_theme(spacing.5))] font-medium",
      },
      inactive: {
        container: "bg-ds-bg-weaker border-dashed border-ds-stroke-secondary",
      },
    },
  },
  defaultVariants: {
    status: "inactive",
  },
});
