import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import * as React from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { z } from "zod";
import { UTAH_PROGRAM_AVAILABLE_STEPS, UTAH_PROGRAM_ID } from "~/participant/constants/utah.constants";
import {
  checkboxStyles,
  errorMessageStyles,
  formItemStyles,
  formLabelStyles,
  formRowStyles,
  inputStyles,
  pageTitle,
  selectionGroupStyles,
} from "~/participant/styles/utah.styles";
import {
  NextStepButton,
  ProgressButtonContainer,
  ProgressButtonContent,
  progressButtonVariants,
} from "~/participant/views/utah-onboarding/components/Actions";
import {
  SplitLayoutLeft,
  SplitLayoutLeftContent,
  SplitLayoutLeftFooter,
  useScrollSplitToTop,
} from "~/participant/views/utah-onboarding/components/SplitLayout";
import { StepLayoutPartText, stepLayoutStyles } from "~/participant/views/utah-onboarding/components/StepLayout";
import { StepProgressLayout, useStepProgress } from "~/participant/views/utah-onboarding/components/StepProgress";
import api from "~/shared/api/api";
import { USER_OPTIONS } from "~/shared/api/user/user";
import { Checkbox } from "~/shared/components/ds/Checkbox";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/shared/components/ds/Form";
import { Input } from "~/shared/components/ds/Input";
import { toast } from "~/shared/components/ds/Toast/Toast";

export function utahOnboardingBasicInfoLoader() {
  return null;
}
export function UtahOnboardingBasicInfoView() {
  const { dispatch: stepProgressDispatch } = useStepProgress();
  const [part, setPart] = React.useState(1);

  React.useEffect(() => {
    stepProgressDispatch({
      type: "UPDATE_STEP",
      value: UTAH_PROGRAM_AVAILABLE_STEPS["basic-info"].step,
    });
  }, [stepProgressDispatch]);
  const { header, content, description } = stepLayoutStyles();

  return (
    <>
      <div className="flex h-full w-full flex-col self-start">
        <StepProgressLayout />
        <header className={header()}>
          <h1 className={pageTitle}>Basic Info</h1>
          <StepLayoutPartText part={part} total={2} />
        </header>
        <div className={content()}>
          <p className={description()}>
            The details and context you share here will help our team represent and advocate for you with employers.
          </p>
          {(() => {
            switch (part) {
              case 1:
                return <BasicInfoFormOne setPart={setPart} />;
              case 2:
                return <BasicInfoFormTwo setPart={setPart} />;
              default:
                return null;
            }
          })()}
        </div>
      </div>
    </>
  );
}

function BasicInfoFormOne({ setPart }: { setPart: React.Dispatch<React.SetStateAction<number>> }) {
  useScrollSplitToTop();
  const queryClient = useQueryClient();
  const { data: user } = useSuspenseQuery(USER_OPTIONS.user());
  const { dispatch: stepProgressDispatch } = useStepProgress();
  const basicInfoPartOneMutation = useMutation({
    mutationFn: async (data: BasicInfoFormOneOutput) => {
      await api.post(`/participants/${user.id}/onboarding`, {
        programId: UTAH_PROGRAM_ID,
        step: UTAH_PROGRAM_AVAILABLE_STEPS["basic-info"].id,
        part: 1,
        payload: data,
      });
    },
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: USER_OPTIONS.user().queryKey,
      });
    },
  });
  const form = useForm<BasicInfoFormOneInput>({
    mode: "onSubmit",
    resolver: zodResolver(BasicInfoFormOneSchema),
    defaultValues: {
      firstName: user?.firstName ?? "",
      lastName: user?.lastName ?? "",
      phone: user?.phone ?? "",
      linkedin: user?.profile?.linkedin ?? "",
    },
  });
  const { control, handleSubmit } = form;
  return (
    <Form {...form}>
      <form
        onSubmit={handleSubmit((data) => {
          basicInfoPartOneMutation.mutate(data, {
            onSuccess: () => {
              setPart(2);
              stepProgressDispatch({ type: "UPDATE_PROGRESS", value: 50 });
            },
            onError: () => {
              toast.error("Failed to complete basic info part one");
            },
          });
        })}
        className="h-full"
      >
        <SplitLayoutLeft>
          <SplitLayoutLeftContent>
            <div className={formRowStyles}>
              <FormField
                control={control}
                name="firstName"
                render={({ field }) => {
                  return (
                    <FormItem className={formItemStyles}>
                      <FormLabel className={formLabelStyles}>First Name</FormLabel>
                      <FormControl>
                        <Input {...field} placeholder="Jane" type="text" className={inputStyles} />
                      </FormControl>
                      <FormMessage className={errorMessageStyles} />
                    </FormItem>
                  );
                }}
              />
              <FormField
                control={control}
                name="lastName"
                render={({ field }) => {
                  return (
                    <FormItem className={formItemStyles}>
                      <FormLabel className={formLabelStyles}>Last Name</FormLabel>
                      <FormControl>
                        <Input {...field} className={inputStyles} placeholder="Doe" type="text" />
                      </FormControl>
                      <FormMessage className={errorMessageStyles} />
                    </FormItem>
                  );
                }}
              />
            </div>
            <div className={formRowStyles}>
              <FormField
                control={control}
                name="phone"
                render={({ field }) => {
                  return (
                    <FormItem className={formItemStyles}>
                      <FormLabel className={formLabelStyles}>Phone</FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          placeholder="123-456-7890"
                          type="text"
                          inputMode="tel"
                          autoComplete="tel"
                          className={inputStyles}
                        />
                      </FormControl>
                      <FormMessage className={errorMessageStyles} />
                    </FormItem>
                  );
                }}
              />
              <FormField
                control={control}
                name="linkedin"
                render={({ field }) => {
                  return (
                    <FormItem className={formItemStyles}>
                      <FormLabel className={formLabelStyles} optional>
                        LinkedIn
                      </FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          placeholder="https://www.linkedin.com/in/..."
                          type="text"
                          inputMode="url"
                          className={inputStyles}
                        />
                      </FormControl>
                      <FormMessage className={errorMessageStyles} />
                    </FormItem>
                  );
                }}
              />
            </div>
          </SplitLayoutLeftContent>
          <SplitLayoutLeftFooter>
            <ProgressButtonContainer>
              <Link
                to="/onboarding/utah"
                className={progressButtonVariants().button({
                  direction: "left",
                })}
              >
                <ProgressButtonContent direction="left" />
              </Link>
              <button
                disabled={basicInfoPartOneMutation.isPending}
                className={progressButtonVariants().button({
                  direction: "right",
                  loading: basicInfoPartOneMutation.isPending,
                })}
                type="submit"
              >
                <ProgressButtonContent isLoading={basicInfoPartOneMutation.isPending} direction="right" />
              </button>
            </ProgressButtonContainer>
          </SplitLayoutLeftFooter>
        </SplitLayoutLeft>
      </form>
    </Form>
  );
}

const BasicInfoFormOneSchema = z.object({
  firstName: z.string().min(1, { message: "Please enter a first name" }),
  lastName: z.string().min(1, { message: "Please enter a last name" }),
  phone: z.string().min(1, { message: "Please enter a phone number" }),
  linkedin: z.string().url().optional().or(z.literal("")),
});

type BasicInfoFormOneInput = z.input<typeof BasicInfoFormOneSchema>;
type BasicInfoFormOneOutput = z.output<typeof BasicInfoFormOneSchema>;

function BasicInfoFormTwo({ setPart }: { setPart: React.Dispatch<React.SetStateAction<number>> }) {
  useScrollSplitToTop();
  const queryClient = useQueryClient();
  const { data: user } = useSuspenseQuery(USER_OPTIONS.user());
  const navigate = useNavigate();
  const basicInfoPartTwoMutation = useMutation({
    mutationFn: async (data: BasicInfoFormTwoOutput) => {
      await api.post(`/participants/${user.id}/onboarding`, {
        programId: UTAH_PROGRAM_ID,
        step: UTAH_PROGRAM_AVAILABLE_STEPS["basic-info"].id,
        part: 2,
        payload: data,
      });
    },
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: USER_OPTIONS.user().queryKey,
      });
    },
  });
  const { dispatch: stepProgressDispatch } = useStepProgress();
  const form = useForm<BasicInfoFormTwoInput>({
    mode: "onSubmit",
    resolver: zodResolver(BasicInfoFormTwoSchema),
    defaultValues: {
      workEnvironment: user.profile?.workEnvironment ?? [],
      employmentType: user.profile?.employmentType ?? [],
      dateAvailable: user.profile?.dateAvailable ?? "",
      compensationExpectation: user.profile?.compensationExpectation ?? 0,
    },
  });
  const { control, handleSubmit } = form;

  return (
    <Form {...form}>
      <form
        onSubmit={handleSubmit((data) => {
          basicInfoPartTwoMutation.mutate(data, {
            onSuccess: () => {
              navigate(UTAH_PROGRAM_AVAILABLE_STEPS.demographics.slug);
            },
            onError: () => {
              toast.error("Failed to complete basic info part two");
            },
          });
        })}
        className="h-full"
      >
        <SplitLayoutLeft>
          <SplitLayoutLeftContent>
            <div className={formRowStyles}>
              <FormField
                control={control}
                name="workEnvironment"
                render={() => {
                  return (
                    <FormItem className={formItemStyles}>
                      <FormLabel className={formLabelStyles}>Which work environment do you prefer?</FormLabel>
                      {WORK_ENVIRONMENTS.map((item) => {
                        return (
                          <FormField
                            control={control}
                            name="workEnvironment"
                            key={item.id}
                            render={({ field }) => {
                              return (
                                <FormItem className={selectionGroupStyles}>
                                  <FormControl>
                                    <Checkbox
                                      checked={field.value?.includes(item.id)}
                                      onCheckedChange={(checked) => {
                                        if (checked) {
                                          field.onChange([...field.value, item.id]);
                                        } else {
                                          field.onChange(field.value?.filter((value) => value !== item.id));
                                        }
                                      }}
                                      className={checkboxStyles}
                                    />
                                  </FormControl>
                                  <FormLabel
                                    className={twMerge(
                                      formLabelStyles,
                                      "text-ds-text-secondary font-normal",
                                      field.value.includes(item.id) ? "text-ds-text-primary" : "",
                                    )}
                                  >
                                    {item.label}
                                  </FormLabel>
                                </FormItem>
                              );
                            }}
                          />
                        );
                      })}
                      <FormDescription className="text-sm">Please select all that apply.</FormDescription>
                      <FormMessage className={errorMessageStyles} />
                    </FormItem>
                  );
                }}
              />
              <FormField
                control={control}
                name="employmentType"
                render={({ field }) => {
                  return (
                    <FormItem className={formItemStyles}>
                      <FormLabel className={formLabelStyles}>
                        Are you looking for full-time or part-time employment?
                      </FormLabel>
                      {EMPLOYMENT_TYPES.map((item) => {
                        return (
                          <FormField
                            control={control}
                            name="employmentType"
                            key={item.id}
                            render={({ field }) => {
                              return (
                                <FormItem className={selectionGroupStyles}>
                                  <FormControl>
                                    <Checkbox
                                      checked={field.value?.includes(item.id)}
                                      onCheckedChange={(checked) => {
                                        if (checked) {
                                          field.onChange([...field.value, item.id]);
                                        } else {
                                          field.onChange(field.value?.filter((value) => value !== item.id));
                                        }
                                      }}
                                      className={checkboxStyles}
                                    />
                                  </FormControl>
                                  <FormLabel
                                    className={twMerge(
                                      formLabelStyles,
                                      "text-ds-text-secondary font-normal",
                                      field.value.includes(item.id) ? "text-ds-text-primary" : "",
                                    )}
                                  >
                                    {item.label}
                                  </FormLabel>
                                </FormItem>
                              );
                            }}
                          />
                        );
                      })}
                      <FormDescription className="text-sm">Please select all that apply.</FormDescription>
                      <FormMessage className={errorMessageStyles} />
                    </FormItem>
                  );
                }}
              />
            </div>
            <div className={formRowStyles}>
              <FormField
                control={control}
                name="dateAvailable"
                render={({ field }) => {
                  return (
                    <FormItem className={formItemStyles}>
                      <FormLabel className={formLabelStyles}>When are you hoping to start a new job?</FormLabel>
                      <FormControl>
                        <Input {...field} className={inputStyles} type="date" />
                      </FormControl>
                      <FormMessage className={errorMessageStyles} />
                    </FormItem>
                  );
                }}
              />

              <FormField
                control={control}
                name="compensationExpectation"
                render={({ field }) => {
                  return (
                    <FormItem className={formItemStyles}>
                      <FormLabel className={formLabelStyles}>What are your compensation expectations?</FormLabel>

                      <FormControl>
                        <Input
                          {...field}
                          type="number"
                          prefix={<span>$</span>}
                          prefixStyles
                          step=".01"
                          placeholder="0"
                          className={inputStyles}
                        />
                      </FormControl>
                      <FormDescription className="text-sm">
                        Please provide a target annual base salary or hourly rate.
                      </FormDescription>
                      <FormMessage className={errorMessageStyles} />
                    </FormItem>
                  );
                }}
              />
            </div>
          </SplitLayoutLeftContent>
          <SplitLayoutLeftFooter>
            <ProgressButtonContainer>
              <button
                type="button"
                className={progressButtonVariants().button({
                  direction: "left",
                })}
                onClick={() => {
                  setPart(1);
                  stepProgressDispatch({ type: "UPDATE_PROGRESS", value: 0 });
                }}
              >
                <ProgressButtonContent direction="left" />
              </button>
              <button
                className={progressButtonVariants().button({
                  direction: "right",
                  loading: basicInfoPartTwoMutation.isPending,
                })}
                type="submit"
                disabled={basicInfoPartTwoMutation.isPending}
              >
                <ProgressButtonContent isLoading={basicInfoPartTwoMutation.isPending} direction="right" />
              </button>
            </ProgressButtonContainer>
            <NextStepButton
              type="submit"
              disabled={basicInfoPartTwoMutation.isPending}
              isLoading={basicInfoPartTwoMutation.isPending}
            >
              Self Identification
            </NextStepButton>
          </SplitLayoutLeftFooter>
        </SplitLayoutLeft>
      </form>
    </Form>
  );
}

const BasicInfoFormTwoSchema = z.object({
  workEnvironment: z.array(z.string()).min(1, { message: "Please select an option" }),
  employmentType: z.array(z.string()).min(1, { message: "Please select an option" }),
  dateAvailable: z.string().min(1, { message: "Please enter a date" }),
  compensationExpectation: z.coerce.number().min(0, { message: "Must be a greater than or equal to 0" }),
});
type BasicInfoFormTwoInput = z.input<typeof BasicInfoFormTwoSchema>;
type BasicInfoFormTwoOutput = z.output<typeof BasicInfoFormTwoSchema>;

const WORK_ENVIRONMENTS = [
  { label: "In-person", id: "In-person" },
  { label: "Remote", id: "Remote" },
  { label: "Hybrid", id: "Hybrid" },
] as const;

const EMPLOYMENT_TYPES = [
  { label: "Full-time", id: "Full-time" },
  { label: "Part-time", id: "Part-time" },
] as const;
