import { QueryClient } from "@tanstack/react-query";
import * as React from "react";
import { Helmet } from "react-helmet";
import { LoaderFunctionArgs, Outlet, useSearchParams } from "react-router-dom";
import { INTERVIEWS_QUERY_OPTIONS } from "~/shared/api/interviews";
import { JOBS_QUERY_OPTIONS } from "~/shared/api/jobs";
import { PageHeader, PageHeading } from "~/shared/components/PageLayout";
import { TabNavigation, TabNavigationItem } from "~/shared/components/TabNavigation";
import { LoadingContainer } from "~/shared/components/ds/Spinner";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "~/shared/components/ds/Tooltip";
import { Icon, IconUse } from "~/shared/components/ds/icons/Icon";
import { copy } from "~/shared/styles/text";
import { PIPELINE_PATHS } from "~/team/constants/paths.constants";
import {
  ALIGNMENT_SCORE_SHEET_ID,
  CLOSING_NOTES_SHEET_ID,
  INTERVIEW_CLOSING_NOTES_SHEET_ID,
  INTERVIEW_ID_PARAM,
  ROLE_ID_PARAM,
  SHEET_PARAM,
} from "~/team/constants/pipeline.constants";

export function pipelineLoader(queryClient: QueryClient, { request }: LoaderFunctionArgs) {
  const url = new URL(request.url);
  const interviewId = url.searchParams.get(INTERVIEW_ID_PARAM);
  const roleId = url.searchParams.get(ROLE_ID_PARAM);
  const sheet = url.searchParams.get(SHEET_PARAM);

  if (!sheet) return null;

  /** Pre-fetch (non-blocking) the sheet data for the alignment score or closing notes sheets */
  switch (sheet) {
    case ALIGNMENT_SCORE_SHEET_ID: {
      if (!interviewId) return null;

      queryClient
        .ensureQueryData({
          ...INTERVIEWS_QUERY_OPTIONS.alignmentScoresList(interviewId),
          retry: false,
        })
        .catch(() => {
          return null;
        });
      return null;
    }
    case INTERVIEW_CLOSING_NOTES_SHEET_ID: {
      queryClient
        .ensureQueryData({
          ...INTERVIEWS_QUERY_OPTIONS.closingNotesList(interviewId),
          retry: false,
        })
        .catch(() => {
          return null;
        });
      return null;
    }
    case CLOSING_NOTES_SHEET_ID: {
      if (!roleId) return null;

      queryClient
        .ensureQueryData({
          ...JOBS_QUERY_OPTIONS.closingNotesList(roleId),
          retry: false,
        })
        .catch(() => {
          return null;
        });
      return null;
    }
    default:
      return null;
  }
}
export function PipelineView() {
  const [containerRef, setContainerRef] = React.useState<HTMLDivElement | null>(null);

  const [searchParams] = useSearchParams();

  return (
    <div className="content-container-full" ref={setContainerRef}>
      <Helmet title="Pipeline" />
      <PageHeader>
        <div className="stack-y-3">
          <PageHeading>Pipeline</PageHeading>
          <p
            className={copy({
              variant: "16",
              color: "secondary",
            })}
          >
            Manage your team's roles and interviews
          </p>
        </div>
      </PageHeader>
      <div className="pb-8">
        <TabNavigation>
          <TabNavigationItem
            to={{
              pathname: PIPELINE_PATHS.root,
              search: searchParams.toString(),
            }}
            end
          >
            <span className="stack-x-2 items-center">
              Roles
              <TabTooltip containerRef={containerRef}>
                Roles are the positions that you are hiring for. You can create new roles, manage existing roles, and
                view the candidates that have applied to each role.
              </TabTooltip>
            </span>
          </TabNavigationItem>
          <TabNavigationItem
            to={{
              pathname: PIPELINE_PATHS.interviews(),
              search: searchParams.toString(),
            }}
            end
          >
            <span className="stack-x-2 items-center">
              Interviews
              <TabTooltip containerRef={containerRef}>
                Interviews are the conversations you have with candidates to assess their skills and experience. You can
                view upcoming interviews, past interviews, and the candidates that have applied to each role.
              </TabTooltip>
            </span>
          </TabNavigationItem>
        </TabNavigation>
      </div>

      <React.Suspense fallback={<LoadingContainer level="component" />}>
        <Outlet />
      </React.Suspense>
    </div>
  );
}

interface TabTooltipProps {
  children: React.ReactNode;
  containerRef: HTMLDivElement | null;
}
function TabTooltip({ children, containerRef }: TabTooltipProps) {
  return (
    <span className="hidden md:block">
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <Icon className="h-3 w-3">
              <IconUse id="information-line" />
            </Icon>
          </TooltipTrigger>
          <TooltipContent
            collisionBoundary={containerRef}
            className="max-w-prose whitespace-normal text-left"
            sideOffset={18}
          >
            {children}
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </span>
  );
}
