import * as SeparatorPrimitive from "@radix-ui/react-separator";
import * as React from "react";
import { twMerge } from "tailwind-merge";

export const Separator = React.forwardRef<
  React.ElementRef<typeof SeparatorPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SeparatorPrimitive.Root>
>(({ children, className, orientation = "horizontal", decorative = true, ...rest }, forwardedRef) => {
  return (
    <SeparatorPrimitive.Root
      ref={forwardedRef}
      decorative={decorative}
      orientation={orientation}
      className={twMerge(
        "text-ds-text-tertiary flex text-sm",
        orientation === "horizontal" ? "w-full flex-row items-center gap-3" : "w-auto flex-col items-center gap-1",
        className,
      )}
      {...rest}
    >
      {children ? (
        <>
          <div
            aria-hidden
            className={twMerge(
              "bg-ds-stroke-tertiary",
              orientation === "horizontal" ? "h-[1px] w-full" : "h-full w-[1px]",
            )}
          />
          <div className="whitespace-nowrap text-inherit">{children}</div>
          <div
            aria-hidden
            className={twMerge(
              "bg-ds-stroke-tertiary",
              orientation === "horizontal" ? "h-[1px] w-full" : "h-full w-[1px]",
            )}
          />
        </>
      ) : (
        <div
          aria-hidden
          className={twMerge(
            "bg-ds-stroke-tertiary",
            orientation === "horizontal" ? "h-[1px] w-full" : "h-full w-[1px]",
          )}
        />
      )}
    </SeparatorPrimitive.Root>
  );
});
Separator.displayName = SeparatorPrimitive.Root.displayName;
