import {
  InfiniteData,
  useSuspenseInfiniteQuery,
  useSuspenseQuery,
  UseSuspenseQueryOptions,
} from "@tanstack/react-query";
import * as CommandPrimitive from "cmdk";
import * as fuzzy from "fuzzy";
import * as React from "react";
import { useInView } from "react-intersection-observer";
import { twMerge } from "tailwind-merge";
import { Filter } from "~/shared/components/FiltersV3/types/filters-v3.types";
import { ListLoadMore } from "~/shared/components/ListLoadMore";
import { Button } from "~/shared/components/ds/Button";
import { ComboboxCheck, ComboboxInput, ComboboxItem, ComboboxList } from "~/shared/components/ds/Combobox";
import { Spinner } from "~/shared/components/ds/Spinner";
import { useDebounce } from "~/shared/hooks/useDebounce";

export const CommandListAsyncInfinite = React.forwardRef<
  HTMLInputElement,
  {
    label: string;
    initialSelection?: Array<Filter>;
    infiniteQueryOptions: (params: { [key: string]: unknown }) => any;
    onApply?: (selection: Array<Filter>) => void;
  }
>(({ label, infiniteQueryOptions, onApply, initialSelection = [] }, ref) => {
  const [isApplying, setIsApplying] = React.useState(false);
  const labelLowercase = label.toLowerCase();
  const [selection, setSelection] = React.useState<Array<Filter>>(initialSelection);
  const [search, setSearch] = React.useState("");
  const debouncedSearchTerm = useDebounce(search, 500);
  const params = React.useMemo(() => {
    return debouncedSearchTerm ? { term: debouncedSearchTerm } : {};
  }, [debouncedSearchTerm]);
  const deferredParams = React.useDeferredValue(params);
  const isDeferred = params !== deferredParams;
  const { data, hasNextPage, fetchNextPage, isFetchingNextPage, isError } = useSuspenseInfiniteQuery(
    infiniteQueryOptions(deferredParams),
  );
  const sortedData = useSortAndFilterInfiniteData(data, initialSelection, debouncedSearchTerm);
  const { ref: loadMoreRef, inView: loadMoreInView } = useInView();

  React.useEffect(() => {
    if (loadMoreInView && hasNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, fetchNextPage, loadMoreInView]);

  return (
    <div>
      <CommandPrimitive.CommandRoot shouldFilter={false}>
        <ComboboxInput
          ref={ref}
          loading={isDeferred}
          onValueChange={(value) => {
            setSearch(value);
          }}
          placeholder={`Search ${labelLowercase}...`}
        />

        {sortedData.length > 0 ? (
          <ComboboxList className="max-h-[400px] md:max-h-[300px]">
            {sortedData.map((item) => {
              const isSelected = selection.map((f) => f.id.toString()).includes(item.id.toString());

              return (
                <ComboboxItem
                  key={item.id}
                  value={item.id.toString()}
                  data-checked={isSelected}
                  onSelect={() => {
                    function addValue() {
                      setSelection((prev) => [...prev, item]);
                    }
                    function removeValue() {
                      setSelection((prev) => prev.filter((f) => f.id !== item.id.toString()));
                    }

                    if (isSelected) {
                      removeValue();
                    } else {
                      addValue();
                    }
                  }}
                >
                  {item.name}
                  <ComboboxCheck checked={isSelected} />
                </ComboboxItem>
              );
            })}
            {hasNextPage && <ListLoadMore loading={isFetchingNextPage} ref={loadMoreRef} />}
          </ComboboxList>
        ) : isDeferred ? (
          <div className="text-ds-text-primary py-6 text-center text-sm">Searching...</div>
        ) : isError ? (
          <div className="text-ds-text-primary py-6 text-center text-sm">Error fetching results.</div>
        ) : (
          <div className="text-ds-text-primary py-6 text-center text-sm">No results</div>
        )}
      </CommandPrimitive.CommandRoot>

      {selection.length > 0 && (
        <div className="border-ds-stroke-tertiary border-t p-2">
          <Button
            variant="secondary"
            className="w-full"
            onClick={() => {
              setIsApplying(true);
              onApply?.(selection);
            }}
            disabled={isApplying}
          >
            Apply
          </Button>
        </div>
      )}
    </div>
  );
});

function useSortAndFilterInfiniteData<TData extends InfiniteData<any, unknown>>(
  data: TData,
  initialSelection: Array<Filter>,
  searchTerm: string,
) {
  return React.useMemo(() => {
    const flattenedFetchedFilters =
      data?.pages
        .flatMap((page) => {
          return page.items;
        })
        .map((f) => {
          return {
            type: "tag" as const,
            id: String(f.id),
            name: f.name,
          };
        })
        .filter((f) => {
          return !initialSelection.some((sf) => sf.id === f.id);
        }) ?? [];

    const allFilters = [...initialSelection, ...flattenedFetchedFilters];
    const searchResults = fuzzy.filter(searchTerm, allFilters, {
      extract: (f) => f.name,
    });

    return searchResults.map((f) => {
      return f.original;
    });
  }, [data, initialSelection, searchTerm]);
}

export const CommandListAsync = React.forwardRef<
  HTMLInputElement,
  {
    label: string;
    initialSelection?: Array<Filter>;
    queryOptions: (params: { [key: string]: unknown }) => UseSuspenseQueryOptions<unknown, unknown, Array<Filter>>;
    onApply?: (selection: Array<Filter>) => void;
  }
>(({ label, queryOptions, onApply, initialSelection = [] }, ref) => {
  const [isApplying, setIsApplying] = React.useState(false);
  const labelLowercase = label.toLowerCase();
  const [selection, setSelection] = React.useState<Array<Filter>>(initialSelection);
  const [search, setSearch] = React.useState("");
  const debouncedSearchTerm = useDebounce(search, 500);
  const params = React.useMemo(() => {
    return debouncedSearchTerm ? { term: debouncedSearchTerm } : {};
  }, [debouncedSearchTerm]);
  const deferredParams = React.useDeferredValue(params);
  const isDeferred = params !== deferredParams;
  const { data, isError } = useSuspenseQuery(queryOptions(deferredParams));
  const sortedData = useSortAndFilterData(data, initialSelection, debouncedSearchTerm);

  return (
    <div>
      <CommandPrimitive.CommandRoot shouldFilter={false}>
        <ComboboxInput
          ref={ref}
          loading={isDeferred}
          onValueChange={(value) => {
            setSearch(value);
          }}
          placeholder={`Search ${labelLowercase}...`}
        />

        {sortedData.length > 0 ? (
          <ComboboxList className="max-h-[400px] md:max-h-[300px]">
            {sortedData.map((item) => {
              const isSelected = selection.map((f) => f.id.toString()).includes(item.id.toString());

              return (
                <ComboboxItem
                  key={item.id}
                  value={item.id.toString()}
                  data-checked={isSelected}
                  onSelect={() => {
                    function addValue() {
                      setSelection((prev) => [...prev, item]);
                    }
                    function removeValue() {
                      setSelection((prev) => prev.filter((f) => f.id !== item.id.toString()));
                    }

                    if (isSelected) {
                      removeValue();
                    } else {
                      addValue();
                    }
                  }}
                >
                  {item.name}
                  <ComboboxCheck checked={isSelected} />
                </ComboboxItem>
              );
            })}
          </ComboboxList>
        ) : isDeferred ? (
          <div className="text-ds-text-primary py-6 text-center text-sm">Searching...</div>
        ) : isError ? (
          <div className="text-ds-text-primary py-6 text-center text-sm">Error fetching results.</div>
        ) : (
          <div className="text-ds-text-primary py-6 text-center text-sm">No results</div>
        )}
      </CommandPrimitive.CommandRoot>

      {selection.length > 0 && (
        <div className="border-ds-stroke-tertiary border-t p-2">
          <Button
            variant="secondary"
            className="w-full"
            onClick={() => {
              setIsApplying(true);
              onApply?.(selection);
            }}
            disabled={isApplying}
          >
            Apply
          </Button>
        </div>
      )}
    </div>
  );
});

function useSortAndFilterData<TData extends Array<Filter>>(
  data: TData,
  initialSelection: Array<Filter>,
  searchTerm: string,
) {
  return React.useMemo(() => {
    const unselectedFilters =
      data
        .map((f) => {
          return {
            type: "tag" as const,
            id: String(f.id),
            name: f.name,
          };
        })
        .filter((f) => {
          return !initialSelection.some((sf) => sf.id === f.id);
        }) ?? [];

    const allFilters = [...initialSelection, ...unselectedFilters];
    const searchResults = fuzzy.filter(searchTerm, allFilters, {
      extract: (f) => f.name,
    });

    return searchResults.map((f) => {
      return f.original;
    });
  }, [data, initialSelection, searchTerm]);
}

export const CommandList = React.forwardRef<
  HTMLInputElement,
  {
    label: string;
    initialSelection: Array<Filter>;
    values: Array<Filter>;
    onApply?: (selection: Array<Filter>) => void;
  }
>(({ label, initialSelection, values, onApply }, ref) => {
  const [isApplying, setIsApplying] = React.useState(false);
  const labelLowercase = label.toLowerCase();
  const [selection, setSelection] = React.useState<Array<Filter>>(initialSelection);
  const [search, setSearch] = React.useState("");

  const filteredValues = React.useMemo(() => {
    const withoutInitialSelection = values.filter((f) => {
      return !initialSelection.some((s) => s.id === f.id);
    });

    const allFilters = [...initialSelection, ...withoutInitialSelection];
    const searchResults = fuzzy.filter(search, allFilters, {
      extract: (f) => f.name,
    });

    return searchResults.map((f) => {
      return f.original;
    });
  }, [values, initialSelection, search]);

  return (
    <div>
      <CommandPrimitive.CommandRoot shouldFilter={true}>
        <ComboboxInput
          ref={ref}
          onValueChange={(value) => {
            setSearch(value);
          }}
          placeholder={`Search ${labelLowercase}...`}
        />
        {filteredValues.length > 0 ? (
          <ComboboxList className="max-h-[400px] md:max-h-[300px]">
            {filteredValues.map((value) => {
              const isSelected = selection.map((f) => f.id.toString()).includes(value.id.toString());

              return (
                <ComboboxItem
                  key={value.id}
                  value={value.id.toString()}
                  data-checked={isSelected}
                  onSelect={() => {
                    function addValue() {
                      setSelection((prev) => [...prev, value]);
                    }
                    function removeValue() {
                      setSelection((prev) => prev.filter((f) => f.id !== value.id.toString()));
                    }

                    if (isSelected) {
                      removeValue();
                    } else {
                      addValue();
                    }
                  }}
                >
                  {value.name}
                  <ComboboxCheck checked={isSelected} />
                </ComboboxItem>
              );
            })}
          </ComboboxList>
        ) : (
          <div className="text-ds-text-primary py-6 text-center text-sm">No results</div>
        )}

        {selection.length > 0 && (
          <div className="border-ds-stroke-tertiary border-t p-2">
            <Button
              variant="secondary"
              className="w-full"
              onClick={() => {
                setIsApplying(true);
                onApply?.(selection);
              }}
              disabled={isApplying}
            >
              Apply
            </Button>
          </div>
        )}
      </CommandPrimitive.CommandRoot>
    </div>
  );
});

export function CommandListLoadingFallback({ className, children }: { className?: string; children: React.ReactNode }) {
  return (
    <React.Suspense
      fallback={
        <div className={twMerge("grid h-[300px] place-items-center", className)}>
          <Spinner size="sm" />
        </div>
      }
    >
      {children}
    </React.Suspense>
  );
}
