import type { QueryClient } from "@tanstack/react-query";
import { Outlet, RouteObject } from "react-router-dom";
import { TopLevelRouteErrorHandler } from "~/shared/components/Error";
import { PublicAppLayout } from "~/shared/components/PublicAppLayout";
import { getSharedRoutes } from "~/shared/shared.routes";

const PublicAppRoot = () => {
  return (
    <PublicAppLayout>
      <Outlet />
    </PublicAppLayout>
  );
};

export function PublicRoutes(queryClient: QueryClient): RouteObject[] {
  return [
    {
      path: "*",
      Component: PublicAppRoot,
      errorElement: <TopLevelRouteErrorHandler />,
      children: [...getSharedRoutes(queryClient)],
    },
  ];
}
