import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import * as React from "react";
import { useMemo } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { z } from "zod";
import apiService from "~/shared/api/api-service";
import { JOBS_KEYS, JOBS_QUERY_OPTIONS } from "~/shared/api/jobs";
import { PIPELINE_QUERIES } from "~/shared/api/pipeline";
import Button from "~/shared/components/Button";
import ButtonGroup from "~/shared/components/ButtonGroup";
import DateWithHover from "~/shared/components/Date";
import { H3, H4 } from "~/shared/components/H";
import Modal, { useModal } from "~/shared/components/Modal";
import PageTitle from "~/shared/components/PageTitle";
import Pagination from "~/shared/components/Pagination";
import { TagController } from "~/shared/components/TagController";
import { LoadingContainer } from "~/shared/components/ds/Spinner";
import { toast } from "~/shared/components/ds/Toast/Toast";
import Table, { DataCell } from "~/shared/components/table";
import { statusColors } from "~/shared/util/statusColors";
import {
  DEFAULT_TABLE_PARAMS,
  getTableParamsFromRequest,
  useDefaultTableSearchParams,
  useTableSearchParamsCallbacks,
} from "~/shared/util/tableHelpers";
import AddStatusModal from "~/team/views/JobEditStatusesView/components/AddStatusModal";
import UpdateStatusModal from "~/team/views/JobEditStatusesView/components/UpdateStatusModal";

const JobTitle = styled(PageTitle)`
  margin: 35px 0 10px;
`;

export function jobEditStatusesLoader(queryClient, { params, request }) {
  try {
    const { jobId } = params ?? {};

    if (!jobId) {
      throw new Error("Job ID is required to load job statuses");
    }

    queryClient.ensureQueryData(
      JOBS_QUERY_OPTIONS.statusesList(
        jobId,
        getTableParamsFromRequest({
          key: ACTIVE_STATUSES_TABLE_PARAMS_ID,
          request,
        }),
      ),
    );

    queryClient.ensureQueryData(
      JOBS_QUERY_OPTIONS.statusesLogList(
        jobId,
        getTableParamsFromRequest({
          key: JOB_STATUS_LOG_TABLE_PARAMS_ID,
          request,
          fallbackData: {
            page: DEFAULT_TABLE_PARAMS.page,
            limit: DEFAULT_TABLE_PARAMS.limit,
          },
        }),
      ),
    );

    return null;
  } catch (error) {
    return null;
  }
}

export function JobEditStatusesView() {
  return (
    <div>
      <ActiveStatuses>
        <ActiveStatusesTable />
      </ActiveStatuses>
      <JobStatusLog>
        <JobStatusLogTable />
      </JobStatusLog>
    </div>
  );
}

const ActiveStatusTableContext = React.createContext({});
function ActiveStatuses({ children }) {
  const queryClient = useQueryClient();
  const { jobId } = useParams();
  const { modalOpen, modalData, toggleModal } = useModal();

  const onRequestClose = (mutate) => {
    if (mutate) {
      queryClient.invalidateQueries({ queryKey: JOBS_KEYS.detail(jobId) });
      queryClient.invalidateQueries({ queryKey: PIPELINE_QUERIES.all() });
    }
    toggleModal();
  };

  const removeStatus = () => {
    apiService
      .delete(`jobs/${modalData.jobId}/statuses`, { id: modalData.status?.id })
      .then(() => {
        toast.success(`${modalData?.status?.type} status has been removed`);
        onRequestClose(true);
      })
      .catch(() => {
        toast.error(`There was an error removing the ${modalData?.status?.type} status`);
        onRequestClose();
      });
  };

  return (
    <section>
      <JobTitle>
        <H4 as="h2">Active Statuses</H4>

        <Button
          $small
          onClick={() => {
            toggleModal("addStatus", { jobId });
          }}
        >
          Add Status
        </Button>
      </JobTitle>
      <ActiveStatusTableContext.Provider value={{ toggleModal }}>
        <React.Suspense fallback={<LoadingContainer level="component" />}>{children}</React.Suspense>
      </ActiveStatusTableContext.Provider>

      <Modal isOpen={modalOpen === "removeStatus"} modalData={modalData} onRequestClose={toggleModal}>
        <H3>Remove {modalData.status?.type} Status</H3>
        <p>
          Are you sure you want to remove the <strong>{modalData.status?.type}</strong> status?
        </p>

        <ButtonGroup>
          <Button onClick={removeStatus}>Remove</Button>
          <Button
            onClick={() => {
              toggleModal();
            }}
            $theme="secondary"
          >
            Cancel
          </Button>
        </ButtonGroup>
      </Modal>

      <UpdateStatusModal modalData={modalData} modalOpen={modalOpen} onRequestClose={onRequestClose} />

      <AddStatusModal modalData={modalData} modalOpen={modalOpen} onRequestClose={onRequestClose} />
    </section>
  );
}

const ACTIVE_STATUSES_TABLE_PARAMS_ID = "activeStatusesTable";
function ActiveStatusesTable() {
  const { toggleModal } = React.useContext(ActiveStatusTableContext);
  const { jobId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { queryParams, parsedSearchParams } = useDefaultTableSearchParams({
    key: ACTIVE_STATUSES_TABLE_PARAMS_ID,
    searchParams,
  });
  const { handlePageChange, handlePerRowsChange } = useTableSearchParamsCallbacks({
    key: ACTIVE_STATUSES_TABLE_PARAMS_ID,
    setSearchParams,
  });
  const deferredParams = React.useDeferredValue(queryParams);
  const isDeferred = JSON.stringify(queryParams) !== JSON.stringify(deferredParams);

  const { data: jobStatuses, isFetching } = useSuspenseQuery(JOBS_QUERY_OPTIONS.statusesList(jobId, deferredParams));
  const isPending = isFetching || isDeferred;

  const tableCols = useMemo(
    () => [
      {
        name: "Name",
        selector: "row.type",
        maxWidth: "150px",
        cell: (row) => (
          <DataCell name="Status">
            <TagController value={row.status?.type} options={statusColors} />
          </DataCell>
        ),
      },
      {
        name: "Created By",
        selector: "row.createdBy",
        maxWidth: "170px",
        sort: false,
        cell: (row) => (
          <DataCell name="Created By">
            {row?.createdBy?.firstName} {row?.createdBy?.lastName}
          </DataCell>
        ),
      },
      {
        name: "Last Update",
        selector: "row.date",
        maxWidth: "200px",
        sort: false,
        cell: (row) => (
          <DataCell name="Date">
            <DateWithHover date={row.createdAt} />
          </DataCell>
        ),
      },
      {
        name: "Context",
        selector: "row.context",
        sort: false,
        cell: (row) => <DataCell name="Date">{row?.context}</DataCell>,
      },
      {
        name: "Action",
        selector: "row.action",
        sort: false,
        cell: (row) => (
          <DataCell name="Action">
            <ButtonGroup>
              <Button
                $small
                $theme="secondary"
                onClick={() => {
                  toggleModal("updateStatus", {
                    endpoint: "jobs",
                    jobId,
                    status: row,
                  });
                }}
              >
                Update
              </Button>

              <Button
                $small
                $theme="error"
                onClick={() => {
                  toggleModal("removeStatus", { jobId, status: row });
                }}
              >
                Remove
              </Button>
            </ButtonGroup>
          </DataCell>
        ),
      },
    ],
    [jobId, toggleModal],
  );

  return (
    <>
      <Table
        columns={tableCols}
        data={jobStatuses?.items}
        progressPending={isPending && !jobStatuses}
        pagination={false}
        small
      />
      <Pagination
        rowCount={jobStatuses?.total}
        currentPage={parsedSearchParams.page}
        rowsPerPage={parsedSearchParams.limit}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerRowsChange}
      />
    </>
  );
}

function JobStatusLog({ children }) {
  return (
    <section>
      <JobTitle>
        <H4 as="h2">Status Log</H4>
      </JobTitle>

      <React.Suspense fallback={<LoadingContainer level="component" />}>{children}</React.Suspense>
    </section>
  );
}

const STATUS_LOG_COLUMNS = [
  {
    name: "Date",
    selector: "row.date",
    maxWidth: "200px",
    sort: false,
    cell: (row) => (
      <DataCell name="Date">
        <DateWithHover date={row.createdAt} />
      </DataCell>
    ),
  },
  {
    name: "Description",
    cell: (row) => (
      <DataCell name="Description">
        <p>
          {row?.createdBy?.firstName} {row?.createdBy?.lastName} has {row?.action}d the status{" "}
          <TagController value={row.status?.type} options={statusColors} />{" "}
          {row?.context && ["create", "update"].includes(row.action)
            ? `with the following context: ${row?.context}`
            : null}
        </p>
      </DataCell>
    ),
  },
];

// TODO: Hunter - need to add the loaders these features
const jobStatusLogTableParamsSchema = z.object({
  page: z.number().int().positive().default(DEFAULT_TABLE_PARAMS.page).catch(DEFAULT_TABLE_PARAMS.page),
  limit: z.number().int().positive().default(DEFAULT_TABLE_PARAMS.limit).catch(DEFAULT_TABLE_PARAMS.limit),
});
const JOB_STATUS_LOG_TABLE_PARAMS_ID = "jobStatusLogTable";
function JobStatusLogTable() {
  const [searchParams] = useSearchParams();
  const { handlePageChange, handlePerRowsChange } = useTableSearchParamsCallbacks({
    key: JOB_STATUS_LOG_TABLE_PARAMS_ID,
  });
  const { queryParams, parsedSearchParams } = React.useMemo(() => {
    function parseParams() {
      const values = searchParams.get(JOB_STATUS_LOG_TABLE_PARAMS_ID);
      const fallback = {
        page: DEFAULT_TABLE_PARAMS.page,
        limit: DEFAULT_TABLE_PARAMS.limit,
      };
      if (!values) return fallback;

      try {
        return jobStatusLogTableParamsSchema.parse(JSON.parse(values));
      } catch (_err) {
        return {
          page: DEFAULT_TABLE_PARAMS.page,
          limit: DEFAULT_TABLE_PARAMS.limit,
        };
      }
    }
    const parsedSearchParams = parseParams();
    const { page, limit } = parsedSearchParams ?? {};

    return {
      queryParams: apiService.getTableParams(page, limit),
      parsedSearchParams,
    };
  }, [searchParams]);
  const deferredParams = React.useDeferredValue(queryParams);
  const isDeferred = queryParams !== deferredParams;
  const { jobId } = useParams();
  const { data: jobStatusLog } = useSuspenseQuery(JOBS_QUERY_OPTIONS.statusesLogList(jobId, deferredParams));

  return (
    <>
      <Table
        columns={STATUS_LOG_COLUMNS}
        data={jobStatusLog?.items}
        progressPending={isDeferred && !jobStatusLog}
        pagination={false}
        small
      />
      <Pagination
        rowCount={jobStatusLog?.total}
        currentPage={parsedSearchParams.page}
        rowsPerPage={parsedSearchParams.limit}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerRowsChange}
      />
    </>
  );
}
