import { IconLoader } from "~/shared/components/icons/IconLoader/IconLoader";

export function FormLoadingState() {
  return (
    <div className="flex h-full justify-center p-6">
      <IconLoader animate className="h-5 w-5" />
    </div>
  );
}

export function FormErrorState() {
  return (
    <div className="flex h-full justify-center p-6">
      <p className="text-ds-text-primary m-0">There was an error loading the form data.</p>
    </div>
  );
}
