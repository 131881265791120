import { useMutation, useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import api from "~/shared/api/api";
import { PARTICIPANT_KEYS, UpdateRoleBody } from "~/shared/api/participants";
import { USER_OPTIONS } from "~/shared/api/user/user";

export function useUpdateSavedRole(savedRoleId: number) {
  const { data: user } = useSuspenseQuery(USER_OPTIONS.user());
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: UpdateRoleBody) => {
      const response = await api.patch(`/participants/${user.id}/saved/roles/${savedRoleId}`, data);
      return response.data;
    },
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: PARTICIPANT_KEYS.savedRoles(user.id.toString()),
      });
    },
  });
}
